import React, { useContext, useEffect, useRef } from 'react';
import Navigation from './Navigation/index.js';
import { store } from 'components/Store.js';
import { useAuth0 } from '@auth0/auth0-react';
import GeneralTooltips from 'components/Dashboard/components/GeneralTooltips';
import SplitBanner from './Navigation/SplitBanner';
import SubNav from './SubNav';
import Loading from 'components/Login/Loading';
import SaveItemPopup from 'components/SavedItems/SaveItemPopup';
import ContactsOversubscribedInTrialModal from 'components/Dashboard/components/ContactsOversubscribedInTrialModal';
import useDashboardInitialController from './SearchControl/useDashboardInitialController';
import FeedbackItemPopup from './FeedbackItem/FeedbackItemPopup';
import StakeHolderSharePopUp from './StakeHolders/StakeHolderSharePopUp.js';
import ClientLoadingPage from './SearchControl/ClientLoadingPage.js';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import ContactsOversubscribedConfirmModal from 'components/Dashboard/components/ContactsOversubscribedConfirmModal.js';
import SearchResults from 'components/Dashboard/SearchControl/SearchResults.js';
import MonitorPage from 'components/Dashboard/SearchControl/MonitorPage.js';
import useInitialValidations from 'components/useInitialValidations.js';

const Dashboard = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const {
    search,
    contentResults,
    activePlan,
    readyKeywordLists,
    targetClient,
    readySetCurrentTeam,
    dispatchSearchForClients,
  } = state;
  const { isAuthenticated } = useAuth0();

  const params = useParams();
  const location = useLocation();
  const mainSection = location?.pathname === '/influence/stakeholder-mapping' ? 'stakeholders' : params?.mainSection;

  const { loadKeywordsLists, dispatchSearch, isLoading } = useDashboardInitialController();
  // SEARCH FUNCTIONS
  const initialCall = useRef();
  initialCall.current = async (source) => {
    try {
      if (readySetCurrentTeam === null || readySetCurrentTeam === true || !isAuthenticated) {
        loadKeywordsLists.current(source);
      }
    } catch (error) {}
  };
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    return () => {
      source.cancel('Keywords lists cancelled by the user');
    };
  }, [activePlan, readySetCurrentTeam]);

  useEffect(() => {
    //AE:Conditions when switching clients
    if (readyKeywordLists && (dispatchSearchForClients === null || dispatchSearchForClients === true)) {
      dispatchSearch.current();
    }
  }, [mainSection, readyKeywordLists, dispatchSearchForClients]);

  useEffect(() => {
    const pathnames = ['/influence', '/analytics', '/ai-assistant', '/reports'];
    if (pathnames.some((item) => location.pathname?.startsWith(item))) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'unseenNumbersCurrentList', value: {} });
      dispatch({ type: 'MODIFY_SECTION', parameter: 'totalNumbers', value: {} });
    }
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      if (!isAuthenticated) {
        let stateForResults = { ...contentResults, searchResults: {} };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'search',
          value: {
            ...search,
            query: '',
            activeSearch: false,
            searchState: true,
          },
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'activeResults',
          value: 'allResults',
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: stateForResults,
        });
      }
    };
  }, []);

  const analyticsPage = !isAuthenticated && location.pathname.startsWith('/data-hub');

  useInitialValidations();

  return (
    <>
      <div className='container-fluid px-0 dashboard-root-container'>
        <Navigation />
        {!analyticsPage && <SplitBanner />}
        {isLoading && <Loading />}
        {targetClient !== null && <ClientLoadingPage />}
        {!isLoading && (
          <>
            {/*AE: Problems this solution is preventing:
            -If we define path=search-results element={<Dashboard/>} and then path={'search-results'} index element={<SearchResults/>} it works but we lost the :mainParameter parameter which can be risky on <ListControl/>
            -The previous solution was define directly path='/search-results' element={<SearchResults/>} but that needed render <Navigation/> inside <SearchResults/> which can create a jump effect sometimes when switching between sections.  
            */}
            {location?.pathname === '/search-results' ? (
              <SearchResults />
            ) : location?.pathname === '/monitor-inbox' ? (
              <MonitorPage />
            ) : (
              <>
                <SubNav />
                <Outlet />
              </>
            )}
          </>
        )}
        <SaveItemPopup />
        <FeedbackItemPopup />
        <ContactsOversubscribedConfirmModal />
        <ContactsOversubscribedInTrialModal />
      </div>
      <StakeHolderSharePopUp />
      <GeneralTooltips />
    </>
  );
};

export default Dashboard;
