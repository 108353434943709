import { storageSupported } from 'components/Dashboard/helpers/validateLocalStorage';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import LocalStorage from 'utils/LocalStorage';

const createSectionViewStoreProperty = () => {
  const localStorageProperty =
    storageSupported && !!LocalStorage.getItem('sectionsView')
      ? JSON.parse(LocalStorage.getItem('sectionsView'))
      : null;
  const viewsProperty = {
    selectedOptionRelevantExtract: localStorageProperty?.selectedOptionRelevantExtract ?? 'Most relevant extract',
    selectedOptionParliamentLiveView:
      window.location.pathname === '/live-from-parliament' ||
      (window.location?.pathname?.startsWith('/search-results') && !getUrlParam('search'))
        ? 'Relevant events'
        : localStorageProperty?.selectedOptionParliamentLiveView ?? 'Relevant remarks',
  };
  return viewsProperty;
};

export default createSectionViewStoreProperty;
