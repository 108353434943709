import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import dayjs from 'dayjs';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import useCreateParliamentLiveFilters from 'components/Dashboard/ParliamentLive/useCreateParliamentLiveFilters';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

function useEventListFunctions() {
  const dateParam = getUrlParam('startDateTime');
  const { generalApiCall } = useGeneralApiCall();
  const { currentUKDateTime } = useCreateParliamentLiveFilters();

  const callEvents = async ({
    source,
    eventId,
    query = '',
    requestProperties: requestPropertiesProps,
    setInitialDate,
    specificDate,
  }) => {
    try {
      const UKDateTime = currentUKDateTime({ specificDate });
      const pathname = `/api/search/query`;
      const initialValue =
        specificDate && !dateParam
          ? dayjs(specificDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
          : dayjs.utc(UKDateTime).startOf('day').format('YYYY-MM-DDTHH:mm:ss');

      const secondValue =
        specificDate && !dateParam
          ? dayjs(specificDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
          : dayjs.utc(UKDateTime).format('YYYY-MM-DDTHH:mm:ss');

      if (setInitialDate) {
        setInitialDate(secondValue);
      }
      let requestProperties = {
        query,
        type: 'RealtimeUtterance',
        filters: [
          {
            field: 'createdAtUtc',
            value: initialValue,
            operator: 'DATE_GTE',
          },
          {
            field: 'createdAtUtc',
            value: secondValue,
            operator: 'date_lt',
          },
        ],
        pageNumber: 1,
        pageSize: 10000,
      };
      if (eventId) {
        requestProperties = {
          ...requestProperties,
          filters: [
            ...requestProperties.filters,
            {
              field: 'eventId',
              value: `${eventId}`,
              operator: 'STR_EQ',
            },
          ],
          sort: {
            field: 'dateTime',
            order: 'ASC',
          },
          doNotIncludeCollapsedHits: false,
          doNotSearchTitle: true,
        };
      } else {
        requestProperties = {
          ...requestProperties,
          collapseField: 'eventId',
          doNotSearchTitle: true,
          sort: {
            field: 'title',
            order: 'ASC',
          },
          collapseSort: {
            field: 'dateTime',
            order: 'DESC',
          },
          includeExplanations: true,
          includeHighlights: true,
        };
      }
      return await generalApiCall({
        pathname,
        method: 'post',
        requestProperties: requestPropertiesProps ?? requestProperties,
        notShowErrorMessage: true,
        requestSource: source,
      });
    } catch (error) {}
  };

  return { callEvents };
}

const useCallEventListItem = ({ condition, eventId, data, setData, date: dateProp }) => {
  const { callEvents } = useEventListFunctions();
  const defaultInterval = 30000; //ms
  const dateParam = dateProp;
  const minutesPerCallParam = getUrlParam('minutesPerCall');
  const minutesPerCall = minutesPerCallParam ?? defaultInterval / 60000;
  const interval = minutesPerCallParam ? 60000 : defaultInterval;

  const [date, setDate] = useState(dateParam);

  useEffect(() => {
    if (dateProp !== date) {
      setDate(dateProp);
    }
  }, [dateProp]);

  const intervallCallFunction = useRef();
  intervallCallFunction.current = async ({ source }) => {
    const secondDateValue = dayjs(date).add(minutesPerCall, 'minute').format('YYYY-MM-DDTHH:mm:ss');
    try {
      let requestProperties = {
        query: '',
        type: 'RealtimeUtterance',
        filters: [
          {
            field: 'eventId',
            value: `${eventId}`,
            operator: 'STR_EQ',
          },
          {
            field: 'createdAtUtc',
            value: date,
            operator: 'DATE_GTE',
          },
          {
            field: 'createdAtUtc',
            value: secondDateValue,
            operator: 'date_lt',
          },
        ],
        pageNumber: 1,
        pageSize: 10000,
        includeExplanations: true,
        includeHighlights: true,
        sort: {
          field: 'dateTime',
          order: 'ASC',
        },
      };

      let newRequestProperties = { ...requestProperties };
      let newFilters = [...newRequestProperties.filters];

      newRequestProperties = { ...newRequestProperties, filters: newFilters };
      let result = await callEvents({
        source,
        requestProperties: newRequestProperties,
        eventId,
      });
      let eventBodies = result?.hits?.map((item) => ({ ...item, notShown: true, text: item.body }));
      setData([...data.map((item) => ({ ...item, text: item.text })), ...eventBodies]);
      setDate(secondDateValue);
    } catch (error) {}
  };

  const intervalFunc = useRef(null);
  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    if (condition) {
      intervalFunc.current = setInterval(() => intervallCallFunction.current({ source }), interval);
    }
    return () => {
      if (!!source) {
        source.cancel('Event call canceled by the user.');
      }
      if (!!intervalFunc.current) {
        clearInterval(intervalFunc.current);
      }
    };
  }, [condition]);
};

export { useCallEventListItem };

export default useEventListFunctions;
