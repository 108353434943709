import React, { useRef, useState, useContext, useEffect } from 'react';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import { store } from 'components/Store.js';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import normalize from 'components/Dashboard/utilities/normalizeString';
import changeItemNamesPascalCase from './Helpers/changeItemsPascalCase';
import createResourcesName from './Helpers/createResourcesName';
import parliamentarianUsers from 'components/Dashboard/SidebarElements/parliamentarianUsers';
import { useSiteSectionsNames } from 'utils/getAppSite';

const Resources = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeSubscriptions, activePlan } = state;
  const { planCode } = activeSubscriptions;
  const {
    resources,
    typeAlert,
    allow,
    referenceValues,
    adminSubscriptions,
    scrollPosition,
    includeIcon,
    closeOnScroll,
    scrollRef = {},
  } = props;
  const enableTwitter =
    adminSubscriptions?.enableTwitter ?? activeSubscriptions?.enableTwitter ?? activePlan?.enableTwitter;
  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';
  const buttonRef = useRef();
  let initialContent =
    referenceValues ??
    (parliamentarianUsers(planCode)
      ? [
          'Parliament live',
          'Key updates',
          'Campaigns',
          'Consultations',
          'Legislation',
          'Official records',
          'Committee publications',
          'Library material',
          'Early Day Motions',
          'Answered written questions',
          'Newly tabled written questions',
        ]
      : [
          'Parliament live',
          'Key updates',
          'Consultations',
          'Legislation',
          'Official records',
          'Committee publications',
          'Lobbying material',
          'Library material',
          'Early Day Motions',
          'Answered written questions',
          'Newly tabled written questions',
        ]);
  let createContent = () => {
    if (showTweets && !initialContent.includes('Tweets')) {
      return [...initialContent, 'Tweets'];
    } else {
      return initialContent;
    }
  };

  let content = createContent();

  const [showResources, setShowResources] = useState(false);
  const resourcesElement = useRef(null);

  const ImmediateWarningText = `Questions & answers ${showTweets ? 'and Tweets ' : ''}often have a lot activity.<br/> Setting immediate alerts for ${showTweets ? 'these' : 'this'} resource${showTweets ? 's' : ''}<br/> may result in frequent notifications`;
  const hideResources = () => {
    setShowResources(false);
  };

  useOutsideElement(resourcesElement, hideResources);
  const [dropdownDimentions, setDropdownDimentions] = useState({ top: 0, width: 0 });

  const createDimentions = () => {
    let node = resourcesElement.current;
    if (node !== null) {
      const { top, height, width } = node.getBoundingClientRect() ?? {};
      const topValue = top + height - 2;
      const dropdownHeight = 200;
      const paddingValue = 40;
      const notSpaceAtBottom = dropdownHeight + topValue + paddingValue > window.innerHeight;
      setDropdownDimentions({
        top: topValue,
        width: width,
        maxHeight: notSpaceAtBottom ? top - 28 : window.innerHeight - topValue - 28,
      });
    }
  };

  useEffect(() => {
    //AE: To wait the popup appears and get the correct position
    setTimeout(() => {
      createDimentions();
    }, 300);
    window.addEventListener('resize', createDimentions);
    return () => {
      window.removeEventListener('resize', createDimentions);
    };
  }, [resourcesElement.current, typeAlert]);

  useEffect(() => {
    if (scrollPosition) {
      if (closeOnScroll) {
        setShowResources(false);
      }
      createDimentions();
    }
  }, [scrollPosition]);

  useEffect(() => {
    const createFixed = () => {
      setShowResources(false);
      createDimentions();
    };
    scrollRef.current?.addEventListener('scroll', createFixed);
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', createFixed);
      }
    };
  }, [scrollRef.current]);

  const warningConditionalClass =
    typeAlert === 'Immediate' &&
    (resources.includes('QuestionsAndAnswers') || resources.includes('Tweets')) &&
    !showResources &&
    allow;

  const topCondition = dropdownDimentions.top + dropdownDimentions.maxHeight > window.innerHeight;
  const getDropdownStyles = () => {
    let styleObj = {
      position: 'fixed',
      width: `${dropdownDimentions.width}px`,
      top: `${dropdownDimentions.top}px`,
    };
    if (topCondition) {
      styleObj = {
        ...styleObj,
        top: `${dropdownDimentions.top - dropdownDimentions.maxHeight - resourcesElement?.current?.getBoundingClientRect()?.height + 1}px`,
      };
    }
    return styleObj;
  };

  return (
    <>
      <div className={`position-relative ${showResources ? 'dropdown-open z-max' : ''}`} ref={resourcesElement}>
        <button
          className={`general-button pl-3 pr-4 mb-0 hour-select d-block col-16 text-left
        ${allow ? '' : 'disabled-hour-select'} ${showResources ? `hour-select-opendropdown ${dropdownDimentions.top + dropdownDimentions.maxHeight > window.innerHeight ? 'opened-dropdown-at-top' : ''}` : ''}
      `}
          onClick={() => {
            if (allow) {
              setShowResources(!showResources);
            }
          }}
          ref={buttonRef}
        >
          {includeIcon && (
            <span className='d-inline-block' style={{ marginTop: '1px', marginLeft: '1px' }}>
              <span
                className='icon-key-update mr-2 paragraph-p3 d-inline-block position-relative'
                style={{ verticalAlign: 'middle', top: '-1px' }}
              />
            </span>
          )}
          {createResourcesName(resources.join(', '), referenceValues, planCode)}
        </button>

        <WarningIconImmediate
          warningConditionalClass={warningConditionalClass}
          ImmediateWarningText={ImmediateWarningText}
        />

        {showResources && (
          <div
            className={`resources-dropdown p-0 rounded-${topCondition ? 'top' : 'bottom'}`}
            style={getDropdownStyles()}
          >
            <ul
              className={`policy-list scroll-container hover-container mb-0 resources-dropdown-list ${content?.length === 1 ? 'pb-0' : ''}`}
              style={{
                height: 'auto',
                maxHeight: `${dropdownDimentions.maxHeight}px`,
              }}
            >
              <div className='hover-content pl-2'>
                <ResourcesItems
                  {...props}
                  content={content}
                  enableTwitter={enableTwitter}
                  ImmediateWarningText={ImmediateWarningText}
                />
              </div>
            </ul>
          </div>
        )}
      </div>
      <ReactTooltip
        id={'resources-tooltip'}
        effect={'solid'}
        type={'info'}
        className={'pricing-feature-tooltip'}
        backgroundColor={'#00122B'}
        textColor={'#fff'}
        multiline={true}
        arrowColor={'transparent'}
        place={'right'}
      />
    </>
  );
};

const ResourcesItems = (props) => {
  const { content, enableTwitter, resources, changeResources, typeAlert, ImmediateWarningText, showRetweets } = props;
  const { getName } = useSiteSectionsNames();
  const immediateAlert = typeAlert === 'Immediate';
  const warningConditional = (item) => {
    const newlyTabledWrittenQuestions =
      resources.includes('UnansweredWrittenQuestion') && item === 'Newly tabled written questions';
    const answeredWrittenQuestions =
      resources.includes('AnsweredWrittenQuestion') && item === 'Answered written questions';
    const tweets = resources.includes('Tweets') && item === 'Tweets';
    return immediateAlert && (newlyTabledWrittenQuestions || answeredWrittenQuestions || tweets);
  };

  const checkItem = (name, index) => {
    const applyResources = () => {
      let newResources = resources.slice();
      if (resources.includes(name)) {
        if (newResources.length === 1) {
          createNotification('danger', `You must have at least 1 resource selected`);
        } else {
          let position = newResources.indexOf(name);
          newResources.splice(position, 1);
          if (name === 'Tweets' && newResources.includes('Retweets')) {
            let retweetsPosition = newResources.indexOf('Retweets');
            newResources.splice(retweetsPosition, 1);
          }
          changeResources(newResources);
        }
      } else {
        newResources.splice(index, 0, name);
        changeResources(newResources);
      }
    };

    if (name !== 'Tweets' || (name === 'Tweets' && (enableTwitter || resources.includes('Tweets')))) {
      applyResources();
    }
  };

  return (
    <>
      {content.map((item, index) => {
        let namePascalCase = changeItemNamesPascalCase(item);
        const disabledItem = item === 'Parliament live' && !immediateAlert;
        return (
          <div key={`resource-${item}`}>
            <li className={`checkbox-item ${disabledItem ? 'checkbox-item-disabled' : ''}`}>
              <input
                id={normalize(item)}
                onChange={() => {
                  checkItem(namePascalCase, index);
                }}
                type='checkbox'
                disabled={disabledItem}
                checked={resources.includes(namePascalCase)}
              />
              <label className={`${disabledItem ? 'light-60-text' : ''}`} htmlFor={normalize(item)}>
                {getName({ property: item })}
                {warningConditional(item) && <WarningElement ImmediateWarningText={ImmediateWarningText} />}
                {item === 'Tweets' && !enableTwitter && (
                  <span
                    className='icon-padlock d-inline-block-centered ml-2 centered-3'
                    data-tooltip-content='Twitter monitoring is available on Mogul'
                    data-tooltip-id='resources-tooltip'
                    src={`${process.env.REACT_APP_CDNURL}/images/padlock.svg`}
                    alt='padlock'
                  />
                )}
                {item === 'Parliament live' && (
                  <span
                    data-tooltip-class-name={'general-tooltip pricing-feature-tooltip report-popup-tooltip'}
                    data-tooltip-content={
                      immediateAlert
                        ? `If enabled, you'll receive alerts within 15 minutes of any relevant remark made in Parliament. Alerts are grouped every 15 minutes, but you can view them in real-time on the Monitor tab of the platform`
                        : 'Available for immediate alerts only'
                    }
                    data-tooltip-id='resources-tooltip'
                    className='icon-info d-inline-block-centered centered-3 ml-2 paragraph-p3'
                  />
                )}
              </label>
            </li>
            {item === 'Tweets' && showRetweets && (
              <div className='pl-4'>
                <div className={`checkbox-item`}>
                  <input
                    id={'retweets-0'}
                    onChange={() => {
                      if (enableTwitter || resources.includes('Tweets')) {
                        let newResources = resources.slice();
                        if (resources.includes('Retweets')) {
                          let position = newResources.indexOf('Retweets');
                          newResources.splice(position, 1);
                          changeResources(newResources);
                        } else {
                          newResources.push('Retweets');
                          if (!newResources.includes('Tweets')) {
                            newResources.push('Tweets');
                          }
                          changeResources(newResources);
                        }
                      }
                    }}
                    type='checkbox'
                    checked={resources.includes('Retweets')}
                  />
                  <label htmlFor={'retweets-0'}>Retweets</label>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

const WarningIconImmediate = (props) => {
  const { warningConditionalClass, ImmediateWarningText } = props;
  return (
    <>
      {warningConditionalClass && (
        <button
          className='general-button dropdown-next-icon warning-resources'
          data-tooltip-html={ImmediateWarningText}
          data-tooltip-id='resources-tooltip'
          data-tooltip-position-strategy={'fixed'}
        >
          Warning
        </button>
      )}
    </>
  );
};

const WarningElement = (props) => {
  const { ImmediateWarningText } = props;
  return (
    <>
      <img
        className='warning-resource'
        src={`${process.env.REACT_APP_CDNURL}/images/warning-icon-yellow.svg`}
        alt='warning-icon'
        data-tooltip-html={ImmediateWarningText}
        data-tooltip-id='resources-tooltip'
        data-tooltip-position-strategy={'fixed'}
      />
    </>
  );
};

export default Resources;
