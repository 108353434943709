import ModalComponent from 'components/Common/Modal';
import { useState } from 'react';

const TranscriberDeletePopup = (props) => {
  const { openState, eventIdState, deleteSpecificEvent } = props;
  const { isOpen, setIsOpen } = openState ?? {};
  const [isLoading, setIsLoading] = useState(false);

  const { eventId, setEventId } = eventIdState ?? {};
  const closeModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setEventId(null);
    }, 300);
  };

  const deleteCurrentEvent = async () => {
    try {
      setIsLoading(true);
      const result = await deleteSpecificEvent({ eventId });
      if (!!result) {
        closeModal();
      }
      setIsLoading(false);
    } catch (error) {}
  };
  return (
    <ModalComponent
      isOpen={isOpen}
      setIsOpen={closeModal}
      maxWidth={558}
      overlayClassName={'react-modal-overlay modal-close-position-adjustment'}
    >
      <div className='p-5 text-center'>
        <p className='title-h5-m'>Are you sure you want to delete this event?</p>
        <button className='general-button danger-button px-4 py-2' disabled={isLoading} onClick={deleteCurrentEvent}>
          {isLoading && <i className='fas fa-spinner fa-spin d-inline-block-centered mr-2' />}
          Delete event
        </button>
      </div>
    </ModalComponent>
  );
};

export { TranscriberDeletePopup };
