import React, { useEffect, useState } from 'react';

const ParliamentLiveTextGenerator = (props) => {
  const { htmlContent } = props;
  const [displayText, setDisplayText] = useState([]);
  const [charIndex, setCharIndex] = useState(0);
  const [flatChars, setFlatChars] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [prevHtmlContent, setPrevHtmlContent] = useState(null);

  const typingSpeed = 25;
  const deleteSpeed = 10;

  useEffect(() => {
    if (htmlContent !== prevHtmlContent) {
      setPrevHtmlContent(htmlContent);
      setIsDeleting(true);
    }
  }, [htmlContent]);

  useEffect(() => {
    if (isDeleting && displayText.length > 0) {
      const timeoutId = setTimeout(() => {
        setDisplayText((prev) => prev.slice(0, -1));
      }, deleteSpeed);
      return () => clearTimeout(timeoutId);
    }

    if (isDeleting && displayText.length === 0) {
      setIsDeleting(false);
      setCharIndex(0);

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      const nodes = Array.from(doc.body.childNodes);

      const extractCharacters = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          return node.textContent.split('').map((char) => ({ char, className: '' }));
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const childChars = Array.from(node.childNodes).flatMap(extractCharacters);
          return childChars.map(({ char }) => ({ char, className: node.className }));
        }
        return [];
      };

      setFlatChars(nodes.flatMap(extractCharacters));
    }
  }, [isDeleting, displayText.length, htmlContent]);

  useEffect(() => {
    if (!isDeleting && charIndex < flatChars.length) {
      const timeoutId = setTimeout(() => {
        setDisplayText((prev) => [...prev, flatChars[charIndex]]);
        setCharIndex((prev) => prev + 1);
      }, typingSpeed);
      return () => clearTimeout(timeoutId);
    }
  }, [charIndex, flatChars, isDeleting]);

  return (
    <span className='parliament-event-live-text'>
      {displayText.map(({ char, className }, index) => (
        <span key={index} className={className}>
          {char}
        </span>
      ))}
    </span>
  );
};

export default ParliamentLiveTextGenerator;
