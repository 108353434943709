import { Portal } from 'react-portal';
import { Link } from 'react-router-dom';
import ModalComponent from 'components/Common/Modal';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import useModifyAnalyticsState from 'components/Dashboard/Analytics/useModifyAnalyticsState';
import { comprehensiveText } from 'utils/getAppSite';

const AnalyticsRightPanelPopup = ({ isOpen }) => {
  const { modifyState } = useModifyAnalyticsState();
  const { agencyUser } = useTopicOrClientWord();

  const hidePanelPopup = () => {
    modifyState({ property: 'restrictPanel', newValue: false });
  };

  return (
    <Portal>
      <ModalComponent maxWidth={1020} isOpen={isOpen} setIsOpen={hidePanelPopup}>
        <div className='bg-white rounded-lg text-center relative'>
          <div className='d-lg-flex align-items-center'>
            <div className='bg-main-blue text-white  p-4 p-lg-5 text-left' style={{ minWidth: '720px' }}>
              <h3 className=' title-h2-bold mb-2'>Delve into the data</h3>
              <p className='mb-5 title-h5-m' style={{ maxWidth: '500px' }}>
                PolicyMogul is an all-in-one public affairs platform powered by {comprehensiveText()} political
                monitoring service.
              </p>
              <img
                className='image-render-optimized img-fluid mb-3'
                src={`${process.env.REACT_APP_CDNURL}/images/202305181-preview-insights.png`}
                alt={`Insights`}
              />
            </div>
            <div className='grow px-4 px-lg-5 pb-5 mb-3 text-left d-none d-lg-block'>
              <h3 className='title-h3-bold pt-5 mb-5'>Insights</h3>

              <ul className='policy-list text-left title-h5-m statistics-popup-list px-0 pb-5 mb-5'>
                {!agencyUser && (
                  <>
                    <li className='mb-3 nowrap-item text-main-blue statistics-list-active-item '>
                      <span className='title-h3 mr-3 icon-close-circle' />
                      Campaigner
                    </li>
                    <li className='mb-3 nowrap-item '>
                      <span className={`title-h3 mr-3 icon-close-circle`} />
                      Pro
                    </li>
                  </>
                )}
                <li className='mb-3 nowrap-item '>
                  <span className='title-h3 mr-3 icon-close-circle' />
                  Mogul
                </li>
                <li className='mb-3 nowrap-item '>
                  <span className='title-h3 mr-3 icon-small-check' />
                  Enterprise
                </li>
              </ul>

              <div className='text-center text-lg-right mt-4 mt-lg-0 w-100 pt-5'>
                <Link
                  className='action-button rounded-button py-2 px-4 px-xl-5 title-h5 d-inline-block'
                  to={`/pricing`}
                >
                  Compare plans
                  <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </Portal>
  );
};

export default AnalyticsRightPanelPopup;
