import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import DayPicker from 'react-day-picker';

const TranscriberDatePicker = (props) => {
  const { date, buttonClass = 'rounded-pill px-4 ', rightPosition = 10, daySelectFunction, showTime } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(dayjs(date).toDate());
  const [hourSelected, setHourSelected] = useState(dayjs()?.format('HH:MM'));
  const hideActions = () => {
    setIsOpen(false);
  };

  const actionsElement = useRef();
  useOutsideElement(actionsElement, hideActions);

  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setSelectedDay(day);
    daySelectFunction({ day: dayjs(day)?.format('YYYY-MM-DD'), time: hourSelected });
    setIsOpen(false);
  };

  return (
    <>
      <div className='position-relative' ref={actionsElement}>
        <button
          className={`border general-button specific-y-padding-5 bg-main-white mr-3 hovered-button ${buttonClass}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {!!date ? (
            dayjs(date)?.format(`YYYY-MM-DD${showTime ? '-HH:MM' : ''}`)
          ) : (
            <span className='main-light-text'>Select a date</span>
          )}
        </button>
        {isOpen && (
          <div className='position-absolute right-0' style={{ right: `${rightPosition}px`, top: '40px' }}>
            <div className='tweets-calendar calendar-picker reference-calendar-picker px-4' style={{ width: '240px' }}>
              {showTime && (
                <div className='py-2'>
                  <input
                    type='time'
                    value={hourSelected}
                    className='form-control main-input py-1'
                    onChange={(e) => {
                      setHourSelected(e.target.value);
                      daySelectFunction({
                        day: dayjs(selectedDay)?.format('YYYY-MM-DD'),
                        time: e.target.value,
                      });
                    }}
                  />
                </div>
              )}
              <DayPicker
                month={dayjs(selectedDay).toDate()}
                className='Selectable'
                onDayClick={handleDayClick}
                selectedDays={selectedDay}
                firstDayOfWeek={1}
                showOutsideDays
                disabledDays={(day) => day > dayjs().toDate()}
                weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TranscriberDatePicker;
