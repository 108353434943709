import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ParliamentaryLiveRemarksWidget = (props) => {
  const { hits, reachedTheEnd } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const keyPressFunction = (e) => {
    let type;
    if (e.code === 'ArrowRight') {
      type = 'next';
    } else if (e.code === 'ArrowLeft') {
      type = 'prev';
    }
    if (type && !disabledItem({ type })) {
      navigateFunction({ type });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyPressFunction);
    return () => {
      document.removeEventListener('keydown', keyPressFunction);
    };
  }, [location.search]);

  const itemPosition = () => {
    let position;
    const currentId = decodeURIComponent(getUrlParam('in'));
    if (currentId !== '' && !!hits) {
      position = hits?.findIndex((item) => {
        const url = new URL(item?.contentUrl);
        const searchParameters = new URLSearchParams(url.search);
        const timeParameter = searchParameters.get('in');
        return timeParameter === currentId;
      });
    } else {
      position = -1;
    }
    return position;
  };

  const widgetItemPosition = () => {
    let position = itemPosition();
    if (reachedTheEnd && position + 1 < hits.length) {
      return `${position + 1}-${hits.length}`;
    } else if (position === undefined || position < 0) {
      return null;
    } else {
      return position + 1;
    }
  };

  const disabledItem = ({ type }) => {
    let position = itemPosition();
    if (position !== undefined) {
      if (type === 'next') {
        return position >= hits.length - 1;
      }
      if (type === 'prev') {
        return position <= 0;
      }
    }
    return true;
  };

  const navigateFunction = ({ type }) => {
    let position = itemPosition();
    const newPosition = type === 'next' ? (position += 1) : (position -= 1);
    const newItem = hits[newPosition];
    const search = new URLSearchParams(location?.search);
    const newTimeUrl = new URL(newItem.contentUrl);
    const newTimeSearch = new URLSearchParams(newTimeUrl?.search);
    search.set('in', encodeURIComponent(newTimeSearch.get('in')));
    navigate(`${location.pathname}?${search?.toString()}`);
  };

  return (
    <div className='flex-centered'>
      <div className='pl-3 py-3 flex-grow-1 overflow-hidden'>
        <p className='mb-0'>
          {widgetItemPosition() && hits.length > 1 ? `${widgetItemPosition()} of ` : ''}
          {hits.length} relevant remark
          {hits.length === 1 ? '' : 's'}
        </p>
      </div>
      {hits.length > 0 && (
        <div className='flex-centered total-contributions-buttons'>
          {hits?.length > 1 ? (
            <>
              {widgetItemPosition() && (
                <button
                  className={`general-button total-contributions-prev`}
                  disabled={disabledItem({ type: 'prev' })}
                  onClick={() => {
                    navigateFunction({ type: 'prev' });
                  }}
                >
                  <span className='icon-arrow-back'></span>
                </button>
              )}

              <button
                className={`general-button total-contributions-next`}
                disabled={disabledItem({ type: 'next' }) || reachedTheEnd}
                onClick={() => {
                  navigateFunction({ type: 'next' });
                }}
              >
                <span className='icon-arrow-next'></span>
              </button>
            </>
          ) : (
            <button
              className={`general-button total-contributions-next`}
              onClick={() => {
                const search = new URLSearchParams(location?.search);
                search.delete('in');
                navigate({ search: search?.toString() }, { replace: true });
              }}
            >
              <span className='icon-arrow-next'></span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ParliamentaryLiveRemarksWidget;
