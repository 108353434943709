import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import dayjs from 'dayjs';

const useTranscribersCalls = () => {
  const { generalApiCall } = useGeneralApiCall();
  const listTranscribers = async ({ source }) => {
    try {
      const pathname = `/api/admin/transcriber/list-transcribers`;
      return await generalApiCall({
        pathname,
        method: 'get',
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
      });
    } catch (error) {}
  };
  const transcribersActions = async ({ action = 'start', taskId, eventId, source }) => {
    try {
      const pathname = `/api/admin/transcriber/${action}-transcriber?${taskId ? 'taskId' : 'eventId'}=${taskId ?? eventId}`;
      return await generalApiCall({
        pathname,
        method: 'post',
        needsAuthentication: true,
        returnCompleteRequest: true,
        requestSource: source,
      });
    } catch (error) {}
  };

  const getEvents = async ({ source, date }) => {
    try {
      const pathname = `/api/admin/transcriber/get-events?isoDate=${dayjs(date ?? new Date())?.format('YYYY-MM-DD')}`;
      return await generalApiCall({
        pathname,
        method: 'get',
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
      });
    } catch (error) {}
  };

  const getEvent = async ({ source, eventId }) => {
    try {
      const pathname = `/api/admin/transcriber/get-event?id=${eventId}`;
      return await generalApiCall({ pathname, method: 'get', needsAuthentication: true, requestSource: source });
    } catch (error) {}
  };

  const updateEvent = async ({ source, event }) => {
    try {
      const pathname = `/api/admin/transcriber/update-event`;
      return await generalApiCall({
        pathname,
        method: 'post',
        requestProperties: event,
        needsAuthentication: true,
        requestSource: source,
      });
    } catch (error) {}
  };

  const deleteEvent = async ({ source, eventId }) => {
    try {
      const pathname = `/api/admin/transcriber/delete-event?id=${eventId}`;
      return await generalApiCall({
        pathname,
        method: 'delete',
        needsAuthentication: true,
        requestSource: source,
        returnCompleteRequest: true,
      });
    } catch (error) {}
  };

  return { updateEvent, deleteEvent, getEvent, getEvents, transcribersActions, listTranscribers };
};

export default useTranscribersCalls;
