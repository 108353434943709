import React, { useContext, useRef } from 'react';
import { store } from 'components/Store.js';
import { formatEmbargoedDate } from 'components/Dashboard/helpers/formatGeneralDate.js';
import normalize from 'components/Dashboard/utilities/normalizeString.js';
import RenderingTags from './RenderingTags.js';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile.js';

const TagsLists = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, keywordsLists } = state;

  const {
    index,
    title,
    embargoed,
    feedItemFlags,
    embargoedUntil,
    calendarTags,
    live,
    remarkInformation,
    watchItem,
    ended,
  } = props;
  const topicIdQuery = getUrlParam('topic-id');
  const list =
    search?.list === 'noListsCreated'
      ? search?.list
      : keywordsLists.find((item) => item.id === (topicIdQuery === 'all' ? null : parseInt(topicIdQuery)));

  const tagsContainer = useRef();

  const { newAgencyPlan } = useTopicOrClientWord();
  const isMobile = useIsMobile();

  const feedItemsConditional = list !== null && feedItemFlags?.length > 0;
  const handPicked = feedItemsConditional && feedItemFlags[index]?.isHandPicked;
  const listConditions = keywordsLists?.length > 1 || newAgencyPlan;
  const matchingKeywordLists =
    (feedItemsConditional || calendarTags) && listConditions && feedItemFlags[index]?.matchingKeywordLists?.length > 0;
  const createMatchingKeywords = () => {
    let terms = [];
    if (matchingKeywordLists) {
      if (newAgencyPlan && calendarTags) {
        let lists = feedItemFlags[index].matchingKeywordLists;
        let finalLists = [];
        for (let i = 0; i < lists?.length; i++) {
          let list = lists[i];
          let teamNamePosition = finalLists?.findIndex((item) => item.teamName === list?.teamName);
          if (teamNamePosition >= 0) {
            let finalItem = finalLists[teamNamePosition];
            finalLists[teamNamePosition] = { ...finalItem, topics: [...finalItem?.topics, list?.name] };
          } else {
            finalLists.push({ ...list, topics: [list?.name] });
          }
        }
        terms = finalLists;
      } else {
        terms = feedItemFlags[index].matchingKeywordLists;
      }
    }
    if (embargoed) {
      let embargoedItem = {
        embargoed: true,
        name: `Embargoed ${!!embargoedUntil ? `until ${formatEmbargoedDate(embargoedUntil)}` : ''}`,
      };
      if (embargoedUntil) {
        embargoedItem.embargoedUntil = embargoedUntil;
      }
      terms = [embargoedItem, ...terms];
    }

    if (live) {
      let liveItem = {
        live: true,
        name: ended ? 'Ended' : 'Live',
        ended,
      };
      if (remarkInformation) {
        liveItem = {
          ...liveItem,
          ...remarkInformation,
        };
        terms = isMobile ? [...terms] : [liveItem, ...terms];
      } else {
        terms = [liveItem, ...terms];
      }
    }

    return terms;
  };
  return (
    <>
      <div
        ref={tagsContainer}
        className={`explanations specific-bottom-padding-6 mb-0 row mx-0 align-items-start 
      ${title ? `explanations-${normalize(title)}` : ''} ${embargoed || handPicked ? 'explanations-handpicked' : ''}`}
      >
        {(matchingKeywordLists || embargoed || live || watchItem) && (
          <>
            <RenderingTags terms={createMatchingKeywords()} matchingKeywordLists calendarTags={calendarTags} />
          </>
        )}
      </div>
    </>
  );
};

export default TagsLists;
