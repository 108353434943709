import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TrialBanner from 'components/Dashboard/Navigation/TrialBanner';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { christmasCondition } from 'components/Dashboard/Navigation';
const SettingsHeader = (props) => {
  const param = getUrlParam('returnUrl');
  const returnUrl = `${param !== undefined ? decodeURIComponent(param) : '/monitor-inbox'}`;

  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();

  const regex = /^\/settings\/topics\/\d+$/;
  const backButtonCondition = regex.test(location?.pathname) && !location.state?.prevPath;
  const { agencyUserBase } = useTopicOrClientWord();

  return (
    <>
      <TrialBanner />
      <div className='py-3 px-3 px-lg-0 settings-header'>
        <div className='mx-0 justify-content-between align-items-center row mx-auto main-content-wrapper w-100'>
          <div className='pl-md-5 pl-3 side-left-element'>
            <div className='d-flex position-relative'>
              <Link
                to={returnUrl}
                className={`settings-logo d-block ${christmasCondition() ? 'settings-logo-christmas' : ''}`}
              />
              <Link to={returnUrl} className='close-name-settings d-inline-block'>
                Close settings
              </Link>
            </div>
          </div>
          <div
            className={`main-content setting-clients-picker-container d-flex justify-content-${backButtonCondition ? 'between' : 'end'} align-items-center pr-xl-5 px-md-4 px-3`}
          >
            {backButtonCondition && (
              <div className='pr-lg-3 d-none d-lg-block'>
                <button
                  className='border-general border-light-blue general-button mb-0 rounded bg-main-white nowrap-item specific-y-padding-5 px-4 text-light-blue action-state-hover d-inline-block text-decoration-none general-link'
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <span className='icon-long-arrow-left d-inline-block-centered paragraph-p5 centered-2 mr-2 ' /> Back
                  to {agencyUserBase ? 'clients' : 'topics'}
                </button>
              </div>
            )}
            <Link
              className={`${isMobile ? 'close-settings' : 'text-decoration-none third-button rounded text-white specific-y-padding-5 pl-3 pr-4'}  d-none d-lg-block`}
              to={returnUrl}
              data-tooltip-content={isMobile ? 'Close settings' : ''}
              data-tooltip-id='settings-navbar-tooltip'
            >
              {isMobile ? (
                ''
              ) : (
                <>
                  <span className='icon-close paragraph-p5 specific-right-margin-13' />
                  Close settings
                </>
              )}
            </Link>
          </div>
        </div>
      </div>
      <ReactTooltip
        id='settings-navbar-tooltip'
        effect={'solid'}
        className={'header-tooltip'}
        multiline={true}
        arrowColor={'transparent'}
        place={'right'}
        globalEventOff={isMobile ? 'click' : undefined}
        delayShow={300}
      />
    </>
  );
};
export default SettingsHeader;
