import Axios from 'axios';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import TextGenerate from 'components/Common/TextGenerator';
import { SelectedEventSkeleton } from 'components/Dashboard/LiveFromParliament/EventListSkeleton';
import useEventListFunctions, {
  useCallEventListItem,
} from 'components/Dashboard/LiveFromParliament/useEventListFunctions';
import { CreateAlertPopup, SearchEventPopup } from 'components/Dashboard/Navigation/NotFeaturePopups';
import checkEllipsis from 'components/Dashboard/utilities/checkEllipsis';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);

function SelectedEvent({ selectedEvent }) {
  const {
    eventId,
    transcriberRunning,
    actualStartDateTime,
    scheduledStartDateTime,
    actualEndDateTime,
    subject,
    createdAtUtc,
  } = selectedEvent ?? {};

  const [eventData, setEventData] = useState({});
  const [eventBodies, setEventBodies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(null);
  const [allowScrollBottom, setAllowScrollBottom] = useState(true);
  const [initialDate, setInitialDate] = useState(null);
  const [transcriptionButtonPosition, setTranscriptionButtonPosition] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [isOpenCreateAlertPopup, setIsOpenCreateAlertPopup] = useState(false);

  const isMobile = useIsMobile();
  const { callEvents } = useEventListFunctions();

  useCallEventListItem({
    condition: transcriberRunning && !isLoading,
    eventId,
    data: eventBodies,
    setData: setEventBodies,
    date: initialDate,
  });

  const conditionToShowGoToTranscriptionButton = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e ?? scrollBarRef?.current ?? {};
    return scrollTop + 20 < scrollHeight - clientHeight;
  };

  const [showGoToTranscriptionButton, setShowGoToTranscriptionButton] = useState(false);
  const [heightContainer, containerRef] = useHeightContainer();
  const scrollBarHeight = isMobile ? 300 : heightContainer;

  const scrollBarRef = useRef(null);

  const callEventsFunc = useRef();
  const subjectElement = useRef();
  const goTranscriptionButton = useRef();
  const contentContainer = useRef();

  useEffect(() => {
    if (contentContainer?.current) {
      setTranscriptionButtonPosition(
        contentContainer.current?.getBoundingClientRect()?.left +
          (contentContainer.current?.getBoundingClientRect()?.width -
            goTranscriptionButton?.current?.getBoundingClientRect()?.width) /
            2
      );
    }
  }, [contentContainer?.current]);

  callEventsFunc.current = async (source) => {
    try {
      setIsLoading(true);

      const dateUK = dayjs().tz('Europe/London');
      const initialDateEvent = dateUK.isSame(createdAtUtc, 'day') ? null : createdAtUtc;
      const result = await callEvents({ source, eventId, setInitialDate, specificDate: initialDateEvent });
      if (!!result) {
        const eventBodies = result?.hits?.map((item) => ({ text: item.body }));
        setEventBodies(eventBodies);
        setEventData(result?.hits?.[0]);
        setIsLoading(false);

        setTimeout(
          () =>
            scrollBarRef?.current?.view?.scroll({
              top: scrollBarRef?.current?.view?.scrollHeight,
              behavior: 'smooth',
            }),
          10
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    if (selectedEvent) {
      callEventsFunc.current(source);
    }
    return () => {
      source.cancel('Events cancelled by user');
    };
  }, [selectedEvent]);

  const scrollToBottom = () => {
    if (allowScrollBottom && !scrolling) {
      setTimeout(
        () =>
          scrollBarRef?.current?.view?.scroll({
            top: scrollBarRef?.current?.view?.scrollHeight,
            behavior: 'smooth',
          }),
        10
      );
    }
  };

  const bodyToGenerate = eventBodies
    .filter((item) => item.notShown)
    .map((item) => item.text)
    .join('\n');

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e;
    setShowGoToTranscriptionButton(
      ((transcriberRunning && bodyToGenerate.length > 0) || (!transcriberRunning && actualEndDateTime)) &&
        conditionToShowGoToTranscriptionButton(e)
    );
    setScrollPosition(e.scrollTop);
    const scrolledToBottom = scrollTop === scrollHeight - clientHeight;
    setAllowScrollBottom(scrolledToBottom);
  };

  const topFadeCondition = scrollPosition > 20 && actualStartDateTime;
  const bottomFadeCondition =
    scrollPosition + scrollBarHeight < scrollBarRef?.current?.view?.scrollHeight - 50 && actualStartDateTime;

  const conditionOfContent = eventBodies?.length > 0;
  return (
    <>
      {isLoading || !selectedEvent ? (
        <SelectedEventSkeleton />
      ) : (
        <div className='rounded bg-white border specific-top-padding-30 px-3 px-lg-5' ref={contentContainer}>
          {conditionOfContent && (
            <>
              {transcriberRunning && (
                <p>
                  <span className='tag-keyword-list-live'>
                    <span className='element-record'></span>
                  </span>
                  <span className='light-60-text'>Transcribing...</span>
                </p>
              )}
              {actualEndDateTime && (
                <p className='element-record-past-event'>
                  <span className='icon-record mr-2 color-main-yellow'></span>
                  <span className='light-60-text'>This event was transcribed in real time</span>
                </p>
              )}
            </>
          )}
          <div>
            {conditionOfContent && (
              <>
                <h3 className='title-h5 font-weight-bold'>{eventData?.title}</h3>
                <p className={`heading-date mb-0 pt-2 d-flex align-items-center`}>
                  {subject && (
                    <>
                      <span
                        ref={subjectElement}
                        className={`paragraph-p3 parliament-live-item-subject`}
                        data-tooltip-content={checkEllipsis(subjectElement?.current) ? subject : ''}
                        data-tooltip-id={'general-tooltip'}
                      >
                        {subject}
                      </span>
                      <span className='dot mt-0'></span>
                    </>
                  )}
                  <span className='paragraph-p3 nowrap-item'>
                    {`${actualStartDateTime ? 'Started' : 'Starts'} at ${dayjs(
                      actualStartDateTime ?? scheduledStartDateTime
                    )
                      .format('HH:mm')
                      .replaceAll(' ', '')
                      .toLowerCase()}`}
                  </span>
                </p>
                <div className='d-flex align-items-center mt-4 mb-3 flex-wrap flex-lg-nowrap gap-15'>
                  <a
                    href={
                      eventData?.contentUrl
                        ? new URL(eventData?.contentUrl)?.origin + new URL(eventData?.contentUrl)?.pathname
                        : ''
                    }
                    target='_blank'
                    rel={'noopener noreferrer'}
                    className='specific-x-padding-24 py-1 rounded-pill border bg-main-white general-button hovered-button text-light-blue link-hover reset-link nowrap-item'
                  >
                    <span className='icon-single-play d-inline-block-centered mr-2' />
                    {actualStartDateTime && <>{transcriberRunning ? 'Watch this event live' : `Watch this event`}</>}
                  </a>
                  <SearchInput />
                </div>
              </>
            )}
            <div ref={containerRef}></div>
            <CustomScrollbar
              onScroll={(e) => {
                handleScroll(e);
              }}
              onScrollStart={() => {
                setScrolling(true);
              }}
              onScrollStop={() => {
                setScrolling(false);
              }}
              className={`main-content-scrollbar ${topFadeCondition ? 'scrollbar-top-fade' : ''} ${bottomFadeCondition ? 'scrollbar-bottom-fade' : ''} `}
              style={{ height: `${scrollBarHeight}px` }}
              maximalThumbYSize={100}
              ref={scrollBarRef}
              contentClass={'ScrollbarsCustom-Content overscroll-behavior-contain'}
            >
              <div className='selected-event-bodies-container'>
                {conditionOfContent ? (
                  <>
                    {eventBodies
                      .filter((item) => !item.notShown)
                      .map((item, i) => {
                        return (
                          <p key={`event-text-${i}`} className='paragraph-p1 line-height-1-5 mb-1'>
                            {item.text}
                          </p>
                        );
                      })}

                    {bodyToGenerate.length > 0 && (
                      <p className='paragraph-p1 line-height-1-5 mb-5'>
                        <TextGenerate
                          key={`generated-text-${eventBodies?.length}`}
                          onCharacterAdd={(i) => {
                            if (i % 10 === 0) scrollToBottom();
                          }}
                        >
                          {bodyToGenerate}
                        </TextGenerate>
                      </p>
                    )}
                  </>
                ) : (
                  <p className='paragraph-p1 line-height-1-5 mb-5 specific-top-margin-80 text-center light-65-text'>
                    When this event starts, the live transcription will appear here. To be alerted in real time if your
                    organisation or key interests are mentioned,{' '}
                    <button
                      className='general-button text-underline-hover blue-text cursor-pointer'
                      onClick={() => setIsOpenCreateAlertPopup(true)}
                    >
                      create a keyword alert
                    </button>
                  </p>
                )}
                <button
                  ref={goTranscriptionButton}
                  className='rounded-pill bg-main-blue px-4 py-2 general-button position-fixed text-white z-max go-to-live-transcription'
                  style={{
                    visibility: showGoToTranscriptionButton ? `visible` : 'hidden',
                    opacity: showGoToTranscriptionButton ? 1 : 0,
                    bottom: '20px',
                    left: `${transcriptionButtonPosition}px`,
                  }}
                  onClick={() => {
                    setShowGoToTranscriptionButton(false);
                    scrollBarRef?.current?.view?.scroll({
                      top: scrollBarRef?.current?.view?.scrollHeight,
                      behavior: 'smooth',
                    });
                    setAllowScrollBottom(true);
                  }}
                >
                  <span className='dropdown-item-element dropdown-item-element-11'>
                    {transcriberRunning ? 'Go to live transcription' : 'Go to end of transcription'}
                  </span>
                </button>
              </div>
            </CustomScrollbar>
          </div>
        </div>
      )}
      <CreateAlertPopup isOpen={isOpenCreateAlertPopup} setIsOpen={setIsOpenCreateAlertPopup} />
    </>
  );
}

const SearchInput = () => {
  const [searchValue, setSearchValue] = useState('');
  const [openSearchPopup, setOpenSearchPopup] = useState(false);
  const inputSearch = useRef();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'f') {
        event.preventDefault();
        setOpenSearchPopup(true);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <div className='rounded-pill border flex-centered specific-right-padding-7 specific-left-padding-15 specific-y-padding-6 flex-grow-1 bg-white specific-right-margin-10 search-events-input'>
        <div className='flex-grow-1 d-flex align-items-center'>
          <span className='icon-search mr-2'></span>
          <input
            className='input-empty w-100 paragraph-p1 pr-2'
            placeholder='Search within this transcript'
            value={searchValue}
            ref={inputSearch}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                inputSearch.current.blur();
                setOpenSearchPopup(true);
              }
            }}
          />
        </div>
        <div
          className='close-icon-container'
          style={{
            visibility: searchValue !== '' ? 'visible' : 'hidden',
          }}
        >
          <button
            className='general-button close-icon'
            data-tooltip-content='Clear search'
            data-tooltip-id='general-tooltip'
            data-tooltip-place='right'
            onClick={(e) => {
              e.stopPropagation();
              setSearchValue('');
            }}
          />
        </div>
      </div>
      <SearchEventPopup
        isOpen={openSearchPopup}
        setIsOpen={setOpenSearchPopup}
        onAfterClose={() => {
          setSearchValue('');
        }}
      />
    </>
  );
};
export default SelectedEvent;
