import React, { useContext } from 'react';
import removeLocalStorage from 'components/Dashboard/Navigation/RemoveLocalStorage';
import { AdminStore } from 'components/Admin/AdminState';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';

const AdminHeader = (props) => {
  const { headerProperites } = props;
  const location = useLocation();
  const adminState = useContext(AdminStore);
  const { state } = adminState;
  const { organisation } = state ?? {};
  const { name } = organisation ?? {};
  const { urlLink } = headerProperites;
  const nameCondition = name && location.pathname !== '/admin';
  const isMobile = useIsMobile(480);

  return (
    <div className='py-3 px-3 px-lg-0 settings-header'>
      <div
        className={`mx-0 align-items-center row mx-auto main-content-wrapper w-100 px-lg-5 px-3 position-relative justify-content-between ${
          nameCondition && isMobile ? 'flex-column' : ''
        }`}
      >
        <Link to={urlLink()}>
          <img src={`${process.env.REACT_APP_CDNURL}/images/logo.svg`} alt='Logo' className='policy-logo' />
        </Link>
        <div className='flex-centered'>
          <Link
            className='general-button reset-link general-link text-light-blue px-4 py-2 hovered-button rounded mr-2'
            to={'/admin/transcribers'}
          >
            <span className='icon-play d-inline-block-centered centered-3 title-h5-m mr-2' />
            Live transcribers
          </Link>
          {nameCondition ? (
            <div className='d-flex justify-content-between align-items-center mt-3 mt-lg-0 flex-lg-grow-1 team-header-title-container'>
              <h3 className='team-header-title main-title mb-0 flex-grow-1'>{name}</h3>
              <LogoutButton headerProperites={headerProperites} />
            </div>
          ) : (
            <LogoutButton headerProperites={headerProperites} />
          )}
        </div>
      </div>
    </div>
  );
};

const LogoutButton = (props) => {
  const { headerProperites } = props ?? {};
  const { logoutWithRedirect } = headerProperites;
  return (
    <button
      className='general-button logout-button px-4 py-2 align-self-end ml-2'
      onClick={() => {
        removeLocalStorage();
        logoutWithRedirect();
      }}
    >
      Logout
    </button>
  );
};
export default AdminHeader;
