import Axios from 'axios';
import EmptyMessage from 'components/Dashboard/components/EmptyMessage';
import useCreateResults from 'components/Dashboard/ListControl/useCreateResults';
import { LiveFromParliamentSkeleton } from 'components/Dashboard/LiveFromParliament/EventListSkeleton';
import EventsList from 'components/Dashboard/LiveFromParliament/EventsList';
import EventsListEmpty from 'components/Dashboard/LiveFromParliament/EventsListEmpty';
import SelectedEvent from 'components/Dashboard/LiveFromParliament/SelectedEvent';
import Navigation from 'components/Dashboard/Navigation';
import { CreateAlertPopup } from 'components/Dashboard/Navigation/NotFeaturePopups';
import useCallParliamentLive from 'components/Dashboard/ParliamentLive/useCallParliamentLive';
import useGetRealTimeEvents from 'components/Dashboard/ParliamentLive/useGetRealtimeEvents';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import Footer from 'components/home/Footer';
import useScrollBody from 'components/home/useScrollBody';
import { store } from 'components/Store';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function LiveFromParliament() {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contentResults, activeResults } = state ?? {};
  const { ParliamentLive } = contentResults[activeResults];

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [remainingSpaceBoxHeght, setRemainingSpaceBoxHeght] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const { getRealTimeEvents } = useGetRealTimeEvents();
  const { createResults } = useCreateResults({ sectionName: 'ParliamentLive' });
  const isMobile = useIsMobile();

  useScrollBody();

  const search = getUrlParam('search');

  const sortResults = (a, b) => {
    return (
      new Date(a.actualStartDateTime ?? a.scheduledStartDateTime ?? a.eventStartDateTime).getTime() -
      new Date(b.actualStartDateTime ?? b.scheduledStartDateTime ?? b.eventStartDateTime).getTime()
    );
  };

  const callEventsFunc = useRef();
  callEventsFunc.current = async ({ source }) => {
    try {
      let totalEvents = [...events];
      setIsLoading(true);
      if (events.length === 0) {
        totalEvents = await getRealTimeEvents({ source, needsAuthentication: false, notUseStorage: true });
      }
      const results = await createResults({ source, setMarker: false });
      if (!!results) {
        if (events.length === 0) {
          setEvents(totalEvents);
        }
        if (results?.allResults?.ParliamentLive?.hits?.length > 0) {
          const liveEvents = results?.allResults?.ParliamentLive?.hits?.filter((item) => {
            const eventItem = totalEvents?.find((event) => event?.id === item?.eventId);
            return !!eventItem?.transcriberRunning;
          });
          const firstEvent =
            liveEvents.length > 0 ? liveEvents?.sort(sortResults)?.[0] : results?.allResults?.ParliamentLive?.hits?.[0];
          const eventItem = totalEvents?.find((event) => event?.id === firstEvent?.eventId);
          setSelectedEvent(eventItem);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    callEventsFunc.current({ source });
    return () => {
      source.cancel('Events cancelled by user');
    };
  }, [search]);

  const resultsToRender = ParliamentLive?.hits;
  const noResultsFoundCondition = resultsToRender?.length === 0 && events?.length === 0;

  useEffect(() => {
    const lockScrolling = (e) => {
      const overscrollBehaviorContainElem =
        e.target.closest('.overscroll-behavior-contain') && !e.target.closest('.ScrollbarsCustom-Content');
      if (!!overscrollBehaviorContainElem) {
        e.preventDefault();
      }
    };
    window.addEventListener('DOMMouseScroll', lockScrolling, false);
    document.addEventListener('wheel', lockScrolling, { passive: false });
    return () => {
      window.removeEventListener('DOMMouseScroll', lockScrolling, false);
      document.removeEventListener('wheel', lockScrolling, { passive: false });
    };
  }, []);

  useCallParliamentLive();

  const Title = () => {
    return (
      <>
        <h3 className='text-uppercase font-weight-bold paragraph-p2 main-light-text'>Political monitoring</h3>
        <h3 className='events-list-title font-weight-bold specific-bottom-margin-16'>Live from Parliament</h3>
      </>
    );
  };

  return (
    <>
      <Navigation />
      <section className='pb-5 pb-lg-5 bg-salmon overflow-hidden'>
        <div
          className={`d-lg-flex ${noResultsFoundCondition ? '' : 'justify-content-between'} pr-3 pr-lg-5 specific-top-padding-78 main-content-wrapper mx-auto gap-30 free-tools-container ${isMobile ? 'pt-4' : ''}`}
        >
          {isLoading ? (
            <LiveFromParliamentSkeleton title={<Title />} />
          ) : (
            <>
              {noResultsFoundCondition ? (
                <div className='pl-3 pl-lg-5 max-width-none flex-grow-1'>
                  <EventsListEmpty />
                </div>
              ) : (
                <>
                  <div
                    className={`position-relative flex-grow-1 overscroll-behavior-contain ${resultsToRender?.length > 0 ? '' : 'max-width-none'}`}
                  >
                    <div className={`pl-3 pl-lg-5 free-tools-title  ${resultsToRender?.length > 0 ? '' : 'w-50'}`}>
                      <Title />
                      <ActionBar setSelectedEvent={setSelectedEvent} />
                    </div>
                    {resultsToRender?.length > 0 ? (
                      <EventsList
                        selectedEvent={selectedEvent}
                        setSelectedEvent={setSelectedEvent}
                        resultsToRender={resultsToRender}
                        isLoading={isLoading}
                        sortResults={sortResults}
                        remainingSpaceBoxHeght={remainingSpaceBoxHeght}
                        setRemainingSpaceBoxHeght={setRemainingSpaceBoxHeght}
                        events={events}
                      />
                    ) : (
                      <div className='specific-top-padding-37'>
                        <EmptyMessage />
                      </div>
                    )}
                  </div>
                  {resultsToRender?.length > 0 && (
                    <div className='flex-grow-1 overscroll-behavior-contain d-none d-lg-block'>
                      <SelectedEvent selectedEvent={selectedEvent} />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
}

const ActionBar = ({ setSelectedEvent }) => {
  const [searchValue, setSearchValue] = useState('');
  const [isOpenCreateAlertPopup, setIsOpenCreateAlertPopup] = useState(false);
  const inputSearch = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const searchQuery = getUrlParam('search') ? decodeURIComponent(getUrlParam('search')) : '';
    setSearchValue(searchQuery);
  }, [location.search]);

  const searchResults = (value) => {
    if (value.trim() === '') {
      query.delete('search');
    } else {
      query.set('search', encodeURIComponent(value));
    }
    setSelectedEvent(null);

    const pathname = location.pathname.includes('contacts/update') ? '/influence/directory' : location.pathname;
    let urlToHistory = `${pathname}?${query.toString()}`;
    navigate(urlToHistory, { replace: true });
  };

  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='rounded-pill border flex-centered specific-right-padding-7 specific-left-padding-15 specific-y-padding-6 flex-grow-1 bg-white specific-right-margin-10 search-events-input'>
          <div className='flex-grow-1 d-flex align-items-center'>
            <span className='icon-search mr-2'></span>
            <input
              className='input-empty w-100 paragraph-p1 pr-2'
              placeholder='Search events'
              value={searchValue}
              ref={inputSearch}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  let value = e.target.value;
                  inputSearch.current.blur();
                  searchResults(value);
                } else if (e.metaKey && e.key === 'a') {
                  e.preventDefault();
                  inputSearch.current.select();
                } else if (e.keyCode === 9) {
                  e.preventDefault();
                }
              }}
            />
          </div>
          <div
            className='close-icon-container'
            style={{
              visibility: searchValue !== '' ? 'visible' : 'hidden',
            }}
          >
            <button
              className='general-button close-icon'
              data-tooltip-content='Clear search'
              data-tooltip-id='general-tooltip'
              data-tooltip-place='right'
              onClick={(e) => {
                e.stopPropagation();
                searchResults('');
                setSearchValue('');
              }}
            />
          </div>
        </div>
        <Link
          to={`/settings/alerts?returnUrl`}
          className='specific-x-padding-24 specific-y-padding-6 rounded-pill border bg-main-white general-button hovered-button link-hover reset-link nowrap-item'
          onClick={(e) => {
            e.preventDefault();
            setIsOpenCreateAlertPopup(true);
          }}
        >
          <span
            className=' d-inline-block icon-alert-medium paragraph-p2'
            style={{ marginTop: '-2px', verticalAlign: 'middle', marginRight: '8px' }}
          />
          Create an alert
        </Link>
      </div>
      <CreateAlertPopup isOpen={isOpenCreateAlertPopup} setIsOpen={setIsOpenCreateAlertPopup} />
    </>
  );
};

export default LiveFromParliament;
