import React, { useContext, useRef, useState, useEffect } from 'react';
import { store } from '../../Store.js';
import useRememberPosition from '../hooks/useRememberPosition.js';
import TagsLists from '../TagsList/index.js';
import createMarkup from '../helpers/createMarkup.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Link, useLocation, useParams } from 'react-router-dom';
import checkEllipsis from 'components/Dashboard/utilities/checkEllipsis.js';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile.js';
import ParliamentLiveTextGenerator from 'components/Dashboard/ParliamentLive/ParliamentLiveTextGenerator.js';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter.js';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';
import { normalizedDateUK } from 'components/Dashboard/utilities/normalizedDateUK.js';

dayjs.extend(utc);
dayjs.extend(timezone);

const ParliamentLiveItem = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;

  const { search, contentResults, activeResults, lastSeenMarkers, itemsViewed, sectionsView, realtimeEvents } = state;
  const { activeSearch, list } = search;
  const { selectedOptionParliamentLiveView } = sectionsView;
  const { isAll, item, index, includeTags, scrollBarRef, itemOnclick, array } = props;

  const { title, subject, highlights, body, eventId, createdAtUtc, contentUrl, dateTime } = item ?? {};
  const { ParliamentLive } = contentResults[activeResults];
  const ParliamentLiveSearch = contentResults['searchResults'].ParliamentLive;
  const explanations =
    props.explanations ?? activeSearch
      ? ParliamentLiveSearch !== undefined
        ? ParliamentLiveSearch.explanations
        : []
      : [];
  const feedItemFlags =
    props.feedItemFlags ?? activeSearch
      ? ParliamentLiveSearch !== undefined
        ? ParliamentLiveSearch.feedItemFlags
        : []
      : [];
  const explanationsToRender = explanations ?? ParliamentLiveSearch?.explanations;
  const { rememberPosition } = useRememberPosition();
  const collapsedHitItem = ParliamentLiveSearch?.collapsedHits?.[index];
  const feedItem = feedItemFlags?.[index];
  const isBackpopulatedCondition = feedItem?.isBackpopulated === null || feedItem?.isBackpopulated === true;
  const unseenConditional =
    list !== null &&
    !isAll &&
    includeTags &&
    feedItemFlags?.length > 0 &&
    !!lastSeenMarkers['parliamentLiveFeedItemId'] &&
    lastSeenMarkers['parliamentLiveFeedItemId'] < feedItem?.feedItemId &&
    !itemsViewed.includes(item?.objectID) &&
    !isBackpopulatedCondition;

  const params = useParams();
  const location = useLocation();
  const { event } = params;
  const { inboxSection } = useMainSectionParameter();
  const relevantEventsCondition = selectedOptionParliamentLiveView === 'Relevant events' && !event;
  const isMobile = useIsMobile();

  const [elapsedSeconds, setElapsedSeconds] = useState(null);
  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);
  const eventElement = realtimeEvents?.find((item) => item?.id === eventId);
  const eventLive = !!eventElement && !eventElement?.eventHasEnded;

  const startTimer = (diffInSeconds) => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    setElapsedSeconds(diffInSeconds);

    intervalRef.current = setInterval(() => {
      setElapsedSeconds((prev) => {
        if (prev !== null && prev < 59) {
          return prev + 1;
        } else {
          clearInterval(intervalRef.current);
          return 60;
        }
      });
    }, 1000);

    const remainingTime = (60 - diffInSeconds) * 1000 + 30000;

    timeoutRef.current = setTimeout(() => {
      setElapsedSeconds(null);
    }, remainingTime);
  };

  useEffect(() => {
    const dateParam = getUrlParam('startDateTime');
    const now = dayjs(dateParam ?? new Date()).tz('Europe/London');
    const givenDate = dayjs.tz(dateTime, 'Europe/London');
    let diffInSeconds = now.diff(givenDate, 'second');

    if (diffInSeconds >= 0 && diffInSeconds < 60) {
      startTimer(diffInSeconds);
    }

    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(timeoutRef.current);
    };
  }, [createdAtUtc]);

  const timeAgo = (date) => {
    const dateParam = getUrlParam('startDateTime');
    const now = dayjs(dateParam ?? new Date()).tz('Europe/London');
    const givenDate = dayjs.tz(date, 'Europe/London');
    const diffInSeconds = now.diff(givenDate, 'second');
    const diffInMinutes = now.diff(givenDate, 'minute');
    const diffInHours = now.diff(givenDate, 'hour');
    const diffInDays = now.diff(givenDate, 'day');
    let text = '';

    if (diffInSeconds < 0) {
      const absDiffInSeconds = Math.abs(diffInSeconds);

      if (absDiffInSeconds < 60) {
        text = `Will start in ${absDiffInSeconds} second${absDiffInSeconds !== 1 ? 's' : ''}`;
      } else {
        const absDiffInMinutes = Math.abs(diffInMinutes);

        if (absDiffInMinutes < 60) {
          text = `Will start in ${absDiffInMinutes} minute${absDiffInMinutes !== 1 ? 's' : ''}`;
        } else {
          const absDiffInHours = Math.abs(diffInHours);

          if (absDiffInHours >= 1) {
            text = `Will start in ${absDiffInHours} hour${absDiffInHours !== 1 ? 's' : ''}`;
          } else {
            text = `Will start in less than an hour`;
          }
        }
      }
    } else {
      if (diffInSeconds < 60) {
        text = `${diffInSeconds} second${diffInSeconds !== 1 ? 's' : ''} ago`;
      } else if (diffInMinutes < 60) {
        text = `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
      } else if (diffInHours < 24) {
        text = `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
      } else {
        text = `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
      }
    }

    return { text, diffInSeconds, diffInMinutes: diffInMinutes >= 1 ? diffInMinutes : undefined };
  };

  const remarkInformation = {
    name: elapsedSeconds
      ? `${elapsedSeconds === 60 ? '1 minute ago' : `${elapsedSeconds} second${elapsedSeconds === 1 ? '' : 's'} ago`}`
      : timeAgo(dateTime)?.text,
    parliamentaryLiveColor:
      timeAgo(dateTime)?.diffInMinutes < 60 || timeAgo(dateTime)?.diffInSeconds < 60 ? 'green' : 'normal',
    useClockIcon: true,
  };

  const createSearch = () => {
    const querySearch = new URLSearchParams(location?.search);
    if (!relevantEventsCondition) {
      const urlSearch = new URL(contentUrl);
      const contentSearch = new URLSearchParams(urlSearch?.search);
      const timeParameter = contentSearch.get('in');
      querySearch.set('in', encodeURIComponent(timeParameter));
    }

    return querySearch.toString();
  };

  return (
    <>
      <Link
        to={`/parliament-live/${eventId}?${createSearch()}`}
        state={{ search: location.search }}
        className={`content-item-link parliament-live-item-link`}
        style={{ zIndex: array ? array.length - index : 1 }}
        onClick={(e) => {
          if (scrollBarRef) {
            rememberPosition('ParliamentLive', scrollBarRef, index);
          }
          if (itemOnclick) {
            itemOnclick(e);
          }
        }}
      >
        <li className={`my-2 content-item ${relevantEventsCondition ? `` : 'relevant-remark-item'}`}>
          {unseenConditional && (
            <>
              <span className='unseen-dot' />
            </>
          )}
          <div className='p-3 p-sm-4'>
            {relevantEventsCondition && title && <h4 className={`mb-0 flex-grow-1 content-item-title`}>{title}</h4>}
            <div className='row mx-0 align-items-start'>
              <div className='content-title parliament-live-title w-100 px-0'>
                <div className='title-body-container'>
                  <p className={`title-body mb-0 ${relevantEventsCondition ? 'mt-3' : ''}`}>
                    {relevantEventsCondition && eventLive && ParliamentLive?.fromInterval ? (
                      <ParliamentLiveTextGenerator
                        htmlContent={highlights ? highlights.highlightedBodySnippets[0] : body}
                      />
                    ) : (
                      <span
                        dangerouslySetInnerHTML={createMarkup(
                          highlights ? highlights.highlightedBodySnippets[0] : body
                        )}
                      />
                    )}
                  </p>
                </div>
                <ParliamentHeadingElement item={item} />
                {isMobile && !!subject && (
                  <>
                    <p className={`heading-date specific-top-margin-3 mb-0`}>
                      <span className='parliament-live-item-subject w-100'>{subject}</span>
                    </p>
                  </>
                )}
                <TagsLists
                  feedItemFlags={feedItemFlags}
                  explanations={explanationsToRender}
                  index={index}
                  title={'Parliament Live'}
                  live={true}
                  ended={relevantEventsCondition ? !eventLive : false}
                  remarkInformation={relevantEventsCondition ? null : remarkInformation}
                />
                {collapsedHitItem?.totalHits && relevantEventsCondition && inboxSection && (
                  <p className='mt-2 mb-0 text-light-blue paragraph-p3 d-inline-block'>
                    {collapsedHitItem?.totalHits} relevant remark{collapsedHitItem?.totalHits === 1 ? '' : 's'}
                    {eventLive && <> so far</>}
                    <span className='icon-long-arrow-right paragraph-p6 ml-2 parliamentary-event-icon' />
                  </p>
                )}
                {!relevantEventsCondition && isMobile && (
                  <span className='light-70-text paragraph-p4 specific-top-padding-5 d-inline-block'>
                    <span className='icon-clock specific-right-margin-5 d-inline-block-centered' />
                    {remarkInformation?.name}
                  </span>
                )}
              </div>
            </div>
          </div>
        </li>
      </Link>
    </>
  );
};

const ParliamentHeadingElement = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { sectionsView } = state;
  const { selectedOptionParliamentLiveView } = sectionsView;

  const { item, className = 'heading-date' } = props;
  const { createdAtUtc, title, subject } = item ?? {};
  const params = useParams();
  const isMobile = useIsMobile();
  const { event } = params;

  const relevantEventsCondition = selectedOptionParliamentLiveView === 'Relevant events' || event;
  const subjectElement = useRef();

  return (
    <>
      <p className={`${className} mb-0 pt-2 d-flex align-items-center`}>
        {relevantEventsCondition && (
          <>
            <span className='nowrap-item'>{dayjs(createdAtUtc).format('dddd D MMMM YYYY')}</span>
            <span className='dot mt-0'></span>
          </>
        )}
        {!!title && !relevantEventsCondition && (
          <span className={`nowrap-item legislation-${title?.replaceAll('House of ', '')?.toLowerCase()}`}>
            {title}
          </span>
        )}
        {subject && !isMobile && (
          <>
            {!relevantEventsCondition && <span className='dot mt-0'></span>}
            <span
              ref={subjectElement}
              className={`parliament-live-item-subject`}
              data-tooltip-content={checkEllipsis(subjectElement?.current) ? subject : ''}
              data-tooltip-id={'general-tooltip'}
            >
              {subject}
            </span>
          </>
        )}
        {(!relevantEventsCondition || (relevantEventsCondition && !!subject && !isMobile)) && (
          <span className='dot mt-0'></span>
        )}

        <span className='date nowrap-item'>
          {relevantEventsCondition && !isMobile ? 'Started at ' : ''}
          {normalizedDateUK(createdAtUtc).format('HH:mm').replaceAll(' ', '').toLowerCase()}
        </span>
      </p>
    </>
  );
};

export { ParliamentHeadingElement };
export default ParliamentLiveItem;
