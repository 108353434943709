import axios from 'axios';
import TranscriberDatePicker from 'components/Admin/Transcribers/TranscriberDatePicker';
import useTranscribersCalls from 'components/Admin/Transcribers/useTranscribersCalls';
import ModalComponent from 'components/Common/Modal';
import dayjs from 'dayjs';
import { useState, useEffect, useRef } from 'react';

const AddNewEventForm = (props) => {
  const { eventIdState, openState, createOrUpdateEvent } = props;

  const [originalEventInformation, setOriginalEventInformation] = useState({});
  const [eventInformation, setEventInformation] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const { eventId, setEventId } = eventIdState ?? {};
  const { isOpen, setIsOpen } = openState ?? {};
  const { getEvent } = useTranscribersCalls();

  const getEventInformation = useRef();
  getEventInformation.current = async (source) => {
    try {
      if (!!eventId) {
        const event = await getEvent({ requestSource: source, eventId });
        if (!!event) {
          setEventInformation(event);
          setOriginalEventInformation(event);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {}
  };
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getEventInformation.current(source);
    return () => {
      source.cancel('Get transcriber event loading cancelled by the user');
    };
  }, [eventId]);

  const changeInformation = ({ property, newValue }) => {
    let newEventInformation = { ...eventInformation };
    newEventInformation = {
      ...newEventInformation,
      [property]: newValue,
    };
    setEventInformation(newEventInformation);
  };

  const closeFunction = (value) => {
    setIsOpen(value);
    setTimeout(() => {
      setEventInformation({});
      setEventId(null);
    }, 300);
  };

  const disabledCondition = () => {
    let disabled = true;
    if (
      !!eventInformation?.title &&
      !!eventInformation?.subject &&
      !!eventInformation?.scheduledStartDateTime &&
      !!eventInformation?.scheduledEndDateTime
    ) {
      disabled = false;
    }
    if (!!eventId && JSON.stringify(eventInformation) === JSON.stringify(originalEventInformation)) {
      disabled = true;
    }
    return disabled;
  };

  return (
    <ModalComponent isOpen={isOpen} maxWidth={800} setIsOpen={closeFunction}>
      <h3 className='border-bottom py-3 px-4 title-h4-bold'>{eventId ? 'Edit' : 'Create'} an event</h3>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className='p-4'>
            <div className='row mb-4'>
              <div className='col-lg-8 form-group'>
                <label htmlFor='title' className='font-weight-bold'>
                  Title <span className='text-red'>*</span>
                </label>
                <input
                  id='title'
                  className='main-input form-control'
                  type='text'
                  value={eventInformation?.title ?? ''}
                  onChange={(e) => changeInformation({ property: 'title', newValue: e.target.value })}
                  autoFocus={true}
                />
              </div>
              <div className='col-lg-8 form-group'>
                <label htmlFor='subject' className='font-weight-bold'>
                  Subject <span className='text-red'>*</span>
                </label>
                <input
                  id='subject'
                  className='main-input form-control'
                  type='text'
                  value={eventInformation?.subject ?? ''}
                  onChange={(e) => changeInformation({ property: 'subject', newValue: e.target.value })}
                />
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col-lg-8 form-group'>
                <label htmlFor='witnesses' className='font-weight-bold'>
                  Witnesses
                </label>
                <input
                  id='witnesses'
                  className='main-input form-control'
                  type='text'
                  value={eventInformation?.witnesses ?? ''}
                  onChange={(e) => changeInformation({ property: 'witnesses', newValue: e.target.value })}
                  lang='en-GB'
                />
              </div>
              <div className='col-lg-8 d-flex'>
                <div className='col-8 pl-0'>
                  <label className='font-weight-bold'>
                    Start <span className='text-red'>*</span>
                  </label>
                  <TranscriberDatePicker
                    rightPosition={0}
                    buttonClass='rounded w-100 text-left pl-3'
                    daySelectFunction={({ day, time }) => {
                      changeInformation({
                        property: 'scheduledStartDateTime',
                        newValue: dayjs(`${day}T${time}`)?.format('YYYY-MM-DDTHH:MM'),
                      });
                    }}
                    date={eventInformation?.scheduledStartDateTime}
                    showTime={true}
                  />
                </div>
                <div className='col-8 pr-0'>
                  <label className='font-weight-bold'>
                    End <span className='text-red'>*</span>
                  </label>
                  <TranscriberDatePicker
                    rightPosition={0}
                    buttonClass='rounded w-100 text-left pl-3'
                    daySelectFunction={({ day, time }) => {
                      changeInformation({
                        property: 'scheduledEndDateTime',
                        newValue: dayjs(`${day}T${time}`)?.format('YYYY-MM-DDTHH:MM'),
                      });
                    }}
                    date={eventInformation?.scheduledEndDateTime}
                    showTime={true}
                  />
                </div>
              </div>
            </div>

            <div className='mb-4'>
              <label htmlFor='parliamentEventId' className='font-weight-bold'>
                Parliament Event ID
              </label>
              <input
                id='parliamentEventId'
                className='main-input form-control'
                type='text'
                value={eventInformation?.parliamentEventId ?? ''}
                onChange={(e) => changeInformation({ property: 'parliamentEventId', newValue: e.target.value })}
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='streamUrl' className='font-weight-bold'>
                Stream URL
              </label>
              <input
                id='streamUrl'
                className='main-input form-control'
                type='text'
                value={eventInformation?.streamUrl ?? ''}
                onChange={(e) => changeInformation({ property: 'streamUrl', newValue: e.target.value })}
              />
            </div>
          </div>
          <div className='border-top px-4 py-3 d-flex justify-content-end'>
            <button className='general-button simple-link-button mr-3' onClick={() => closeFunction(false)}>
              Cancel
            </button>
            <button
              className='general-button action-button py-2 px-4'
              disabled={disabledCondition()}
              onClick={async () => {
                setIsLoadingButton(true);
                const updateEvent = await createOrUpdateEvent({ event: eventInformation });
                if (updateEvent) {
                  closeFunction(false);
                }
                setIsLoadingButton(false);
              }}
            >
              {isLoadingButton && <i className='fas fa-spinner fa-spin d-inline-block-centered mr-2' />} Save
            </button>
          </div>
        </>
      )}
    </ModalComponent>
  );
};

export default AddNewEventForm;
