import { useEffect, useRef } from 'react';
import roundToOneHundred from 'components/Dashboard/helpers/roundToOneHundred';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas';
import uppercaseFirstLetter from 'components/Dashboard/utilities/uppercaseFirstLetter';
import {
  switchContributionTypeToLowerCase,
  useAllowRightPanel,
  useChartDataOptionSelected,
} from 'components/Dashboard/Analytics/ui/MentionsByTopic/ChartDataTable';
import { createColour } from 'components/Dashboard/Analytics/ui/SentimentOverTime/SentimentOverTimeDataTable';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import getAppSite from 'utils/getAppSite';

const OverallSentimentChartData = (props) => {
  const {
    data,
    analyticsState,
    chartRef,
    activeSections,
    activeSection,
    setActiveSection,
    disableSelectSection,
    smallChartsKeys,
    rightPanelState,
    setRightPanelState,
    changeOrderTableLabel,
  } = props;
  const { labels, rawData } = data;
  const chartTableContainer = useRef();
  const { restrictPanel } = analyticsState;

  useEffect(() => {
    function handleHoverOutside(event) {
      if (chartTableContainer.current && !chartTableContainer.current.contains(event.target)) {
        if (!disableSelectSection) {
          setActiveSection(null);
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mouseover', handleHoverOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mouseover', handleHoverOutside);
    };
  }, [chartTableContainer, disableSelectSection]);

  const createLabels = () => {
    return chartRef.current ? labels.filter((item) => activeSections.includes(item)) : labels;
  };

  const labelsToRender = createLabels();

  const localHighlightFunction = (index) => {
    if (!disableSelectSection) {
      setActiveSection(index);
    }
  };

  const { chartDataOption } = useChartDataOptionSelected();
  const topicParameter = getUrlParam('topic-id');

  useEffect(() => {
    if (!restrictPanel) {
      setRightPanelState({ open: false });
    }
  }, [chartDataOption, topicParameter, restrictPanel]);

  const overallOption = chartDataOption === 'Overall';
  const totalValue = data?.value.reduce(
    (previousValue, currentValue) => previousValue + (typeof currentValue === 'number' ? currentValue : 0),
    0
  );

  return (
    <>
      <div className='flex-centered justify-content-between pt-5 pb-4 mb-1 sticky-item'>
        <h3 className='title-h5-m-bold mb-0'>Chart data</h3>
      </div>
      <div className='chart-data-container d-flex align-items-start' ref={chartTableContainer}>
        {!overallOption && (
          <div className='overflow-auto chart-data-labels-container'>
            <table className='table chart-data-labels'>
              <thead>
                <tr>
                  <th className='font-weight-bold'>{chartDataOption}</th>
                </tr>
              </thead>
              <tbody>
                {smallChartsKeys.map((item, i) => {
                  return (
                    <tr key={`data-top-${i}`}>
                      <td
                        className={`${i === activeSection ? 'selected-label-total' : ''}`}
                        onMouseOver={() => {
                          localHighlightFunction(i);
                        }}
                      >
                        <span className='hidden-lines hidden-one-line'>{item}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div className={`overflow-auto chart-data-info-container flex-grow-1 ${overallOption ? 'border-left' : ''}`}>
          <table className='table chart-data-info chart-data-info-sentiment-changes'>
            <thead>
              <tr>
                {labelsToRender.map((label) => (
                  <th
                    className={`sort-table-item nowrap-item font-weight-bold pointer`}
                    key={`label-${label}`}
                    style={{ backgroundColor: createColour(label) }}
                    onClick={(e) => {
                      changeOrderTableLabel(label, e);
                    }}
                    data-order={'asc'}
                  >
                    {label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <>
                {overallOption ? (
                  <tr>
                    {labelsToRender.map((label, index) => {
                      let value = data?.value[index];
                      let percentage = (value * 100) / parseInt(totalValue);
                      return (
                        <CellItem
                          key={`date-${label}`}
                          localHighlightFunction={localHighlightFunction}
                          activeSection={activeSection}
                          i={value}
                          value={value ?? 0}
                          totalValue={totalValue}
                          label={label}
                          analyticsState={analyticsState}
                          dataKey={null}
                          percentage={percentage ? Math.round((percentage + Number.EPSILON) * 10) / 10 : 0}
                          rawData={rawData}
                          rightPanelState={rightPanelState}
                          setRightPanelState={setRightPanelState}
                        />
                      );
                    })}
                  </tr>
                ) : (
                  <>
                    {smallChartsKeys.map((item, i) => {
                      let points = rawData[item];
                      let pointsFiltered = Object.keys(points).filter((item) =>
                        activeSections.includes(uppercaseFirstLetter(item))
                      );
                      let totalValue = pointsFiltered.reduce((acc, item) => {
                        acc += points[item];
                        return acc;
                      }, 0);
                      let percentagesFixed = roundToOneHundred(
                        pointsFiltered.map((item) => (points[item] * 100) / totalValue),
                        100
                      );
                      let percentages = {};
                      pointsFiltered.forEach((item, index) => {
                        percentages[item] = percentagesFixed[index];
                      });
                      return (
                        <tr key={`data-${i}`}>
                          {labelsToRender.map((label, index) => {
                            let value = points[label.toLowerCase()];
                            return (
                              <CellItem
                                key={`date-${label}`}
                                localHighlightFunction={localHighlightFunction}
                                activeSection={activeSection}
                                i={i}
                                value={value ?? 0}
                                totalValue={totalValue}
                                label={label}
                                analyticsState={analyticsState}
                                dataKey={item}
                                percentage={percentages[label.toLowerCase()] ?? 0}
                                rawData={rawData}
                                rightPanelState={rightPanelState}
                                setRightPanelState={setRightPanelState}
                              />
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                )}
              </>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const CellItem = (props) => {
  const {
    localHighlightFunction,
    activeSection,
    i,
    value,
    totalValue,
    label,
    analyticsState,
    dataKey,
    percentage,
    setRightPanelState,
    rightPanelState,
    rawData,
  } = props;
  const { chartDataOptionSelected } = analyticsState;
  const { delayShowPanelTitle } = useAllowRightPanel();

  const nextText = (sectionsValues) => {
    if (chartDataOptionSelected === 'Contribution type') {
      return `${switchContributionTypeToLowerCase(dataKey)}${totalValue === 1 ? '' : 's'} (${percentage}%)`;
    } else {
      return `${sectionsValues?.length === 1 ? switchContributionTypeToLowerCase(sectionsValues[0]?.name) : 'contribution'}${totalValue === 1 ? '' : 's'} (${percentage}%) by ${getAppSite() === 'usa' ? 'policymakers' : 'parliamentarians'} ${!!dataKey ? `(${dataKey})` : ''}`;
    }
  };

  const createText = (sectionsValues) => {
    return `${value ? thousandCommas(value) : 0} of ${thousandCommas(totalValue)} ${nextText(sectionsValues)} in the last ${process.env.REACT_APP_SENTIMENT_SNAPSHOT_NUM_DAYS} days were ${labelLowerCase}`;
  };

  const labelLowerCase = label.toLowerCase();
  const activeCell = rightPanelState?.sentiment === label && rightPanelState?.dataKey === dataKey;

  return (
    <td
      className={`${i === activeSection ? 'selected-label-info' : ''}`}
      style={activeCell ? { backgroundColor: '#0094CC' } : null}
      onMouseOver={() => {
        localHighlightFunction(i);
      }}
      onClick={(e) => {
        setRightPanelState({
          open: true,
          date: null,
          sentiment: label,
          noResults: value === 0,
          rawData,
          text: createText,
          dataKey,
        });
        delayShowPanelTitle();
      }}
    >
      <span className={`${!activeCell ? 'cell-element' : ''} ${activeCell ? 'text-white font-weight-bold' : ''}`}>
        {percentage}%
      </span>
    </td>
  );
};

export default OverallSentimentChartData;
