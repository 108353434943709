import React, { useContext } from 'react';
import { store } from 'components/Store';
import { useAuth0 } from '@auth0/auth0-react';
import { createFiltersProperties, useCreateSectionTitle } from 'components/Dashboard/helpers/controlActiveFilters';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import parliamentarianUsers from 'components/Dashboard/SidebarElements/parliamentarianUsers';
import MainSearchBar from 'components/Dashboard/MainSearchBar';
import EmbedWebsiteButton from 'components/Settings/WebsiteFeeds/EmbedWebsiteButton';
import { Link, useLocation } from 'react-router-dom';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import MoreFiltersButton from 'components/Dashboard/Filters/ui/MoreFiltersButton';
import SectionTitle from 'components/Dashboard/components/SectionTitle';

const InboxBar = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { code } = activePlan;
  const filtersProperties = createFiltersProperties();

  const { inboxSection } = useMainSectionParameter();
  const isMobile = useIsMobile();

  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  const createMainSectionName = () => {
    if (location.pathname.includes('stakeholder-mapping')) {
      return parliamentarianUsers(code) ? 'Policymakers' : 'Stakeholder mapping';
    } else {
      if (inboxSection && isAuthenticated) {
        return 'Monitor';
      }
      return 'Research';
    }
  };
  const sectionName = createMainSectionName();
  const { createSectionTitle } = useCreateSectionTitle();
  const { newAgencyPlan } = useTopicOrClientWord();

  return (
    <>
      <div className={`bg-white monitor-items-bar inbox-items-bar`}>
        <div className='mx-auto main-content-wrapper flex-centered justify-content-between resize-elements-container'>
          <div className='side-left-element pl-lg-5 pl-4 d-none d-lg-block'>
            <div className='position-relative mr-3'>
              <p className={`mb-0 title-h4 nowrap-item monitor-dropdown-item`}>{sectionName}</p>
            </div>
          </div>
          <div
            className={`main-content flex-centered position-relative filters-general-container justify-content-${isMobile ? 'end' : 'between'} pr-xl-5 px-md-4 px-3`}
          >
            <div
              className={`single-content-width-monitor flex-centered justify-content-between stakeholder-sub-nav ${newAgencyPlan ? 'single-content-agency' : ''}`}
            >
              {<SectionTitle sectionName={createSectionTitle()} />}
              {!isMobile && (
                <>
                  <MainSearchBar />
                </>
              )}
            </div>

            <div className='d-flex align-items-start subnav-buttons pl-3 pl-xl-2'>
              {isMobile && <MoreFiltersButton section={filtersProperties?.section} />}
              <div className='embed-button-container'>
                <EmbedWebsiteButton />
              </div>
              <Link
                to={`/settings/alerts?returnUrl=${encodeURIComponent(location.pathname)}`}
                className='general-button alerts-button d-none d-lg-block action-button rounded-button nowrap-item'
              >
                <span className=' d-inline-block icon-alert-medium paragraph-p2 d-inline-block-centered centered-2 icon-alert-medium paragraph-p2 specific-right-margin-8' />
                Alerts
              </Link>
            </div>
          </div>
        </div>
      </div>
      {isMobile && (
        <div className='w-100 px-3 bg-main-white border-bottom pb-3'>
          <div className={`w-100 pt-3 d-block`}>
            <MainSearchBar />
          </div>
        </div>
      )}
    </>
  );
};

export default InboxBar;
