const ParliamentLiveFilters = [
  {
    name: 'Location',
    searchState: 'always',
    pages: ['ParliamentLive'],
    filterName: 'category',
    notExpand: true,
    options: [
      {
        name: 'House of Commons',
        filterValue: 'Business in the House of Commons',
      },
      {
        name: 'House of Lords',
        filterValue: 'Business in the House of Lords',
      },
      {
        name: 'Westminster Hall',
        filterValue: 'Business in Westminster Hall',
      },
      {
        name: 'Committees',
        filterValue: [
          'Joint Committee meetings',
          'Second Reading Committee',
          'Grand Committee(s) sitting at Westminster',
          'Business in Grand Committee in the House of Lords',
          'Private Bill Committees (Westminster)',
          'Delegated Legislation Committee (Westminster)',
          'European Committees (Westminster)',
          'Public Bill committee meetings',
          'Select committee reports',
          'Select committee meetings (Lords)',
          'Select committee meetings (Commons)',
          'General committee meetings',
        ],
      },
    ],
  },
];

export default ParliamentLiveFilters;
