import { useContext, useEffect, useRef, useState } from 'react';
import { store } from '../../Store';
import useGeneralRequestProperties from '../hooks/useGeneralRequestProperties';
import Axios from 'axios';
import { createSectionName } from '../helpers/controlActiveFilters';
import getUrlParam from '../utilities/getUrlParam';
import useCreateResults from '../ListControl/useCreateResults';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

const useCallParliamentLive = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contentResults, activeResults, sectionsView, keywordsLists } = state;
  const { selectedOptionParliamentLiveView } = sectionsView ?? {};
  const { parliamentLiveReferenceDates, eventApiCall, condition } = props ?? {};

  const { createRequestProperties } = useGeneralRequestProperties();
  const sectionName = createSectionName();
  const params = useParams();
  const { event } = params ?? {};
  const topicParam = getUrlParam('topic-id');

  const ParliamentLive = contentResults[activeResults]?.ParliamentLive;
  const { createResults } = useCreateResults({});
  const defaultInterval = 30000; //ms

  const minutesPerCallParam = getUrlParam('minutesPerCall');
  const minutesPerCall = minutesPerCallParam ?? defaultInterval / 60000;
  const interval = minutesPerCallParam ? 60000 : defaultInterval;
  const [date, setDate] = useState(null);
  const intervallCallFunction = useRef();
  intervallCallFunction.current = async ({ source }) => {
    try {
      const directTopic = keywordsLists?.find((item) =>
        topicParam === 'all' ? item?.id === null : item?.id === parseInt(topicParam)
      );
      const { initialCallFirstDateValue, initialCallSecondDateValue } =
        parliamentLiveReferenceDates ?? ParliamentLive?.parliamentLiveReferenceDates;
      const { requestProperties } = createRequestProperties({
        pageNumber: 1,
        noUseQuery: false,
        customPageSize: 10000,
        directTopic,
      });
      let newRequestProperties = { ...requestProperties };
      const dateValue =
        selectedOptionParliamentLiveView === 'Relevant remarks' || !!event
          ? date ?? initialCallSecondDateValue
          : initialCallFirstDateValue;

      const secondValue = dayjs(date ?? initialCallSecondDateValue)
        .add(minutesPerCall, 'minute')
        .format('YYYY-MM-DDTHH:mm:ss');

      const newDateFilter = {
        field: 'createdAtUtc',
        value: dateValue,
        operator: 'DATE_GTE',
      };

      let newFilters = [...newRequestProperties.filters];
      newFilters = [
        ...newFilters.filter((item) => item?.field !== 'createdAtUtc'),
        newDateFilter,
        {
          field: 'createdAtUtc',
          value: secondValue,
          operator: 'date_lt',
        },
      ];
      newRequestProperties = { ...newRequestProperties, filters: newFilters };
      if (eventApiCall) {
        await eventApiCall({
          propsRequestProperties: newRequestProperties,
          source,
        });
      } else {
        await createResults({
          pageNumber: 1,
          setMarker: false,
          source,
          notForceLoading: true,
          requestProperties: newRequestProperties,
          fromInterval: true,
        });
      }
      setDate(secondValue);
    } catch (error) {}
  };
  const intervalFunc = useRef(null);
  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    if ((!event && sectionName === 'ParliamentLive' && ParliamentLive?.hits?.length > 0) || (!!event && condition)) {
      intervalFunc.current = setInterval(() => intervallCallFunction.current({ source }), interval);
    }
    return () => {
      if (!!source) {
        source.cancel('New ParliamentLive call canceled by the user.');
      }
      if (!!intervalFunc.current) {
        clearInterval(intervalFunc.current);
      }
      setDate(null);
    };
  }, [sectionName, ParliamentLive?.loaded, selectedOptionParliamentLiveView, condition]);
};

export default useCallParliamentLive;
