const transformToPascalCase = (str) => {
  const words = str.match(/[a-z]+/gi);
  if (!words) return '';
  return words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    })
    .join('');
};
const changeItemNamesPascalCase = (item) => {
  switch (item) {
    case 'Upcoming events':
      return 'Events';
    case 'Lobbying material':
    case 'Campaigns':
      return 'UserContent';
    case 'Parliament live':
      return 'RealtimeUtterances';
    case 'Official records':
      return 'HansardContent';
    case 'Library material':
      return 'CommonsLibraryArticles';
    case 'Early Day Motions':
      return 'Edms';
    case 'Answered written questions':
      return 'AnsweredWrittenQuestion';
    case 'Newly tabled written questions':
      return 'UnansweredWrittenQuestion';
    default:
      return transformToPascalCase(item);
  }
};

export default changeItemNamesPascalCase;
