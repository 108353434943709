import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const EventListSkeleton = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div>
        <ul className='policy-list px-0 items-list'>
          {Array(9)
            .fill()
            .map((item, index) => (
              <SkeletonEventItem key={index} />
            ))}
        </ul>
      </div>
    </SkeletonTheme>
  );
};

const SkeletonEventItem = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <li className={`my-2 events-list-padding`}>
        <div className='row mx-0 pl-0 pr-3 py-3 align-items-center'>
          <div className='content-title pr-lg-5 pr-2 ml-2 py-1'>
            <Skeleton height={17} width={`100%`} />
          </div>
        </div>
      </li>
    </SkeletonTheme>
  );
};

const SelectedEventSkeleton = () => {
  return (
    <div className='rounded bg-white border specific-top-padding-36 px-5 pb-4'>
      <div>
        <h3 className='title-h5 font-weight-bold'>
          <Skeleton height={17} width={`55%`} />
        </h3>
        <div className='d-flex align-items-center mt-4 mb-3'>
          <div className='flex-grow-1'>
            <Skeleton height={17} width={`100%`} />
          </div>
        </div>
        <div>
          {[30, 55, 25, 90, 70, 40, 55, 55, 80, 35, 100, 35, 30, 55, 25, 90, 70, 40, 55, 55, 80, 35].map((item, i) => (
            <div className='mt-2' key={`skeleton-${item}-${i}`}>
              <Skeleton height={17} width={`${item}%`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const LiveFromParliamentSkeleton = ({ title }) => {
  return (
    <>
      <div className={`position-relative flex-grow-1 overscroll-behavior-contain`}>
        <div className={`pl-3 pl-lg-5 free-tools-title`}>
          {title}
          <div className='d-flex align-items-center bg-white rounded p-2'>
            <div className='rounded-pill border flex-centered specific-right-padding-7 specific-left-padding-15 specific-y-padding-6 flex-grow-1 bg-white specific-right-margin-10 search-events-input'>
              <Skeleton height={17} width={`150px`} />
            </div>
            <div className='rounded-pill border flex-centered bg-white specific-right-margin-10 search-events-input px-3 py-2'>
              <Skeleton height={17} width={`95px`} />
            </div>
          </div>
        </div>
        <div className='specific-top-padding-37 parliament-event-list-container'>
          <EventListSkeleton />
        </div>
      </div>
      <SelectedEventSkeleton />
    </>
  );
};

export { SkeletonEventItem, SelectedEventSkeleton, LiveFromParliamentSkeleton };
export default EventListSkeleton;
