import axios from 'axios';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import { formatGeneralDate } from 'components/Dashboard/helpers/formatGeneralDate';
import EventListItem from 'components/Dashboard/LiveFromParliament/EventListItem';
import EventListSkeleton from 'components/Dashboard/LiveFromParliament/EventListSkeleton';
import useEventListFunctions from 'components/Dashboard/LiveFromParliament/useEventListFunctions';
import BigBen from 'components/Dashboard/Sentiment/BigBen';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { normalizedDateUK } from 'components/Dashboard/utilities/normalizedDateUK';
import normalize from 'components/Dashboard/utilities/normalizeString';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

const EventsTypeMapping = {
  'Business in Westminster Hall': 'Westminster Hall',
  'Business in the House of Lords': 'Lords chamber',
  'Business in the House of Commons': 'Commons chamber',
  'Joint Committee meetings': 'Committees',
  'Second Reading Committee': 'Committees',
  'Grand Committee(s) sitting at Westminster': 'Committees',
  'Business in Grand Committee in the House of Lords': 'Committees',
  'Private Bill Committees (Westminster)': 'Committees',
  'Delegated Legislation Committee (Westminster)': 'Committees',
  'European Committees (Westminster)': 'Committees',
  'Public Bill committee meetings': 'Committees',
  'Select committee reports': 'Committees',
  'Select committee meetings (Lords)': 'Committees',
  'Select committee meetings (Commons)': 'Committees',
  'General committee meetings': 'Committees',
};

const EventsListEmpty = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [filterValue, setFilterValue] = useState(normalize('All'));
  const { callEvents } = useEventListFunctions();
  const [heightContainer, containerRef] = useHeightContainer();
  const [date, setDate] = useState(null);

  const scrollToItemRef = useRef(null);
  const scrollBarRef = useRef(null);
  const dateParam = getUrlParam('startDateTime');

  const isMobile = useIsMobile();

  const filterOptions = [
    {
      label: 'All',
      themeColor: '0, 18, 43',
    },
    {
      label: `Commons ${isMobile ? '' : 'chamber'}`,
      themeColor: '0, 96, 48',
      name: 'Commons chamber',
    },
    {
      label: `Lords ${isMobile ? '' : 'chamber'}`,
      themeColor: '163, 0, 53',
      name: 'Lords chamber',
    },
    {
      label: 'Committees',
      themeColor: '0, 94, 165',
    },
    {
      label: 'Westminster Hall',
      themeColor: '117, 40, 100',
    },
  ];

  const sortResults = (a, b) => {
    return new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime();
  };

  const callEventsFunc = useRef();
  callEventsFunc.current = async (source) => {
    try {
      setIsLoading(true);
      const requestPropertiesFirstCall = {
        type: 'Event',
        query: '',
        pageSize: 1,
        filters: [
          {
            field: 'eventStartDate',
            value: dayjs
              .utc(normalizedDateUK(dateParam ?? new Date()))
              .startOf('day')
              .add(1, 'day')
              .format('YYYY-MM-DDTHH:mm:ss'),
            operator: 'DATE_GTE',
          },
        ],
        sort: {
          field: 'eventStartDateTimestamp',
          order: 'ASC',
        },
        pageNumber: 1,
      };
      const resultFirstCall = await callEvents({
        source,
        needsAuthentication: false,
        requestProperties: requestPropertiesFirstCall,
      });
      if (!!resultFirstCall) {
        const firstEvent = resultFirstCall.hits?.[0];
        const startDate = dayjs(firstEvent?.eventStartDate).startOf('day');
        setDate(startDate);
        const requestPropertiesHitsCall = {
          type: 'Event',
          query: '',
          pageSize: 10000,
          filters: [
            {
              field: 'eventStartDate',
              value: startDate.format('YYYY-MM-DDTHH:mm:ss'),
              operator: 'DATE_GTE',
            },
            {
              field: 'eventStartDate',
              value: startDate.add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
              operator: 'DATE_LT',
            },
          ],
          sort: {
            field: 'eventStartDateTimestamp',
            order: 'ASC',
          },
          pageNumber: 1,
        };

        const resultHitsCall = await callEvents({
          source,
          needsAuthentication: false,
          requestProperties: requestPropertiesHitsCall,
        });
        if (!!resultHitsCall?.hits) {
          let finalHits = [...resultHitsCall.hits?.sort(sortResults)];
          if (!!finalHits) {
            setResults(finalHits);
            setIsLoading(false);
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    callEventsFunc.current(source);
    return () => {
      source.cancel('Events cancelled by user');
    };
  }, []);

  useEffect(() => {
    if (scrollBarRef.current && scrollToItemRef.current) {
      scrollBarRef.current.scrollTop(scrollToItemRef.current.offsetTop);
    }
  }, [scrollBarRef.current, scrollToItemRef.current]);

  const resultsToRender = results?.filter(
    (item) =>
      normalize(EventsTypeMapping[item.eventCategoryName]) === filterValue || !filterValue || filterValue === 'all'
  );

  return (
    <div className='rounded bg-white border pt-lg-5 pt-3  px-lg-5 px-3 d-flex overflow-hidden'>
      <div className='flex-grow-1 events-list-empty-content-container pt-lg-3 px-lg-3 position-relative w-100'>
        <div className='mb-5'>
          <h2 className='title-h2 font-weight-bold'>There are no live events in Parliament right now</h2>
          <h4 className='title-h4 light-60-text'>
            Explore upcoming events{date ? `, starting on ${formatGeneralDate(date)} below` : ''}
          </h4>
        </div>

        <div className='d-flex specific-bottom-margin-26 w-100 overflow-auto'>
          {filterOptions.map((item) => {
            const { name, label, themeColor } = item;
            const selected = normalize(name ?? label) === filterValue;
            return (
              <button
                className={`general-btn py-2 px-md-3 px-2 rounded mr-md-3 mr-2 ${selected ? 'text-white' : ''} nowrap-item`}
                onClick={() => {
                  setFilterValue(normalize(name ?? label));
                }}
                style={{
                  backgroundColor: `rgba(${themeColor},${selected ? '1' : '0.02'})`,
                  color: `rgba(${selected ? '255,255,255' : themeColor},${selected ? '1' : '0.8'})`,
                  border: `1px solid ${selected ? 'transparent' : `rgba(${themeColor},0.5)`}`,
                }}
                key={`filter-${normalize(name ?? label)}`}
              >
                {label}
              </button>
            );
          })}
        </div>
        <div className='parliament-event-list-container pl-0 pr-lg-5'>
          {isLoading ? (
            <EventListSkeleton />
          ) : (
            <>
              <div ref={containerRef}>
                <CustomScrollbar
                  className={`main-content-scrollbar`}
                  style={{ height: `${heightContainer}px` }}
                  maximalThumbYSize={100}
                  ref={scrollBarRef}
                  contentClass={'ScrollbarsCustom-Content overscroll-behavior-contain'}
                >
                  <ul className='policy-list px-0 items-list pb-2'>
                    {resultsToRender?.length > 0 ? (
                      resultsToRender?.map((item, index, array) => {
                        if (item.scrollToItem) {
                          return <div key={`item-scrollToItemRef`} ref={scrollToItemRef}></div>;
                        } else {
                          return (
                            <EventListItem
                              key={`item-parliamentary-item-${index}`}
                              item={item}
                              array={array}
                              index={index}
                              listView={true}
                              emptyScreen={true}
                            />
                          );
                        }
                      })
                    ) : (
                      <h4
                        className={`mb-0 flex-grow-1 content-item-title font-weight-normal title-h5 specific-top-padding-23 specific-bottom-padding-22`}
                      >
                        No upcoming events found. Explore other locations using the tabs above
                      </h4>
                    )}
                  </ul>
                </CustomScrollbar>
              </div>
            </>
          )}
        </div>
      </div>
      <div className='mx-auto mt-4 position-relative event-list-empty-big-ben d-none d-lg-block'>
        <BigBen />
      </div>
    </div>
  );
};

export default EventsListEmpty;
