import { useEffect, useState, useRef } from 'react';

const useTitleScrolledStyles = (contentContainer, titleSection) => {
  const [showFixedTitle, setShowFixedTitle] = useState(false);
  let observer = useRef();
  let scrollFunction = useRef();
  scrollFunction.current = () => {
    if (!!window.IntersectionObserver) {
      observer.current = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (titleSection.current) {
              setShowFixedTitle(!entry.isIntersecting);
            }
          });
        },
        { rootMargin: '0px 0px 0px 0px' }
      );
      observer.current.observe(titleSection.current);
    }
  };
  const createStyles = () => {
    if (!!contentContainer.current) {
      const { width, left, top } = contentContainer.current.getBoundingClientRect();
      let leftValue = 24;
      let borderValue = 1;
      return {
        position: 'fixed',
        top: top,
        left: left + leftValue + borderValue,
        width: width - borderValue * 2,
      };
    }
  };
  const [titleStyles, setTitleStyles] = useState(createStyles());
  const updateTitleStyles = () => {
    setTitleStyles(createStyles());
  };
  useEffect(() => {
    if (titleSection?.current) {
      setTitleStyles(createStyles());
      scrollFunction.current();
      window.addEventListener('resize', updateTitleStyles);
      return () => {
        window.removeEventListener('resize', updateTitleStyles);
        if (!!observer.current && titleSection.current !== null) {
          observer.current.unobserve(titleSection.current);
        }
      };
    }
  }, []);
  return { showFixedTitle, titleStyles };
};

export default useTitleScrolledStyles;
