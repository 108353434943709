import React, { useEffect, useState } from 'react';

let timer;
export default function TextGenerate({ onCharacterAdd = () => {}, children, onFinish = () => {} }) {
  const [currentText, setCurrentText] = useState('');
  const [started, setStarted] = useState(false);

  const handleGenerate = () => {
    if (started) {
      return;
    }
    setStarted(true);
    let i = -1;
    timer = setInterval(() => {
      i++;
      if (children[i]) {
        setCurrentText((prev) => prev + children[i]);
      }
      onCharacterAdd(i);
      if (i === children.length - 1) {
        clearInterval(timer);
        setTimeout(() => {
          onFinish();
        }, 200);
      }
    }, 20);
  };

  useEffect(() => {
    handleGenerate();
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <>{currentText}</>;
}
