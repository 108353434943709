import React, { useContext, useRef } from 'react';
import preventLink from './prevenLink.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { store } from 'components/Store.js';
import { formatEmbargoedDate } from 'components/Dashboard/helpers/formatGeneralDate.js';

const MatchingListItem = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { keywordsLists, search } = state;
  const { list } = search ?? {};

  const { item, calendarTags } = props;
  const { name, id, embargoed, embargoedUntil, teamName, topics, live, useClockIcon, parliamentaryLiveColor, ended } =
    item;
  const normalTopic = !live;

  const navigate = useNavigate();
  const location = useLocation();
  const timeoutRef = useRef(null);
  const element = useRef();

  const tagSelected = item?.id === list?.id;
  const createIconClass = () => {
    if (useClockIcon) {
      return 'clock';
    } else {
      return 'topics';
    }
  };

  return embargoed ? (
    <p className='position-relative explanation-container under-embargo-list-item'>
      <span className='explanation-tag tag-keyword-list'>
        <span className='icon-clock'></span>
        Embargoed {!!embargoedUntil ? `until ${formatEmbargoedDate(embargoedUntil)}` : ''}
      </span>
    </p>
  ) : (
    <>
      <p className='position-relative explanation-container' ref={element}>
        <span
          className={`explanation-tag tag-keyword-list ${tagSelected && normalTopic ? 'tag-keyword-list-current-topic' : ''} ${live ? `tag-keyword-list-live ${parliamentaryLiveColor ?? ''}` : ''} ${ended ? 'ended' : ''}`}
          onClick={(e) => {
            if (!calendarTags && !tagSelected && normalTopic) {
              preventLink(e);
              let queryParams = new URLSearchParams(location.search);
              queryParams.set('topic-id', id);
              let list = keywordsLists.find((item) => item.id === id);
              dispatch({
                type: 'MODIFY_SECTION',
                parameter: 'search',
                value: {
                  ...search,
                  list,
                },
              });
              navigate({ search: queryParams.toString() });
            }
          }}
          onMouseOver={() => {
            if (normalTopic) {
              timeoutRef.current = setTimeout(() => {
                const parent = element?.current?.closest('.parliament-live-item-link');
                if (parent) {
                  parent.classList?.add('parliament-live-item-link-hover');
                }
              }, 300);
            }
          }}
          onMouseLeave={() => {
            if (timeoutRef.current) {
              const parent = element?.current?.closest('.parliament-live-item-link');
              if (parent) {
                parent.classList?.remove('parliament-live-item-link-hover');
              }
              clearTimeout(timeoutRef.current);
            }
          }}
          data-tooltip-content={
            live
              ? ''
              : `${calendarTags || tagSelected ? '' : 'Click to see all updates for '}${!tagSelected || calendarTags ? `${topics ? `${topics?.join(', ')}` : name}` : ''}`
          }
          data-tooltip-id={'tags-tooltip'}
        >
          {}
          <span className={`${live && !useClockIcon ? 'element-record' : `icon-${createIconClass()}`}`}></span>
          <span className='explanation-tag-list-name'>{teamName ?? name}</span>
        </span>
      </p>
    </>
  );
};

export default MatchingListItem;
