import { Link } from 'react-router-dom';
import ModalComponent from 'components/Common/Modal';
import { useAuth0 } from '@auth0/auth0-react';
import useTopicOrClientWord from 'components/Dashboard/hooks/useTopicOrClientWord';
import { useContext, useEffect, useRef, useState } from 'react';
import { store } from 'components/Store';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import useReportIconAutoselect from 'components/Reports/useReportIconAutoselect';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart';
import returnCorrectWPUrl from 'components/Dashboard/utilities/returnCorrectWPUrl';
import { comprehensiveText } from 'utils/getAppSite';

const AnalyticsPopup = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { analyticsState } = state;
  const { popupTitle, sideBarText, useEmoji } = analyticsState ?? {};
  const { isOpen, setIsOpen } = props;
  const { isAuthenticated } = useAuth0();
  const { callLoggedOutIconSearch } = useReportIconAutoselect();

  const [icon, setIcon] = useState();
  const callUseEmoji = useRef();
  callUseEmoji.current = async () => {
    let value = await callLoggedOutIconSearch({ value: getUrlParam('search') });
    if (value) {
      setIcon(value);
    }
  };
  useEffect(() => {
    if (useEmoji) {
      callUseEmoji.current();
    }
  }, [useEmoji]);

  useEffect(() => {
    init({ data });
  }, []);

  return (
    <ModalComponent
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      maxWidth={1000}
      overlayClassName='react-modal-overlay report-extra-index-modal'
      onAfterClose={() => {
        setIcon(null);
        let newState = {
          ...analyticsState,
          popupTitle: null,
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'analyticsState',
          value: newState,
        });
      }}
    >
      <div className='bg-white rounded-lg relative'>
        <div className='d-lg-flex'>
          <div className='bg-main-blue py-5 text-left d-none d-lg-block rounded-left-lg'>
            <h3 className='title-h3 pl-lg-5 report-prospecting-title'>
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
                alt='Logo'
                className='policy-logo mr-2'
              />
              {isAuthenticated ? 'Mogul plan' : ''}
            </h3>

            <ul className='policy-list text-white text-left title-h5-m py-4 mt-4 statistics-popup-list px-3 pl-lg-5 pr-lg-0'>
              <li className='pl-4 mb-3 py-2 nowrap-item bg-white text-main-blue statistics-list-active-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {sideBarText ?? 'Analytics'}
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {isAuthenticated ? 'Reports' : 'Tailored political monitoring'}
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {isAuthenticated ? 'Dynamic groups' : 'Stakeholder mapping'}
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {isAuthenticated ? 'Dedicated account manager' : 'Full policymaker CRM'}
              </li>
              {!isAuthenticated && (
                <li className='pl-4 mb-3 py-2 nowrap-item '>
                  <span className='inline-block title-h3 mr-3 icon-small-check' />
                  Lobbying platform
                </li>
              )}
            </ul>
          </div>
          <div className='grow px-5 pb-5 mb-3'>
            <p className='title-h2 mt-3 pt-3 pt-lg-5 mb-4 flex-centered'>
              <span className='font-weight-bold'>{popupTitle ?? 'Political data that’s off the charts'}</span>
              {!!icon && (
                <span className='report-list-icon text-center d-inline-block-centered ml-2'>
                  <em-emoji set={'apple'} id={icon} size={'1.3em'} style={{ lineHeight: 1 }} />
                </span>
              )}
            </p>

            <div className='title-h4 main-subtle-text'>
              <p>
                Our analytics platform gives you instant access to the data that will transform your public affairs and
                campaigning work.
              </p>
              <p>
                {isAuthenticated ? (
                  <>
                    You’ll be able to automatically identify trends for any topic, including sentiment analysis, based
                    on political party, region, parliamentary position and more.
                  </>
                ) : (
                  <>
                    Registered users get more charts, unlimited searches, advanced rules, embed options, and detailed
                    insights into specific contributions.
                  </>
                )}
              </p>
            </div>

            <div className='nowrap-item text-lg-right pt-5 mt-lg-5 d-lg-flex align-items-center justify-content-end'>
              <a
                href={`${returnCorrectWPUrl()}/demo`}
                target='_blank'
                rel='noreferrer'
                className='downgrade-pricing-button btn btn-general outline-active-button rounded-button px-3 px-xxl-5 py-2 mr-0 mr-lg-3 title-h5-m'
              >
                Arrange demo
              </a>

              <Link
                className='downgrade-pricing-button mt-3 mt-lg-0 action-button rounded-button py-2 px-3 px-xxl-5 d-inline-block title-h5-m text-center'
                to={`/${isAuthenticated ? 'pricing' : 'register?reg_source=data-hub-page'}`}
              >
                <span className='px-4'>
                  {isAuthenticated ? 'Compare plans' : 'Try PolicyMogul for free'}
                  <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2 ml-lg-3' />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

const ReportsPopup = (props) => {
  const { isOpen, setIsOpen } = props;
  const { isAuthenticated } = useAuth0();
  const { agencyUser } = useTopicOrClientWord();

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} maxWidth={980}>
      <div className='bg-white rounded-lg relative'>
        <div className='d-lg-flex'>
          <div className='bg-main-blue py-5 text-left rounded-left'>
            <h3 className='title-h3 pl-lg-5 report-prospecting-title'>
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
                alt='Logo'
                className='policy-logo mr-2'
              />
              {isAuthenticated ? 'Mogul plan' : ''}
            </h3>

            <ul className='policy-list text-white text-left title-h5-m py-4 mt-4 statistics-popup-list px-3 pl-lg-5 pr-lg-0'>
              <li className='pl-4 mb-3 py-2 nowrap-item bg-white text-main-blue statistics-list-active-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Reports
              </li>
              {!isAuthenticated && (
                <li className='pl-4 mb-3 py-2 nowrap-item '>
                  <span className='inline-block title-h3 mr-3 icon-small-check' />
                  Tailored political monitoring
                </li>
              )}
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {isAuthenticated ? 'Analytics' : 'Analytics on any policy area'}
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {isAuthenticated ? 'Dynamic groups' : 'Advanced stakeholder mapping'}
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {isAuthenticated ? 'Dedicated account manager' : 'Policymaker CRM'}
              </li>
            </ul>
          </div>
          <div className='grow px-5 pb-5 mb-3'>
            <p className='title-h2-bold mt-3 pt-3 pt-lg-5 mb-4'>
              {isAuthenticated
                ? `Reports are available on the ${agencyUser ? 'Enterprise' : 'Mogul'} plan`
                : `You’ll need an account to create reports`}
            </p>

            <div className='title-h4 main-subtle-text'>
              <p>
                PolicyMogul’s report-builder allows you to quickly and easily create PDF reports in relation to any
                issue or political update.
              </p>

              <p>
                Create reports from scratch, or use one of our templates to showcase the most relevant political
                updates, stakeholders and analysis for any issue.
              </p>
            </div>

            <div className='nowrap-item text-lg-right pt-5 mt-5 d-lg-flex align-items-center'>
              <a
                href={`${returnCorrectWPUrl()}/demo`}
                target='_blank'
                rel='noreferrer'
                className='downgrade-pricing-button btn btn-general outline-active-button rounded-button px-4 px-xxl-5 py-2 mr-0 mr-lg-3 title-h5-m'
              >
                Arrange demo
              </a>

              <Link
                className='downgrade-pricing-button action-button rounded-button py-2 px-3 px-xxl-5 d-inline-block title-h5-m'
                to={isAuthenticated ? '/pricing' : '/register?utm_source=report-from-update-button'}
              >
                <span className='px-4'>
                  {isAuthenticated ? 'Compare plans' : 'Start your free trial'}
                  <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2 ml-lg-3' />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

const DataPopup = (props) => {
  const { isOpen, setIsOpen } = props;
  const { isAuthenticated } = useAuth0();

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} maxWidth={1150}>
      <div className='bg-white rounded-lg relative'>
        <div className='d-lg-flex'>
          <div className='bg-main-blue py-5 text-left rounded-left'>
            <h3 className='title-h3 pl-lg-5 report-prospecting-title'>
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
                alt='Logo'
                className='policy-logo mr-2'
              />
            </h3>
            <ul className='policy-list text-white text-left title-h5-m py-4 mt-4 statistics-popup-list px-3 pl-lg-5 pr-lg-0'>
              <li className='pl-4 mb-3 py-2 nowrap-item bg-white text-main-blue statistics-list-active-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Real-time political data
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Tailored political monitoring
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Stakeholder mapping
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Full policymaker CRM
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Lobbying platform
              </li>
            </ul>
          </div>
          <div className='grow px-5 pb-5 mb-3'>
            <p className='title-h2-bold mt-3 pt-3 pt-lg-5 pb-4 mb-4 text-center'>
              PolicyMogul is an all-in-one public affairs platform powered by {comprehensiveText()} political monitoring
              service
            </p>

            <img
              className='mx-auto d-block mb-4'
              src={`${process.env.REACT_APP_CDNURL}/images/20230404-data-screenshot.png`}
              alt={'data screenshot'}
            />

            <div className='nowrap-item text-lg-right pt-5 d-lg-flex align-items-center justify-content-end'>
              <Link
                className='downgrade-pricing-button action-button rounded-button py-2 px-3 px-xxl-5 d-inline-block title-h5-m'
                to={isAuthenticated ? '/pricing' : '/register?utm_source=sentiment-page-try-policymogul-button'}
              >
                <span className='px-4'>
                  {isAuthenticated ? 'Compare plans' : 'Start your free trial'}
                  <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2 ml-lg-3' />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

const AIAssistantPopup = (props) => {
  const { isOpen, setIsOpen } = props;
  const { isAuthenticated } = useAuth0();

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} maxWidth={980}>
      <div className='bg-white rounded-lg relative'>
        <div className='d-lg-flex'>
          <div className='bg-main-blue py-5 text-left rounded-left'>
            <h3 className='title-h3 pl-lg-5 report-prospecting-title'>
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
                alt='Logo'
                className='policy-logo mr-2'
              />
              {isAuthenticated ? 'Mogul plan' : ''}
            </h3>

            <ul className='policy-list text-white text-left title-h5-m py-4 mt-4 statistics-popup-list px-3 pl-lg-5 pr-lg-0'>
              <li className='pl-4 mb-3 py-2 nowrap-item bg-white text-main-blue statistics-list-active-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                AI assistant
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {'Analytics on any policy area'}
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {'Advanced stakeholder mapping'}
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {'Policymaker CRM'}
              </li>
            </ul>
          </div>
          <div className='grow px-5 pb-5 mb-3'>
            <p className='title-h2-bold mt-3 pt-3 pt-lg-5 mb-4'>{`You’ll need an account to use the AI assistant`}</p>

            <div className='title-h4 main-subtle-text'>
              <p>
                PolicyMogul’s AI assistant allows you to quickly and easily get answers to any question in relation to a
                political update.
              </p>
              <p>
                Ask questions about the nature of the update itself or its relevance to your work, and uncover
                actionable insights to inform your public affairs strategy and decision-making.
              </p>
            </div>

            <div className='nowrap-item text-lg-right pt-5 mt-5 d-lg-flex align-items-center'>
              <a
                href='https://calendar.app.google/XfyRwDQ1m1BjLKB47'
                target='_blank'
                rel='noreferrer'
                className='downgrade-pricing-button btn btn-general outline-active-button rounded-button px-4 px-xxl-5 py-2 mr-0 mr-lg-3 title-h5-m'
              >
                Arrange demo
              </a>

              <Link
                className='downgrade-pricing-button action-button rounded-button py-2 px-3 px-xxl-5 d-inline-block title-h5-m'
                to={'/register?utm_source=ai-assistant-widget'}
              >
                <span className='px-4'>
                  {isAuthenticated ? 'Compare plans' : 'Start your free trial'}
                  <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2 ml-lg-3' />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

const CreateAlertPopup = (props) => {
  const { isOpen, setIsOpen } = props;
  const { isAuthenticated } = useAuth0();

  return (
    <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} maxWidth={980}>
      <div className='bg-white rounded-lg relative'>
        <div className='d-lg-flex'>
          <div className='bg-main-blue py-5 text-left rounded-left'>
            <h3 className='title-h3 pl-lg-5 report-prospecting-title'>
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
                alt='Logo'
                className='policy-logo mr-2'
              />
              {isAuthenticated ? 'Mogul plan' : ''}
            </h3>

            <ul className='policy-list text-white text-left title-h5-m py-4 mt-4 statistics-popup-list px-3 pl-lg-5 pr-lg-0'>
              <li className='pl-4 mb-3 py-2 nowrap-item bg-white text-main-blue statistics-list-active-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Real-time alerts
              </li>
              {!isAuthenticated && (
                <li className='pl-4 mb-3 py-2 nowrap-item '>
                  <span className='inline-block title-h3 mr-3 icon-small-check' />
                  Tailored political monitoring
                </li>
              )}
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {isAuthenticated ? 'Analytics' : 'Analytics on any policy area'}
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {isAuthenticated ? 'Dynamic groups' : 'Advanced stakeholder mapping'}
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {isAuthenticated ? 'Dedicated account manager' : 'Policymaker CRM'}
              </li>
            </ul>
          </div>
          <div className='grow px-5 pb-5 mb-3'>
            <p className='title-h2-bold mt-3 pt-3 pt-lg-5 mb-4'>Get live updates from Parliament</p>

            <div className='title-h4 main-subtle-text'>
              <p>
                PolicyMogul's real-time alerts make sure you're the first to know when your organisation or interests
                are mentioned in Parliament.
              </p>

              <p>
                Alerts are bundled every 15 minutes, ensuring you stay informed without being overwhelmed, and
                transcripts can be followed in real-time directly from our platform.
              </p>
            </div>

            <div className='nowrap-item text-lg-right pt-5 mt-5 d-lg-flex align-items-center'>
              <a
                href={`${returnCorrectWPUrl()}/demo`}
                target='_blank'
                rel='noreferrer'
                className='downgrade-pricing-button btn btn-general outline-active-button rounded-button px-4 px-xxl-5 py-2 mr-0 mr-lg-3 title-h5-m'
              >
                Arrange demo
              </a>

              <Link
                className='downgrade-pricing-button action-button rounded-button py-2 px-3 px-xxl-5 d-inline-block title-h5-m'
                to={isAuthenticated ? '/pricing' : '/register?utm_source=report-from-update-button'}
              >
                <span className='px-4'>
                  {isAuthenticated ? 'Compare plans' : 'Start your free trial'}
                  <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2 ml-lg-3' />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

const SearchEventPopup = (props) => {
  const { isAuthenticated } = useAuth0();

  return (
    <ModalComponent maxWidth={980} {...props}>
      <div className='bg-white rounded-lg relative'>
        <div className='d-lg-flex'>
          <div className='bg-main-blue py-5 text-left rounded-left'>
            <h3 className='title-h3 pl-lg-5 report-prospecting-title'>
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
                alt='Logo'
                className='policy-logo mr-2'
              />
              {isAuthenticated ? 'Mogul plan' : ''}
            </h3>

            <ul className='policy-list text-white text-left title-h5-m py-4 mt-4 statistics-popup-list px-3 pl-lg-5 pr-lg-0'>
              <li className='pl-4 mb-3 py-2 nowrap-item bg-white text-main-blue statistics-list-active-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                See all relevant mentions
              </li>
              {!isAuthenticated && (
                <li className='pl-4 mb-3 py-2 nowrap-item '>
                  <span className='inline-block title-h3 mr-3 icon-small-check' />
                  Tailored political monitoring
                </li>
              )}
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {isAuthenticated ? 'Analytics' : 'Analytics on any policy area'}
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {isAuthenticated ? 'Dynamic groups' : 'Advanced stakeholder mapping'}
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                {isAuthenticated ? 'Dedicated account manager' : 'Policymaker CRM'}
              </li>
            </ul>
          </div>
          <div className='grow px-5 pb-5 mb-3'>
            <p className='title-h2-bold mt-3 pt-3 pt-lg-5 mb-4'>Get tailored updates from Parliament</p>

            <div className='title-h4 main-subtle-text'>
              <p>
                PolicyMogul's real-time alerts make sure you're the first to know when your organisation or interests
                are mentioned in Parliament.
              </p>

              <p>
                Alerts are bundled every 15 minutes, ensuring you stay informed without being overwhelmed, and
                transcripts can be followed in real-time directly from our platform.
              </p>
            </div>

            <div className='nowrap-item text-lg-right pt-5 mt-5 d-lg-flex align-items-center'>
              <a
                href={`${returnCorrectWPUrl()}/demo`}
                target='_blank'
                rel='noreferrer'
                className='downgrade-pricing-button btn btn-general outline-active-button rounded-button px-4 px-xxl-5 py-2 mr-0 mr-lg-3 title-h5-m'
              >
                Arrange demo
              </a>

              <Link
                className='downgrade-pricing-button action-button rounded-button py-2 px-3 px-xxl-5 d-inline-block title-h5-m'
                to={isAuthenticated ? '/pricing' : '/register?utm_source=report-from-update-button'}
              >
                <span className='px-4'>
                  {isAuthenticated ? 'Compare plans' : 'Start your free trial'}
                  <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2 ml-lg-3' />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export { AnalyticsPopup, ReportsPopup, DataPopup, AIAssistantPopup, CreateAlertPopup, SearchEventPopup };
