import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement';
import { CreateAlertPopup } from 'components/Dashboard/Navigation/NotFeaturePopups';
import { useAuth0 } from '@auth0/auth0-react';
import { useCollapse } from 'react-collapsed';
import SelectedEvent from 'components/Dashboard/LiveFromParliament/SelectedEvent';

dayjs.extend(utc);
dayjs.extend(timezone);

const EventListItem = (props) => {
  const {
    item,
    selectedEvent,
    setSelectedEvent = () => {},
    isExpanded: isExpandedProp = false,
    setExpandedItem = () => {},
    emptyScreen,
    events,
  } = props;
  const { title, eventId, eventStartDateTime, dateTime } = item ?? {};

  const eventItem = events?.find((item) => item?.id === eventId);
  const { transcriberRunning, actualStartDateTime, actualEndDateTime } = eventItem ?? {};

  const [isExpanded, setOpen] = useState(isExpandedProp);
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
  });
  const { isAuthenticated } = useAuth0();
  const isMobile = useIsMobile();
  const isSmallMobile = useIsMobile(640);
  const isSelected = selectedEvent?.id === eventId;

  useEffect(() => {
    if (!isExpanded && isSelected) {
      setTimeout(() => {
        setOpen(eventId);
      }, 100);
    }
  }, [isSelected, isExpanded]);

  const listItemProps = isMobile
    ? getToggleProps({
        onClick: () => {
          setOpen(!isExpanded);
          setExpandedItem(isExpanded ? null : eventId);
          setSelectedEvent(isExpanded ? null : eventItem);
        },
      })
    : {
        onClick: () => {
          setSelectedEvent(eventItem);
        },
      };

  return (
    <>
      <li
        className={`my-2 cursor pointer p-0 ${isSelected ? 'bg-light-blue-important' : ''} event-list-item`}
        {...listItemProps}
      >
        <div className={`pr-3 d-flex gap-15 ${emptyScreen ? 'pl-3' : ''}`}>
          {!emptyScreen && (
            <div
              className='border-right d-flex align-items-center justify-content-center'
              style={{ width: isSmallMobile ? '90px' : '115px', minWidth: isSmallMobile ? '90px' : '115px' }}
            >
              {transcriberRunning ? (
                <span className='py-1 px-3 background-medium-sea-green text-white rounded event-item-live-badge'>
                  <span className='mr-1 paragraph-p1 title-h3 d-inline-block' style={{ transform: 'translateX(-4px)' }}>
                    •
                  </span>
                  Live
                </span>
              ) : actualStartDateTime ? (
                <>
                  {actualEndDateTime ? (
                    <span className='py-1 px-3 danger-background text-white rounded event-item-live-badge'>Ended</span>
                  ) : (
                    <span className={`paragraph-p1 title-h3 ${isSelected ? 'text-white' : ''}`}>
                      {dayjs(actualStartDateTime).format('H:mm a')}
                    </span>
                  )}
                </>
              ) : (
                <span className={`paragraph-p1 title-h3 ${isSelected ? 'text-white' : ''}`}>
                  {dayjs(dateTime ?? eventStartDateTime).format('H:mm a')}
                </span>
              )}
            </div>
          )}
          <div className='d-flex align-items-center justify-content-between w-100'>
            <h4
              className={`mb-0 flex-grow-1 content-item-title font-weight-normal title-h5 specific-top-padding-23 specific-bottom-padding-22 ${isSelected ? 'text-white' : ''}`}
            >
              {title}
            </h4>
            {!isAuthenticated && <AlertActionBox isSelected={isSelected} />}
          </div>
        </div>
      </li>
      {isMobile && isSelected && (
        <div {...getCollapseProps()}>
          <div className='flex-grow-1 overscroll-behavior-contain'>
            <SelectedEvent selectedEvent={selectedEvent} />
          </div>
        </div>
      )}
    </>
  );
};

const AlertActionBox = ({ isSelected }) => {
  const [showActions, setShowActions] = useState(false);
  const [isOpenCreateAlertPopup, setIsOpenCreateAlertPopup] = useState(false);

  const actionsElement = useRef(null);
  const hideActions = () => {
    setShowActions(false);
  };
  useOutsideElement(actionsElement, hideActions);

  return (
    <>
      <div className='position-relative pl-3' ref={actionsElement}>
        <button
          className={`general-button circle-button hover-background-gray-05 p-1`}
          onClick={(e) => {
            e.stopPropagation();
            setShowActions(!showActions);
          }}
        >
          <span className={`icon-three-dots ${isSelected ? 'text-white' : 'light-40-text'}`} />
        </button>
        {showActions && (
          <div
            className='box box-shadow py-1 w-auto position-absolute right-0 position-relative'
            style={{ left: '-113px' }}
          >
            <div className='share-container position-relative'>
              <div className='share-container-items flex-column'>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowActions(false);
                    setIsOpenCreateAlertPopup(true);
                  }}
                  className='px-3 py-1 bg-main-white general-button hovered-button link-hover reset-link nowrap-item'
                >
                  <span className=' d-inline-block icon-alert-medium paragraph-p2 d-inline-block-centered centered-2 icon-alert-medium paragraph-p2 specific-right-margin-8' />
                  Create an alert
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <CreateAlertPopup isOpen={isOpenCreateAlertPopup} setIsOpen={setIsOpenCreateAlertPopup} />
    </>
  );
};

export default EventListItem;
