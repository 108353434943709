import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { normalizedDateUK } from 'components/Dashboard/utilities/normalizedDateUK';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
dayjs.extend(isBetween);
dayjs.extend(utc);

const useCreateParliamentLiveFilters = () => {
  const dateParam = getUrlParam('startDateTime');
  const dayJsDateParam = dateParam
    ? dayjs(dateParam).hour() === 0 && dayjs(dateParam).minute() === 0
      ? normalizedDateUK(dateParam).endOf('day')
      : normalizedDateUK(dateParam)
    : null;

  const currentUKDateTime = ({ specificDate }) =>
    specificDate && !dateParam ? dayjs(specificDate) : dateParam ? dayJsDateParam : normalizedDateUK(new Date());

  const createUKDate = ({ specificDate }) => {
    let currentUKDate = currentUKDateTime({ specificDate });
    const start = currentUKDate.startOf('day');
    const end = start.add(3, 'hour');
    if (currentUKDate.isBetween(start, end, null, '[]')) {
      currentUKDate = start.subtract(1, 'day');
    }
    return currentUKDate;
  };

  const createParliamentLiveFilters = ({ specificDate }) => {
    const UKDateTime = currentUKDateTime({ specificDate });
    const currentUKDate = createUKDate({ specificDate });
    const initialCallFirstDateValue = dayjs.utc(currentUKDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    const initialCallSecondDateValue =
      specificDate && !dateParam
        ? dayjs(specificDate).endOf('day')?.format('YYYY-MM-DDTHH:mm:ss')
        : dayjs.utc(UKDateTime).format('YYYY-MM-DDTHH:mm:ss');

    const parliamentLiveFilters = [
      {
        field: 'createdAtUtc',
        value: initialCallFirstDateValue,
        operator: 'DATE_GTE',
      },
      {
        field: 'createdAtUtc',
        value: initialCallSecondDateValue,
        operator: 'date_lt',
      },
      {
        field: 'eventStartDateTime',
        value: initialCallFirstDateValue,
        operator: 'DATE_GTE',
      },
    ];

    return { parliamentLiveFilters, initialCallFirstDateValue, initialCallSecondDateValue };
  };

  return { createParliamentLiveFilters, createUKDate, dayJsDateParam, currentUKDateTime };
};

export default useCreateParliamentLiveFilters;
