import React, { useState, useEffect, useContext, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { store } from 'components/Store.js';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { monitoringData, supportData, influenceData, researchData, reportsData, embeddablesData } from './PricingData';
import { Element, scroller } from 'react-scroll';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import getCurrentTeam from 'components/Settings/Team/getCurrentTeam';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PopUpCheckout, { PricingPopUpData } from './PopUpCheckout.js';
import CouponBanner from './CouponBanner.js';
import Navigation from 'components/Dashboard/Navigation/index.js';
import Footer from 'components/home/Footer.js';
import normalize from 'components/Dashboard/utilities/normalizeString.js';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile.js';
import SkeletonPricing from './SkeletonPricing.js';
import createUtmParameters from 'components/Dashboard/helpers/createUtmParameters.js';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas.js';
import {
  consultantAgenciesMembers,
  membershipMembers,
} from 'components/Dashboard/SidebarElements/parliamentarianUsers.js';
import MobileTopToggle from './MobileTopToggle.js';
import useScrollBody from 'components/home/useScrollBody.js';
import { loadKeyWords } from 'components/Dashboard/utilities/loadKeyWords.js';
import getActiveSubscriptions from 'components/Settings/Billing/getActiveSubscriptions.js';
import useOutsideElement from 'components/Dashboard/utilities/useOutsideElement.js';
import useUpdatePaidSubscriptions from 'components/Settings/Billing/useUpdatePaidSubscriptions.js';
import getActivePlan from 'components/Settings/Utilities/getActivePlan.js';
import AgencyLoggedOut from './AgencyLoggedOut.js';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';
import useAddMetaTags from 'components/Dashboard/utilities/addMetaTags.js';
const dayjs = require('dayjs');

const PricingPage = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { readyKeywordLists, keywordsLists, team, activeSubscriptions } = state;
  const [selectedPlanInformation, setSelectedPlanInformation] = useState({
    period: 'annually',
    periodPopup: 'annually',
  });
  const { myself } = team;
  const { period, periodPopup } = selectedPlanInformation;
  const [planInformation, setPlanInformation] = useState({});
  const [activeTab, setActiveTab] = useState('companies');

  const { planName: mainPlanName, isLegacyPlan, isPaperInvoicing, subscriptionStatus } = activeSubscriptions;
  const planName = mainPlanName === 'Campaigner' ? 'Starter' : mainPlanName;
  const { trialEndDateUtc } = planInformation;
  const [isLoading, setIsLoading] = useState(true);
  const highlightedChannel = process.env.REACT_APP_HIGHLIGHTED_CHANNEL;
  const [planToCheckOut, setPlanToCheckout] = useState(highlightedChannel);
  const [showPopUpCheckout, setShowPopUpCheckout] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const showFreePlan = process.env.REACT_APP_SHOW_FREE_PLAN.toLowerCase() !== 'false';
  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const { addMetaTags } = useAddMetaTags();
  //AE: These conditions are different from the billing page although at first glance it looks almost the same
  const bannerConditions =
    isPaperInvoicing ||
    planName === 'Enterprise' ||
    subscriptionStatus === 'OnFreePlan' ||
    subscriptionStatus === 'OnFreePlanOverLimit' ||
    isLegacyPlan ||
    (myself?.role !== 'Owner' && isAuthenticated);
  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';

  const { bannerText } = useUpdatePaidSubscriptions();
  const initialLoad = useRef();
  initialLoad.current = (source) => {
    try {
      if (isAuthenticated) {
        loadActivePlanInformation(source);
      } else {
        let title = `Pricing - PolicyMogul`;
        let hash = location.hash;
        addMetaTags({ title, hash });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useScrollBody();
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialLoad.current(source);
    return () => {
      source.cancel('Pricing page canceled by the user.');
    };
  }, []);

  const loadActivePlanInformation = async (source) => {
    try {
      let activePlan = await getActivePlan(getAccessToken, source);
      if (!!activePlan) {
        const { code, allowMultipleTeams } = activePlan;
        if (consultantAgenciesMembers(code) && allowMultipleTeams) {
          return navigate('/');
        }
      }
      let activeSubscriptions = await getActiveSubscriptions(getAccessToken, source);
      if (!readyKeywordLists) {
        let keywordsLists = await loadKeyWords(getAccessToken, source);
        dispatch({ type: 'MODIFY_SECTION', parameter: 'keywordsLists', value: keywordsLists });
      }
      let currentTeam = await getCurrentTeam(getAccessToken, source);
      if (activeSubscriptions) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'activeSubscriptions', value: activeSubscriptions });
      }
      if (currentTeam) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
      }
      let defineInitialActiveTab = () => {
        let code = activePlan?.code;
        if (consultantAgenciesMembers(code)) {
          return 'agencies';
        } else if (membershipMembers(code)) {
          return 'membership';
        } else {
          return 'companies';
        }
      };
      setActiveTab(defineInitialActiveTab());

      if (activePlan) {
        setPlanInformation(activePlan);
        dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });
      }
      if (activeSubscriptions) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'activeSubscriptions', value: activeSubscriptions });
        let title = `Pricing - PolicyMogul`;
        let hash = location.hash;
        addMetaTags({ title, hash });
        setIsLoading(false);
      }
    } catch (error) {
      //createNotification('danger', `An error ocurred.`)
    }
  };

  const ViewFetures = () => {
    const goToElement = () => {
      scroller.scrollTo('features', {
        offset: -75,
        duration: 1000,
        smooth: true,
      });
    };
    return (
      <p className='view-features' onClick={goToElement}>
        Compare plans
      </p>
    );
  };

  const showPopUp = () => {
    setShowPopUpCheckout(true);
  };

  const PaymentButton = ({ plan, compare }) => {
    const plans = ['Free', 'Starter', 'Pro', 'Mogul', 'Enterprise'];
    const positionActivePlan = plans.indexOf(planName);
    const position = plans.indexOf(plan);
    const role = myself !== undefined ? myself.role : '';

    const conditionalBlock =
      (subscriptionStatus === 'Subscribed' ||
        subscriptionStatus === 'OnFreePlan' ||
        position < positionActivePlan ||
        role !== 'Owner') &&
      plan === planName;
    const actionConditional = role === 'Owner';

    const blockConcitional =
      (plan === planName &&
        (subscriptionStatus === 'InTrial' ||
          subscriptionStatus === 'TrialEnded' ||
          subscriptionStatus === 'SubscriptionEnded') &&
        role !== 'Owner') ||
      (role !== 'Owner' && plan !== planName);

    const utmPlan = period === 'annually' ? 'annual' : 'monthly';

    const utmSourceCode = () => {
      switch (activeTab) {
        case 'agencies':
          return '-agency';
        case 'membership':
          return '-membership-organisation';
        default:
          return '';
      }
    };

    return (
      <>
        {!isAuthenticated && (
          <button
            className={`general-button payment-button px-3 py-2`}
            onClick={() => {
              window.location.assign(
                `/register?reg_source=pricing-${compare ? 'compare' : utmPlan}-${normalize(plan)}${utmSourceCode()}${createUtmParameters('&')}`
              );
            }}
          >
            Free trial
          </button>
        )}
        {isAuthenticated && (
          <button
            className={`general-button payment-button px-3 py-2 ${conditionalBlock ? 'payment-button-block' : ''}`}
            data-tooltip-place={'top'}
            data-tooltip-html={blockConcitional ? 'Contact your account owner' : ''}
            data-tooltip-id={'pricing-page-tooltip'}
            disabled={blockConcitional}
            onClick={async () => {
              if (actionConditional && !bannerConditions) {
                setPlanToCheckout(plan);
                showPopUp();
              }
            }}
            style={{
              cursor: actionConditional && !bannerConditions ? 'pointer' : 'auto',
            }}
          >
            <>
              {(subscriptionStatus === 'Subscribed' ||
                subscriptionStatus === 'OnFreePlan' ||
                subscriptionStatus === 'OnFreePlanOverLimit' ||
                subscriptionStatus === 'SubscribedOverLimit') &&
                plan === planName &&
                'Your plan'}

              {position > positionActivePlan && 'Upgrade'}

              {position < positionActivePlan && 'Downgrade'}
              {subscriptionStatus === 'InTrial' && plan === planName && 'Extend'}
              {(subscriptionStatus === 'TrialEnded' || subscriptionStatus === 'SubscriptionEnded') &&
                plan === planName &&
                'Reactivate'}
              {(blockConcitional || bannerConditions) && (
                <>
                  <span className='pricing-padlock'>Blocked</span>
                </>
              )}
            </>
          </button>
        )}
      </>
    );
  };

  const FreeButtons = () => {
    return (
      <>
        <div className='pricing-description px-4'>
          <p className='text-center'>1 user</p>
        </div>
        <div className='p-3'>
          <PaymentButton plan='Free' />
        </div>
      </>
    );
  };
  const FreePlan = () => {
    return (
      <div className='pricing-card'>
        <div className='pricing-card--title'>
          <h2 className='py-3'>Free</h2>
          <div className='price-container pb-4'>
            <p className='pricing-card--price'>
              £0 <span className='month-text'>/ month</span>
            </p>
          </div>
        </div>
        <FreeButtons />
        <ViewFetures />
        <div className='pricing-features-list pt-3'>
          <ul className='policy-list px-4 pb-5 text-left pricing-features-container'>
            <li>monitoring portal</li>
            <li>1 topic</li>
            <li>5 keywords</li>
          </ul>
        </div>
      </div>
    );
  };

  const BasicButtons = () => {
    return (
      <>
        <div className='pricing-description px-4'>
          <p className='text-center'>1 user</p>
        </div>
        <div className='p-3'>
          <PaymentButton plan='Starter' />
        </div>
      </>
    );
  };
  const BasicPlan = ({ period, price }) => {
    return (
      <div className='pricing-card'>
        <div className='pricing-card--title'>
          <h2 className='py-3'>Starter</h2>
          <div className='price-container pb-4'>
            <p className='pricing-card--price'>
              £{price} <span className='month-text'>/ month</span>
            </p>
            <p className='pricing-card--billed'>
              {period === 'annually' ? `£${thousandCommas(price * 12)} ` : ''}billed {period}
            </p>
          </div>
        </div>
        <BasicButtons />
        <ViewFetures />
        <div className='pricing-features-list pt-3'>
          <ul className='policy-list px-4 pb-5 text-left pricing-features-container'>
            <li>monitoring portal</li>
            <li>1 topic</li>
            <li>10 keywords</li>
            <li>AI assistant</li>
            <li>political CRM</li>
            <li>stakeholder mapping</li>
            <li>lobbying platform</li>
          </ul>
        </div>
      </div>
    );
  };

  const ProButtons = () => {
    return (
      <>
        <div className='pricing-description px-4'>
          <p className='text-center'>Unlimited users</p>
        </div>
        <div className='p-3'>
          <PaymentButton plan='Pro' />
        </div>
      </>
    );
  };

  const ProPlan = ({ period, price }) => {
    return (
      <div className='pricing-card'>
        <div className='pricing-card--title'>
          <h2 className='py-3'>Pro</h2>
          <div className='price-container pb-4'>
            <p className='pricing-card--price'>
              £{price} <span className='month-text'>/ month</span>
            </p>
            <p className='pricing-card--billed'>
              {period === 'annually' ? `£${thousandCommas(price * 12)} ` : ''}billed {period}
            </p>
          </div>
        </div>
        <ProButtons />
        <ViewFetures />
        <div className='pricing-features-list pt-3'>
          <ul className='policy-list px-4 pb-5 text-left pricing-features-container'>
            <li>monitoring portal</li>
            <li>3 topics *</li>
            <li>unlimited keywords</li>
            {showTweets && <li>Twitter monitoring</li>}
            <li>AI assistant</li>
            <li>political CRM</li>
            <li>stakeholder mapping</li>
            <li>lobbying platform</li>
            <li>AI summaries</li>
          </ul>
        </div>
      </div>
    );
  };

  const MogulButtons = () => {
    return (
      <>
        {activeTab === 'companies' && (
          <div className='pricing-description px-4'>
            <p className='text-center'>Unlimited users</p>
          </div>
        )}
        <div className='p-3'>
          <PaymentButton plan='Mogul' />
        </div>
      </>
    );
  };
  const MogulPlan = ({ period, price, additionalPrice, agencyPrice }) => {
    let currentPrice = activeTab === 'agencies' ? agencyPrice : price;
    return (
      <div className='pricing-card'>
        <div className='pricing-card--title mogul-title'>
          <h2 className='py-3'>Mogul</h2>
          <div className='price-container pb-4'>
            <p className='pricing-card--price '>
              <span className='from-text'></span> £{currentPrice}{' '}
              <span className='month-text'>{activeTab === 'agencies' ? '/ client ' : ''}/ month</span>
            </p>
            <p className='pricing-card--billed'>
              {period === 'annually' ? `£${thousandCommas(currentPrice * 12)} ` : ''} billed {period}
            </p>
          </div>
        </div>
        <MogulButtons />
        <ViewFetures />
        <div className='pricing-features-list pt-3'>
          <ul className='policy-list px-4 pb-5 text-left pricing-features-container'>
            <li>monitoring portal</li>
            {activeTab !== 'agencies' && <li>3 topics *</li>}
            {activeTab !== 'agencies' && <li>unlimited keywords</li>}
            {showTweets && <li>Twitter monitoring</li>}
            <li>AI assistant</li>
            <li>political CRM</li>
            <li>stakeholder mapping</li>
            <li>lobbying platform</li>
            <li>AI summaries</li>
            {activeTab !== 'agencies' && (
              <>
                <li>analytics</li>
                <li>reports</li>
              </>
            )}
          </ul>
        </div>
      </div>
    );
  };
  const CustomButtons = () => {
    return (
      <>
        {activeTab === 'companies' && (
          <div className='pricing-description px-4'>
            <p className='text-center'>Unlimited users</p>
          </div>
        )}
        <div className='p-3'>
          <EnterprisePaymentButton />
        </div>
      </>
    );
  };

  const EnterprisePaymentButton = () => {
    const enterprisePlan = planName === 'Enterprise';
    return (
      <button
        className={`${enterprisePlan ? '' : ''} general-button payment-button px-3 py-2 payment-button-block pricing-contact-button`}
        disabled={enterprisePlan}
        style={{
          cursor: enterprisePlan ? 'auto' : 'pointer',
        }}
        onClick={() => {
          window.open('https://calendar.app.google/XfyRwDQ1m1BjLKB47', '_blank');
        }}
      >
        {enterprisePlan ? 'Your plan' : 'Book a demo'}
      </button>
    );
  };

  const CustomPlan = ({ price }) => {
    return (
      <div className='pricing-card enterprise-card'>
        <div className='pricing-card--title'>
          <h2 className='py-3'>Enterprise</h2>
          <div className='price-container pb-4'>
            {activeTab === 'agencies' ? (
              <p className='pricing-card--price '>
                <span className='from-text'></span> £395{' '}
                <span className='month-text'>/ client / month{activeTab === 'agencies' ? '*' : ''}</span>
              </p>
            ) : (
              <p className='pricing-card--price contact-us-price'>
                <span className='from-text'></span> Contact us <span className='month-text'></span>
              </p>
            )}
            <p className='pricing-card--billed'>{activeTab === 'agencies' ? `£4,740` : ''} billed annually</p>
          </div>
        </div>
        <CustomButtons />
        <ViewFetures />
        <div className='pricing-features-list pt-3'>
          <ul className='policy-list px-4 pb-5 text-left pricing-features-container'>
            <li>monitoring portal</li>
            {activeTab !== 'agencies' && <li>unlimited topics</li>}
            {activeTab !== 'agencies' && <li>unlimited keywords</li>}
            {showTweets && <li>Twitter monitoring</li>}
            <li>AI assistant</li>
            <li>political CRM</li>
            <li>stakeholder mapping</li>
            <li>lobbying platform</li>
            <li>AI summaries</li>
            <li>analytics</li>
            {activeTab !== 'agencies' && <li>reports</li>}
            <li>early access to news **</li>
            <li>research team</li>
          </ul>
        </div>
      </div>
    );
  };

  const calculateDifference = (date) => {
    const date1 = dayjs();
    const date2 = dayjs(date);
    const difference = -date1.diff(date2, 'day');
    return `${difference} day${difference !== 1 ? 's' : ''}`;
  };

  const ExpiresMessage = () => {
    return (
      <>
        {(subscriptionStatus === 'TrialEnded' ||
          subscriptionStatus === 'SubscriptionEnded' ||
          subscriptionStatus === 'InTrial') && (
          <div
            className='mogul-message'
            style={{
              top: `-${subscriptionStatus === 'TrialEnded' ? 30 : 30}px`, //51
            }}
          >
            {subscriptionStatus === 'InTrial' && (
              <p className='date-text'>expires in {calculateDifference(trialEndDateUtc)}</p>
            )}
            <p className='message-text py-2'>{subscriptionStatus === 'InTrial' ? 'Current ' : 'Expired '} plan</p>
          </div>
        )}
      </>
    );
  };

  const TrialStartsMessage = () => {
    return (
      <>
        {activeTab === 'companies' && (
          <div
            className='mogul-message'
            style={{
              top: `-30`,
            }}
          >
            <p className='message-text py-2'>Trial starts here</p>
          </div>
        )}
      </>
    );
  };

  const isMobile = useIsMobile();
  const param = getUrlParam('returnUrl');
  const toggleOptions = useRef(null);
  const [toggleDimentions, setToggleDimentions] = useState({ left: 0, width: '254px' });
  const changeTopToggleOption = (e, option) => {
    setActiveTab(option);
    if (!isMobile) {
      let { left, width } = e.target.getBoundingClientRect();
      let finalLeft = left - toggleOptions.current.getBoundingClientRect().left;
      setToggleDimentions({ left: finalLeft - 1, width });
    }
  };

  const agencyLoggeout = !isAuthenticated && activeTab === 'agencies';

  return (
    <div className='pricing-page ' id='scroll-container'>
      {isLoading && <SkeletonPricing />}
      {!isLoading && (
        <>
          {!isAuthenticated && <Navigation />}
          {isAuthenticated && (
            <div className='py-3 px-3 px-lg-5 main-navigation-logo settings-header' style={{ position: 'relative' }}>
              <div className='mx-0 justify-content-between align-items-center row '>
                <Link to='/monitor-inbox'>
                  <img src={`${process.env.REACT_APP_CDNURL}/images/logo.svg`} alt='Logo' />
                </Link>
                {subscriptionStatus !== 'TrialEnded' &&
                  subscriptionStatus !== 'OnFreePlanOverLimit' &&
                  subscriptionStatus !== 'SubscribedOverLimit' && (
                    <Link
                      className='close-settings'
                      to={`${param !== undefined ? decodeURIComponent(param) : '/monitor-inbox'}`}
                    />
                  )}
              </div>
            </div>
          )}

          <div className='pricing-page-heading py-lg-5 pt-5 pb-3'>
            <div className='container text-center'>
              <h1 className='mt-5'>Find the plan that’s right for you</h1>
              {!isAuthenticated && (
                <>
                  {isMobile && <MobileTopToggle activeTab={activeTab} changeTopToggleOption={changeTopToggleOption} />}
                  {!isMobile && (
                    <div
                      className='justify-content-center mt-5 pricing-toggle-tabs position-relative'
                      ref={toggleOptions}
                    >
                      <div
                        className='pricing-toggle-indicator'
                        style={{
                          transform: `translateX(${toggleDimentions.left}px)`,
                          width: `${toggleDimentions.width}px`,
                        }}
                      />
                      <button
                        className={`general-button action-state-hover toggle-pricing-button ${activeTab === 'companies' ? 'active' : ''}`}
                        onClick={(e) => {
                          changeTopToggleOption(e, 'companies');
                        }}
                      >
                        For companies and charities
                      </button>
                      <button
                        className={`general-button action-state-hover toggle-pricing-button ${activeTab === 'agencies' ? 'active' : ''}`}
                        onClick={(e) => {
                          changeTopToggleOption(e, 'agencies');
                        }}
                      >
                        For agencies and consultants
                      </button>
                      <button
                        className={`general-button action-state-hover toggle-pricing-button ${activeTab === 'membership' ? 'active' : ''}`}
                        onClick={(e) => {
                          changeTopToggleOption(e, 'membership');
                        }}
                      >
                        For membership organisations
                      </button>
                    </div>
                  )}
                </>
              )}

              <CouponBanner />
              {bannerConditions && (
                <p className={`bg-main-blue text-white rounded py-2 px-4 mt-5 pricing-unauthenticated-banner-text`}>
                  {bannerText()}.{' '}
                  {subscriptionStatus !== 'SubscriptionEnded' && myself?.role === 'Owner' && (
                    <>
                      <span className='contact-button-launch-messenger pointer text-yellow text-underline-hover'>
                        {' '}
                        Contact us
                      </span>{' '}
                      to amend your subscription.
                    </>
                  )}
                </p>
              )}
              {!agencyLoggeout ? (
                <>
                  <div className='d-flex toggle-container align-items-center justify-content-center my-5'>
                    <p className={`${period === 'monthly' ? 'active-period' : ''}`}>Monthly</p>
                    <button
                      className={`general-button mx-4  toggle-component pricing-toggle ${period === 'annually' ? 'toggle-annually' : 'toggle-inactive'}`}
                      onClick={() => {
                        if (period === 'annually') {
                          setSelectedPlanInformation({
                            ...selectedPlanInformation,
                            period: 'monthly',
                            periodPopup: 'monthly',
                          });
                        } else {
                          setSelectedPlanInformation({
                            ...selectedPlanInformation,
                            period: 'annually',
                            periodPopup: 'annually',
                          });
                        }
                      }}
                    />
                    <p className={`${period === 'annually' ? 'active-period' : ''}`}>
                      Annually <span className='pl-2'>(Save 20%)</span>
                    </p>
                  </div>

                  <div
                    className={`row justify-content-center pt-5 pb-lg-4 ${highlightedChannel === 'Pro' ? 'px-lg-5' : ''} ${activeTab !== 'companies' ? 'agencies-padding-pricing' : ''}`}
                  >
                    {activeTab === 'companies' && (
                      <>
                        {showFreePlan && (
                          <div
                            className='col-lg price-box mb-5 mb-lg-0'
                            style={{
                              transform: `rotateY(${period === 'annually' ? '0' : '180'}deg)`,
                            }}
                          >
                            <div className='side-a'>
                              <FreePlan />
                            </div>
                            <div className='side-b'>
                              <FreePlan />
                            </div>
                          </div>
                        )}

                        {!showFreePlan && (
                          <div
                            className='col-lg price-box mb-5 mb-lg-0'
                            style={{
                              transform: `rotateY(${period === 'annually' ? '0' : '180'}deg)`,
                            }}
                          >
                            <div className='side-a'>
                              <BasicPlan period={'annually'} price={PricingPopUpData['Starter'].priceAnnually} />
                            </div>
                            <div className='side-b'>
                              <BasicPlan period={'monthly'} price={PricingPopUpData['Starter'].priceMonthly} />
                            </div>
                          </div>
                        )}

                        <div
                          className={`col-lg price-box mb-5 mb-lg-0 ${highlightedChannel === 'Pro' ? 'highlighted-channel' : ''}`}
                          style={{
                            transform: `rotateY(${period === 'annually' ? '0' : '180'}deg)`,
                          }}
                        >
                          <div className='side-a'>
                            {planName === 'Pro' && <ExpiresMessage />}
                            <ProPlan period={'annually'} price={PricingPopUpData['Pro'].priceAnnually} />
                          </div>
                          <div className='side-b'>
                            {planName === 'Pro' && <ExpiresMessage />}
                            <ProPlan period={'monthly'} price={PricingPopUpData['Pro'].priceMonthly} />
                          </div>
                        </div>
                      </>
                    )}

                    <div
                      className={`col-lg price-box mb-5 mb-lg-0 ${highlightedChannel === 'Mogul' ? 'highlighted-channel' : ''}`}
                      style={{
                        transform: `rotateY(${period === 'annually' ? '0' : '180'}deg)`,
                      }}
                    >
                      <div className='side-a position-relative'>
                        {!isAuthenticated && <TrialStartsMessage />}
                        {planName === 'Mogul' && <ExpiresMessage />}
                        <MogulPlan
                          period={'annually'}
                          price={PricingPopUpData['Mogul'].priceAnnually}
                          additionalPrice={PricingPopUpData['Mogul'].categoryPriceAnnually}
                          agencyPrice={PricingPopUpData['Mogul'].agencyPriceAnnually}
                        />
                      </div>
                      <div className='side-b'>
                        {!isAuthenticated && <TrialStartsMessage />}
                        {planName === 'Mogul' && <ExpiresMessage />}
                        <MogulPlan
                          period={'monthly'}
                          price={PricingPopUpData['Mogul'].priceMonthly}
                          additionalPrice={PricingPopUpData['Mogul'].categoryPriceMonthly}
                          agencyPrice={PricingPopUpData['Mogul'].agencyPriceMonthly}
                        />
                      </div>
                    </div>

                    {highlightedChannel === 'Mogul' && (
                      <div
                        className='col-lg price-box mb-5 mb-lg-0'
                        style={{
                          transform: `rotateY(${period === 'annually' ? '0' : '180'}deg)`,
                        }}
                      >
                        <div className='side-a'>
                          <CustomPlan period={'annually'} price={850} />
                        </div>
                        <div className='side-b'>
                          <CustomPlan period={'monthly'} price={850} />
                        </div>
                      </div>
                    )}
                  </div>
                  <p className='my-2'>
                    *{' '}
                    {activeTab === 'agencies'
                      ? 'Minimum of 3 clients. Billed annually as £4,740 per client'
                      : `Additional topics can be purchased for £${period === 'annually' ? 95 : 125}/month`}{' '}
                  </p>
                  <p className='my-2'>** Subject to early access conditions</p>
                  <p>
                    <strong>Prices are exclusive of VAT</strong>
                  </p>
                </>
              ) : (
                <AgencyLoggedOut />
              )}
            </div>
          </div>
          {!agencyLoggeout && (
            <Element id='features'>
              <div className={`pricing-features p-lg-5 py-5 px-3 ${!showFreePlan ? 'pricing-without-free' : ''}`}>
                <h1 className='text-center mt-2 mb-5'>Compare plans</h1>
                <div className='container'>
                  <div className={`table-responsive pricing-table`}>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th scope='col'></th>
                          <>
                            {activeTab === 'companies' && (
                              <>
                                {showFreePlan && (
                                  <th scope='col' className='pricing-plan-title'>
                                    <p>Free</p>
                                    <PaymentButton plan={'Free'} compare />
                                  </th>
                                )}
                                {!showFreePlan && (
                                  <th scope='col' className='pricing-plan-title'>
                                    <p>Starter</p>
                                    <PaymentButton plan={'Starter'} compare />
                                  </th>
                                )}

                                <th scope='col' className='pricing-plan-title'>
                                  <p>Pro</p>
                                  <div
                                    className={`${highlightedChannel === 'Pro' ? 'highlighted-payment-button' : ''}`}
                                  >
                                    <PaymentButton plan={'Pro'} compare />
                                  </div>
                                </th>
                              </>
                            )}
                          </>
                          <th scope='col' className='pricing-plan-title'>
                            <p>Mogul</p>
                            <div className={`${highlightedChannel === 'Mogul' ? 'highlighted-payment-button' : ''}`}>
                              <PaymentButton plan={'Mogul'} compare />
                            </div>
                          </th>
                          {highlightedChannel === 'Mogul' && (
                            <th scope='col' className='pricing-plan-title'>
                              <p>Enterprise</p>
                              <EnterprisePaymentButton />
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='pricing-matrix-title pricing-users-title'>
                          <td>
                            <h4>Users</h4>
                          </td>
                          {activeTab === 'companies' && (
                            <>
                              <td>1</td>
                              <td>unlimited</td>
                            </>
                          )}
                          <td>unlimited</td>
                          {highlightedChannel === 'Mogul' && <td>unlimited</td>}
                        </tr>
                        <CreateDataComponent data={monitoringData} name={'Monitor'} activeTab={activeTab} />
                        <CreateDataComponent data={influenceData} name={'Influence'} activeTab={activeTab} />
                        {activeTab !== 'agencies' && (
                          <CreateDataComponent data={reportsData} name={'Reports'} activeTab={activeTab} />
                        )}
                        <CreateDataComponent data={researchData} name={'Research'} activeTab={activeTab} />
                        <CreateDataComponent data={embeddablesData} name={'Embeddables'} activeTab={activeTab} />
                        <CreateDataComponent data={supportData} name={'Support'} activeTab={activeTab} />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Element>
          )}
          {isAuthenticated && (
            <div className='vat-message'>
              <div className='bottom-terms'>
                <div className='row mx-0 pt-2 pb-3 align-items-center'>
                  <a
                    className='text-uppercase'
                    href='https://policymogul.com/'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    PolicyMogul
                  </a>
                  <span className='dot' />
                  <a href='https://policymogul.com/terms-and-conditions/' rel='noopener noreferrer' target='_blank'>
                    T&Cs
                  </a>
                  <div className='px-2'></div>
                  <a href='https://policymogul.com/privacy-policy/' rel='noopener noreferrer' target='_blank'>
                    Privacy policy
                  </a>
                </div>
              </div>
            </div>
          )}

          <PopUpCheckout
            targetPlan={planToCheckOut}
            setPlanToCheckout={setPlanToCheckout}
            setSelectedPlanInformation={setSelectedPlanInformation}
            selectedPlanInformation={selectedPlanInformation}
            period={periodPopup}
            activePlanName={planName}
            activeTab={activeTab}
            keywordsLists={keywordsLists}
            showPopUpCheckout={showPopUpCheckout}
            setShowPopUpCheckout={setShowPopUpCheckout}
          />

          <ReactTooltip
            id={'pricing-page-tooltip'}
            effect={'solid'}
            type={'info'}
            className={'pricing-feature-tooltip'}
            backgroundColor={'#00122B'}
            textColor={'#fff'}
            multiline={true}
            arrowColor={'transparent'}
            place={'right'}
            /* globalEventOff={isMobile ? 'click' : undefined}  */
          />
        </>
      )}
      {!isAuthenticated && <Footer />}
    </div>
  );
};

const CreateDataComponent = (props) => {
  const { data, name, activeTab } = props;
  const [customText, setCustomText] = useState({
    basic: 'included',
    pro: 'included',
    mogul: 'included',
    custom: 'unlimited',
  });
  const highlightedChannel = process.env.REACT_APP_HIGHLIGHTED_CHANNEL;
  const showFreePlan = process.env.REACT_APP_SHOW_FREE_PLAN.toLowerCase() !== 'false';

  const tickElement = (feature, name) => {
    return (
      <td>
        {feature && feature !== 'no include' ? (
          feature === 'yes' ? (
            <img className='circle-tick' src={`${process.env.REACT_APP_CDNURL}/images/circle-tick.svg`} alt='True' />
          ) : (
            <>{typeof feature === 'string' ? feature : feature()}</>
          )
        ) : (
          '—'
        )}
      </td>
    );
  };

  const hoverTextCondition = (item) => {
    if (activeTab === 'agencies') {
      return item.agenciesHoverText ? item.agenciesHoverText !== 'hide' : item.hoverText;
    } else {
      return item.hoverText;
    }
  };

  return (
    <>
      <tr className='pricing-matrix-title'>
        <td>
          <h4>{name}</h4>
        </td>
        {activeTab === 'companies' && (
          <>
            <td />
            <td />
          </>
        )}
        <td />
        {highlightedChannel === 'Mogul' && <td />}
      </tr>
      {data.map((item, index) => {
        const {
          hideForAgencies,
          name,
          agenciesName,
          agenciesHoverText,
          hoverText,
          free,
          basic,
          pro,
          mogul,
          custom,
          mogulAgencies,
          customAgencies,
          customDropdown,
          tooltipClass,
        } = item;
        return (
          <React.Fragment key={`feature${index}`}>
            {(activeTab !== 'agencies' || (activeTab === 'agencies' && !hideForAgencies)) && (
              <tr className={`${name}-feature-${index}`}>
                <td>
                  <p className={`mb-0 flex-centered pricing-feature-item`}>
                    <span>{activeTab === 'agencies' && agenciesName ? agenciesName : name}</span>
                    {item.beta && (
                      <span className='text-uppercase main-light-text paragraph-p7 ml-2 beta-feature bg-white'>
                        Beta
                      </span>
                    )}
                    {hoverTextCondition(item) && (
                      <span
                        className='pricing-feature-more'
                        data-tooltip-html={
                          activeTab === 'agencies' && agenciesHoverText ? agenciesHoverText : hoverText
                        }
                        data-tooltip-class-name={tooltipClass ?? 'pricing-feature-tooltip'}
                        data-tooltip-id={'pricing-page-tooltip'}
                      >
                        More information
                      </span>
                    )}
                    <span>{customDropdown && <DropdownContacts setCustomText={setCustomText} />}</span>
                  </p>
                </td>
                {activeTab === 'companies' && (
                  <>
                    {customDropdown ? <td>{customText?.pro}</td> : tickElement(showFreePlan ? free : basic)}
                    {customDropdown ? <td>{customText?.pro}</td> : <>{tickElement(pro)}</>}
                  </>
                )}
                {customDropdown ? (
                  <td>{customText?.mogul}</td>
                ) : (
                  <>{tickElement(mogulAgencies && activeTab === 'agencies' ? mogulAgencies : mogul)}</>
                )}
                {highlightedChannel === 'Mogul' && (
                  <>
                    {customDropdown ? (
                      <td>{customText?.custom}</td>
                    ) : (
                      <>{tickElement(customAgencies && activeTab === 'agencies' ? customAgencies : custom)}</>
                    )}
                  </>
                )}
              </tr>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

const DropdownContacts = (props) => {
  const { setCustomText } = props;
  const [value, setValue] = useState(50);
  const [isOpen, setIsOpen] = useState(false);

  const boxElement = useRef(null);
  const hideBox = () => {
    setIsOpen(false);
  };
  useOutsideElement(boxElement, hideBox);

  return (
    <span className='d-block position-relative select-contact-pricing ml-3' ref={boxElement}>
      <span
        className={`border box-shadow pointer pl-1 pr-3 py-1 rounded dropdown-item-element select-contact-value action-state-hover ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{thousandCommas(value)}</span>
      </span>
      {isOpen && (
        <div className='frequency-dropdown scroll-container search-bar-lists'>
          {Array(40)
            .fill()
            .map((item, index) => {
              let itemValue = (index + 1) * 50;
              return (
                <p
                  key={`dropdown-contact-${index}`}
                  className={`mb-0 hour-item ${itemValue === value ? 'hour-item-selected' : ''}`}
                  onClick={() => {
                    setValue(itemValue);
                    if (index === 0) {
                      setCustomText({ pro: 'included', mogul: 'included', custom: 'unlimited' });
                    } else {
                      setCustomText({
                        pro: `£${index * 19} / month`,
                        mogul: `£${index * 19} / month`,
                        custom: `unlimited`,
                      });
                    }
                    setIsOpen(false);
                  }}
                >
                  {`${thousandCommas(itemValue)}`}
                </p>
              );
            })}
        </div>
      )}
    </span>
  );
};

export default PricingPage;
