import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import useCreateParliamentLiveFilters from 'components/Dashboard/ParliamentLive/useCreateParliamentLiveFilters';
import { store } from 'components/Store';
import dayjs from 'dayjs';
import { useContext } from 'react';

const useGetRealTimeEvents = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { realtimeEvents } = state;

  const { generalApiCall } = useGeneralApiCall();
  const { createUKDate } = useCreateParliamentLiveFilters();

  const getRealTimeEvents = async ({ source, needsAuthentication = true, notUseStorage }) => {
    try {
      if (!realtimeEvents || notUseStorage) {
        const currentUKDate = createUKDate({});
        const pathname = `/api/realtimeevent/realtime-events?isoDate=${dayjs.utc(currentUKDate)?.format('YYYY-MM-DD')}`;
        const result = await generalApiCall({
          pathname,
          method: 'get',
          needsAuthentication,
          notShowErrorMessage: true,
          requestSource: source,
        });
        if (!!result) {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'realtimeEvents', value: result });
          return result;
        }
      }
    } catch (error) {}
  };

  const getRealTimeEvent = async ({ source, eventId }) => {
    if (!!eventId) {
      try {
        const pathname = `/api/realtimeevent/realtime-event?id=${eventId}`;
        return await generalApiCall({
          pathname,
          method: 'get',
          needsAuthentication: true,
          notShowErrorMessage: true,
          requestSource: source,
        });
      } catch (error) {}
    }
  };

  return { getRealTimeEvents, getRealTimeEvent };
};

export default useGetRealTimeEvents;
