import React, { useContext, useEffect, useRef, useState } from 'react';
import { store } from 'components/Store.js';
import { Link, useLocation } from 'react-router-dom';
import normalizeString from 'components/Dashboard/utilities/normalizeString';
import getSearchWithinHash from 'components/Dashboard/utilities/getSearchWithinHash.js';
import idsWithoutImages from './idsWithoutImages.js';
import ReactGA from 'react-ga4';
import TagsLists from 'components/Dashboard/TagsList';
import { contentItemSearchQuery } from 'components/Dashboard/helpers/locationSearchDescriptor.js';
import Share from 'components/Dashboard/components/Share.js';
import useRememberPosition from 'components/Dashboard/hooks/useRememberPosition.js';
import { useParams } from 'react-router-dom';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter.js';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';
import ThumbsDown from 'components/Common/ThumbsDown.js';
import FeedbackOnItem from 'components/Dashboard/FeedbackItem/FeedbackOnItem.js';
import createMarkup from 'components/Dashboard/helpers/createMarkup.js';
import getAppSite from 'utils/getAppSite.js';

const dayjs = require('dayjs');
const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  monthsShort: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
});

const ItemNew = (props) => {
  const {
    explanations,
    item,
    itemOnclick,
    index,
    notShowDescriptionAndShare,
    scrollBarRef,
    feedItemFlags: propsFeedItemFlags,
    array,
    embedShowTags,
    notShowShare,
    itemFromWidget,
    useContentRelatedForFeedback,
    nameSection,
    listView,
    openInNewTab,
    switchRelevantExtractOrSummary,
  } = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const { contentResults, activeResults, search, lastSeenMarkers, itemsViewed } = state;

  const location = useLocation();
  const { list, activeSearch } = search;
  const { KeyUpdates } = contentResults[activeResults];
  const feedItemFlags = propsFeedItemFlags ?? KeyUpdates?.feedItemFlags ?? [];
  const { rememberPosition } = useRememberPosition();

  const params = useParams();
  const { inboxSection } = useMainSectionParameter(params);

  const locationDescriptor = (path) => {
    let locationSearch = contentItemSearchQuery(location, list);
    if (getUrlParam('topic-id') === 'all') {
      if (inboxSection) {
        locationSearch = `?topic-id=${feedItemFlags?.[index]?.matchingKeywordLists[0]?.id}&stakeholder-topic=false`;
      } else {
        locationSearch = '';
      }
    }
    return {
      pathname: path,
      search: `${locationSearch}`,
      hash: getSearchWithinHash() ? getSearchWithinHash(true) : '',
    };
  };

  const feedItemsConditional = list !== null && feedItemFlags?.length > 0;
  const feedItem = feedItemFlags?.[index];
  const isBackpopulatedCondition = feedItem?.isBackpopulated === null || feedItem?.isBackpopulated === true;
  const unseenConditional =
    feedItemsConditional &&
    !!lastSeenMarkers['keyUpdateFeedItemId'] &&
    lastSeenMarkers['keyUpdateFeedItemId'] < feedItem?.feedItemId &&
    !itemsViewed.includes(item?.objectID) &&
    !isBackpopulatedCondition &&
    !itemFromWidget;

  const alreadyMarkedFeedback = feedItem?.ratingScored === 'Negative';

  return (
    <>
      <Link
        to={locationDescriptor(`/key-updates/${item.id}/${normalizeString(item.title)}`)}
        className={`content-item-link`}
        style={{ zIndex: array ? array.length - index : 1 }}
        target={openInNewTab ? '_blank' : undefined}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
        onClick={(e) => {
          if (scrollBarRef) {
            rememberPosition('KeyUpdates', scrollBarRef, index);
          }
          if (itemOnclick) {
            itemOnclick(e);
          }
          if (item.splitFilterNoActivity) {
            ReactGA.event({
              category: 'Blank Stakeholder Key Update Click',
              action: `${item.splitFilterNoActivity} click`,
              label: item.contentUrl,
            });
          }
        }}
      >
        <li className={`my-2 content-item`}>
          {unseenConditional && (
            <>
              <span className='unseen-dot' />
            </>
          )}
          <div className='p-3'>
            <div className='flex-centered mb-2 ml-2'>
              <h4 className={`mb-0 flex-grow-1 content-item-title`}>{item.title}</h4>
              {!notShowShare && (
                <div className='flex-centered'>
                  <ThumbsDown
                    item={item}
                    index={index}
                    useContentRelatedForFeedback={useContentRelatedForFeedback}
                    nameSection={nameSection}
                  />
                  <Share item={item} listItem />
                </div>
              )}
            </div>
            <div className='row mx-0 align-items-start'>
              <div className='content-title flex-grow-1 pr-lg-5 pr-2 ml-2'>
                {!notShowDescriptionAndShare && (
                  <>
                    <RelevantExtract
                      item={item}
                      listView={listView}
                      switchRelevantExtractOrSummary={switchRelevantExtractOrSummary}
                    />
                  </>
                )}

                <p className='heading-date flex-grow-1 px-0 mb-0'>
                  {getAppSite() === 'usa' && item.source && (
                    <>
                      <span className='paragraph-p4'>{new URL(item.source)?.hostname}</span>
                      <span className='dot'></span>
                    </>
                  )}
                  <span className='date'>{dayjs(item.dateTime).format('DD MMM[,] YYYY')}</span>
                  <span className='dot'></span>
                  {dayjs(item.dateTime).format('HH:mm')}
                </p>

                {((list !== null && activeSearch && !itemFromWidget) || embedShowTags) && (
                  <TagsLists
                    {...props}
                    explanations={explanations ?? KeyUpdates?.explanations}
                    feedItemFlags={feedItemFlags}
                    index={index}
                    section={'KeyUpdates'}
                    embargoed={item.embargoed}
                    embargoedUntil={item.embargoedUntil}
                  />
                )}
              </div>
              {!itemFromWidget && getAppSite() !== 'usa' && (
                <div
                  className='content-image d-none d-sm-block'
                  style={{
                    backgroundImage: item.imageUrl
                      ? `url(${item.imageUrl})`
                      : item.imageId !== 0 && !idsWithoutImages.includes(item.imageId)
                        ? `url('https://www.parliamenttoday.com/image.php?imageid=${item.imageId}&mode=1')`
                        : `url('${process.env.REACT_APP_CDNURL}/images/keyupdates-placeholder.png')`,
                  }}
                />
              )}
            </div>
          </div>
          {alreadyMarkedFeedback && !itemFromWidget && <FeedbackOnItem item={item} index={index} />}
        </li>
      </Link>
    </>
  );
};

const RelevantExtract = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { sectionsView } = state;

  const { item, listView, switchRelevantExtractOrSummary } = props;
  const { oneSentenceSummary, highlights, body } = item ?? {};
  const [heightItem, setHeightItem] = useState(0);
  const [translateValue, setTranslateValue] = useState(0);
  const [animated, setAnimated] = useState();

  const { selectedOptionRelevantExtract } = sectionsView ?? {};
  const [localSelectedOption, setLocalSelectedOption] = useState(selectedOptionRelevantExtract);
  const sentenceSummaryComponent = useRef();
  const titleBodyComponent = useRef();

  const isMountedComponent = useRef(false);
  const oneAiSummary = selectedOptionRelevantExtract === 'One-line AI summary';

  useEffect(() => {
    const createHeight = () => {
      let bodyElement = titleBodyComponent?.current;
      let sentenceElement = sentenceSummaryComponent?.current;

      let elHeight = selectedOptionRelevantExtract === 'Most relevant extract' ? bodyElement : sentenceElement;
      setTranslateValue(oneAiSummary ? bodyElement?.getBoundingClientRect()?.height + 8 : 0);
      setHeightItem(elHeight?.getBoundingClientRect()?.height + 8);
    };
    createHeight();
    setTimeout(() => {
      isMountedComponent.current = true;
    }, 100); //value while the text gets in the correct position
  }, [selectedOptionRelevantExtract]);

  useEffect(() => {
    if (selectedOptionRelevantExtract !== localSelectedOption) {
      setLocalSelectedOption(selectedOptionRelevantExtract);
      setTimeout(() => {
        setAnimated(true);
        setTimeout(() => {
          setAnimated(false);
        }, 1000); //value of shimmer animation
      }, 500); //Value of dropdown animation
    }
  }, [selectedOptionRelevantExtract]);

  return (
    <div
      className='overflow-hidden relevant-extract-keyupdates'
      style={{
        height: `${!!oneSentenceSummary && switchRelevantExtractOrSummary ? `${heightItem}px` : 'auto'}`,
      }}
    >
      <p
        className={`${listView ? 'title-body-before' : ''} ${isMountedComponent.current && listView ? 'title-body-transition' : ''} mb-2 title-body ${
          animated ? 'title-body-animated' : ''
        }`}
        style={
          !!oneSentenceSummary && listView
            ? {
                transform: `translateY(-${translateValue}px)`,
                opacity: oneAiSummary ? 0 : undefined,
                transition: oneAiSummary ? 'all 500ms' : undefined,
              }
            : null
        }
        ref={titleBodyComponent}
        dangerouslySetInnerHTML={createMarkup(highlights?.highlightedBodySnippets[0] ?? body)}
      />
      {!!oneSentenceSummary && switchRelevantExtractOrSummary && (
        <p
          className={`${listView ? 'title-body-before' : ''} ${isMountedComponent.current ? 'title-body-transition' : ''} title-body ${
            animated ? 'title-body-animated' : ''
          } ${!(!!oneSentenceSummary && listView) ? 'mb-2' : ''}`}
          style={
            !!oneSentenceSummary && listView
              ? {
                  transform: `translateY(-${translateValue}px)`,
                }
              : null
          }
          ref={sentenceSummaryComponent}
          dangerouslySetInnerHTML={createMarkup(oneSentenceSummary)}
        />
      )}
    </div>
  );
};

export default ItemNew;
