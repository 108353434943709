import currentDate from 'components/Dashboard/utilities/currentDateUTC';

const ConsultationsFilters = [
  {
    name: 'Sort',
    searchState: 'allResults',
    type: 'sort',
    pages: ['Consultations'],
    optionSelected: 'Source (A - Z)',
    options: [
      {
        name: 'Source (A - Z)',
        hash: 'source',
        filter: [{ pages: ['Consultations'], property: { field: 'source', order: 'ASC' } }],
      },
      {
        name: 'Recently published',
        filter: [{ pages: ['Consultations'], property: { field: 'openDate', order: 'desc' } }],
        labelClassName: 'h-auto',
      },
      {
        name: 'Consultation name (A - Z)',
        hash: 'a-z',
        filter: [{ pages: ['Consultations'], property: { field: 'title', order: 'ASC' } }],
      },
      {
        name: 'Consultation closing date',
        hash: 'closing-soonest',
        filter: [
          { pages: ['Consultations'], property: { field: 'closeDateTimestamp', order: 'consultation_custom_sort' } },
        ],
      },
    ],
  },
  {
    name: 'Sort',
    searchState: 'inbox',
    type: 'sort',
    pages: ['Consultations'],
    optionSelected: 'Most recently added',
    options: [
      {
        name: 'Most recently added',
        filter: [{ pages: ['Consultations'], property: { field: 'feedItemId', order: 'desc' } }],
      },
      {
        name: 'Consultation opening date',
        filter: [{ pages: ['Consultations'], property: { field: 'openDate', order: 'desc' } }],
        labelClassName: 'h-auto',
      },
      {
        name: 'Consultation name (A - Z)',
        hash: 'a-z',
        filter: [{ pages: ['Consultations'], property: { field: 'title', order: 'asc' } }],
      },
      {
        name: 'Consultation closing date',
        hash: 'closing-soonest',
        filter: [
          { pages: ['Consultations'], property: { field: 'closeDateTimestamp', order: 'consultation_custom_sort' } },
        ],
      },
    ],
  },
  {
    name: 'Sort',
    searchState: 'referenceSection',
    type: 'sort',
    pages: ['Consultations'],
    optionSelected: 'Consultation closing date',
    options: [
      {
        name: 'Consultation name (A - Z)',
        hash: 'a-z',
        filter: [{ pages: ['Consultations'], property: { field: 'title', order: 'asc' } }],
      },
      {
        name: 'Consultation closing date',
        hash: 'closing-soonest',
        filter: [
          { pages: ['Consultations'], property: { field: 'closeDateTimestamp', order: 'consultation_custom_sort' } },
        ],
      },
    ],
  },
  {
    name: 'Exclude closed consultations',
    searchState: 'inbox',
    pages: ['Consultations'],
    filterName: 'closeDate',
    filterValue: currentDate,
    operator: 'DATE_GTE',
    type: 'toggle',
    value: false,
    notUseFromLocalStorage: true,
  },
  {
    name: 'Exclude closed consultations',
    searchState: 'referenceSection',
    pages: ['Consultations'],
    filterName: 'closeDate',
    filterValue: currentDate,
    operator: 'DATE_GTE',
    type: 'toggle',
    value: true,
    notUseFromLocalStorage: true,
  },
  {
    name: 'Exclude closed consultations',
    searchState: 'allResults',
    pages: ['Consultations'],
    filterName: 'closeDate',
    filterValue: currentDate,
    operator: 'DATE_GTE',
    type: 'toggle',
    hidden: true,
    value: true,
  },
];

export default ConsultationsFilters;
