import TranscribersEvents from 'components/Admin/Transcribers/TranscribersEvents';
import TranscribersTasks from 'components/Admin/Transcribers/TranscribersTasks';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

const TranscribersScreen = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <div className='row mx-auto main-content-wrapper main-content-settings'>
        <div className={`${isMobile ? 'w-100' : 'side-left-element'}`}>
          <div className='sidebar-nav settings-navigation pr-3 py-lg-4'>
            <h3 className='section-sidebar-subtitle pl-2 pl-lg-5 mb-3'>Transcribers</h3>
            <ul className='px-0 policy-list d-flex flex-row flex-lg-column admin-navbar'>
              <li className='flex-grow-1'>
                <NavLink
                  to={`/admin/transcribers/events`}
                  className={`admin-sidebar-item general-button w-100 text-left d-block px-2 pl-lg-5 sidebar-item setting-item d-flex`}
                >
                  <span className={`icon-container-calendar`}>
                    <span className={`political-updates-nav-icon icon-calendar`} />
                  </span>
                  <span>Events</span>
                </NavLink>
              </li>
              <li className='flex-grow-1'>
                <NavLink
                  to={`/admin/transcribers/tasks`}
                  className={`admin-sidebar-item general-button w-100 text-left d-block px-2 pl-lg-5 sidebar-item setting-item d-flex`}
                >
                  <span className={`icon-container-bot`}>
                    <span className={`political-updates-nav-icon icon-bot`} />
                  </span>
                  <span className='d-inline-block-centered centered-6'>Tasks</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className='px-0 main-content pr-xl-5 px-md-4 px-3'>
          <Routes>
            <Route path='/' element={<Navigate to={`/admin/transcribers/events`} />} />
            <Route path={`/events`} element={<TranscribersEvents />} />
            <Route path={`/tasks`} element={<TranscribersTasks />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default TranscribersScreen;
