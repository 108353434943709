import axios from 'axios';
import useTranscribersCalls from 'components/Admin/Transcribers/useTranscribersCalls';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

const TranscribersTasks = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);
  const { listTranscribers, transcribersActions } = useTranscribersCalls();
  const [heightContainer, containerRef] = useHeightContainer();

  const initialCall = useRef();
  initialCall.current = async (source) => {
    try {
      const results = await listTranscribers({ source });
      if (!!results) {
        setResults(
          results?.sort((a, b) => {
            return new Date(b.startedAt) - new Date(a.startedAt);
          })
        );
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    return () => {
      source.cancel('Admin transcribers cancelled by the user');
    };
  }, []);

  return (
    <>
      <div className='row mx-0 justify-content-between py-2 py-lg-4 align-items-center team-filters-container'>
        <div className='d-flex  align-items-center'>
          <h3 className='my-lg-0 main-title mb-0 mr-3 specific-y-padding-9'>Live transcribers - Running tasks</h3>
        </div>
      </div>
      <div className='box-top-rounded bg-white admin-general-content' ref={containerRef}>
        <CustomScrollbar
          className={'main-content-scrollbar content-page-scrollbar'}
          style={{ height: `${heightContainer}px` }}
          maximalThumbYSize={100}
        >
          <div>
            {isLoading ? (
              <p className='p-5'>Loading...</p>
            ) : (
              <>
                {results?.length === 0 ? (
                  <p className='text-center mt-5 pt-5 title-h5-m main-light-text'>No tasks found</p>
                ) : (
                  <>
                    <div className='px-3 py-2 border-bottom'>
                      <div className='row'>
                        <div className='col-3'>
                          <h3 className='title-h5-bold mb-0'>Started at</h3>
                        </div>
                        <div className='col-2'>
                          <h3 className='title-h5-bold mb-0'>Status</h3>
                        </div>
                        <div className='col-2'>
                          <h3 className='title-h5-bold mb-0'>Event Id</h3>
                        </div>
                        <div className='col-4'>
                          <h3 className='title-h5-bold mb-0'>Task id</h3>
                        </div>
                        <div className='col-3'>
                          <h3 className='title-h5-bold mb-0'>Event id / URL</h3>
                        </div>
                      </div>
                    </div>
                    <div className='px-3'>
                      {results?.map((item, index) => {
                        const { taskId, realtimeEventId, status, startedAt, parliamentEventId, streamUrl } = item;
                        return (
                          <div
                            className='row py-3 section-title align-items-center'
                            key={`transcriber-event-${taskId}`}
                          >
                            <div className='col-3'>
                              <p className='mb-0'>{dayjs(startedAt)?.format('YYYY-MM-DD HH:mm')}</p>
                            </div>
                            <div className='col-2'>
                              <p className='mb-0'>{status}</p>
                            </div>
                            <div className='col-2'>
                              <p className='mb-0'>{realtimeEventId}</p>
                            </div>
                            <div className='col-4'>
                              <p className={`mb-0 break-word`}>{taskId}</p>
                            </div>
                            <div className='col-3'>
                              <p className='mb-0 break-word'>{parliamentEventId ?? streamUrl ?? ''}</p>
                            </div>
                            <div className='col-2'>
                              <button
                                className='general-button danger-button px-4 py-1 mx-3'
                                onClick={async (e) => {
                                  e.target.disabled = true;
                                  const result = await transcribersActions({ action: 'stop', taskId: taskId });
                                  if (result?.status === 200) {
                                    let newResults = [...results];
                                    const position = newResults?.findIndex((item) => item?.taskId === taskId);
                                    newResults.splice(position, 1);
                                    setResults(newResults);
                                    createNotification('success', 'Transcriber stopped.');
                                  }
                                  e.target.disabled = false;
                                }}
                              >
                                Stop
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </CustomScrollbar>
      </div>
    </>
  );
};

export default TranscribersTasks;
