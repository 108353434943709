import React, { useContext, useEffect, useRef, useState } from 'react';
import { store } from 'components/Store';

import useReferralLinkApiCall from './useReferralLinkApiCall';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import returnCorrectWPUrl from 'components/Dashboard/utilities/returnCorrectWPUrl';

import Axios from 'axios';
import ModalComponent from 'components/Common/Modal';
import { currencySymbol } from 'utils/getAppSite';

const ContentPopup = ({ isParliamentarian }) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;
  const { myself } = team;
  const { getReferralLink, editReferralLinkEmail } = useReferralLinkApiCall();

  const [referralCode, setReferralCode] = useState(null);
  const [emailAddress, setEmailAddress] = useState(myself?.emailAddress);
  const [emailInputDisabled, setEmailInputDisabled] = useState(true);
  const [error, setError] = useState({});
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);

  const emailAddressRef = useRef(null);

  const validateForm = () => {
    let regEmail =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailAddress === '') {
      setError({ type: 'email', message: 'Please complete this field' });
      return false;
    } else if (!regEmail.test(emailAddress)) {
      setError({ type: 'email', message: 'Invalid email' });
      return false;
    }
    return true;
  };
  const asyncFn = useRef();
  asyncFn.current = async (source) => {
    try {
      const referralLink = await getReferralLink(source);
      setReferralCode(referralLink?.referralCode);
      setEmailAddress(referralLink?.paymentEmailAddress);
    } catch {}
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    asyncFn.current(source);
    return () => {
      source.cancel('Referral link cancelled by the user');
    };
  }, []);

  const handleEdit = async (e) => {
    if (emailInputDisabled) {
      setEmailInputDisabled(false);
      setTimeout(() => {
        emailAddressRef.current.focus();
      }, 10);
    } else {
      setDisabledSaveBtn(true);
      let validate = validateForm();
      if (validate) {
        let res = await editReferralLinkEmail(emailAddress);
        if (res) {
          setDisabledSaveBtn(false);
          setEmailInputDisabled(true);
        }
      }
    }
  };

  const handleChange = (e) => {
    setError({});
    setEmailAddress(e.target.value);
    setDisabledSaveBtn(false);
  };

  const handleCopy = (value) => {
    let tempInput = document.createElement('input');
    tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    createNotification('success', `Link copied`);
  };

  const Error = () => {
    return (
      <div className='error-message px-0 px-lg-2'>
        <img className='mr-3' src={`${process.env.REACT_APP_CDNURL}/images/warning-icon.svg`} alt='warning-icon' />
        <p className='mb-0 message py-2 px-3'>{error.message}</p>
      </div>
    );
  };

  const Title = () => {
    return (
      <h3 className='referral-link-header mb-0 section-title pb-1 pt-4 px-lg-5 pl-3 pr-5'>
        {isParliamentarian ? (
          <>
            <p className='mb-0 referral-link-title'>Share PolicyMogul with others</p>
            <p className='mb-0 referral-link-subtitle main-light-text'>
              Spread the word amongst other parliamentary teams and organisations
            </p>
          </>
        ) : (
          <>
            <p className='mb-0 referral-link-title'>
              Earn <span>{currencySymbol}300</span> for each referral
            </p>
            <p className='mb-0 referral-link-subtitle main-light-text'>
              When a contact uses your link to sign up for a paid account, you’ll get {currencySymbol}300
            </p>
          </>
        )}
      </h3>
    );
  };

  return (
    <div className='referral-link-modal'>
      <Title />
      {isParliamentarian ? (
        <>
          <div className='referral-link-unique-section px-lg-5 pl-3 pr-5 pb-4 pt-3'>
            <h4>Invite other parliamentary teams to PolicyMogul</h4>
            <p className='referral-parliament-description'>
              PolicyMogul’s free offering relies on a majority of parliamentarians and staff signing up to claim their
              account.{' '}
              <a
                className='referral-link'
                rel='noreferrer'
                href='http://support.policymogul.com/en/articles/6034419-faqs-for-parliamentarians'
                target='_blank'
              >
                Learn why.
              </a>
            </p>
            <div className='link-input pl-3 pr-1'>
              <span>{`${returnCorrectWPUrl()}/for-parliamentarians`}</span>
              <button
                className='copy-referral-link'
                disabled={!referralCode}
                onClick={() => handleCopy(`${returnCorrectWPUrl()}/for-parliamentarians`)}
              >
                Copy link
              </button>
            </div>
          </div>
          <div className='referral-link-invite-organisations-section px-lg-5 pl-3 pr-5 pb-5 pt-4'>
            <h4>Invite organisations to share their briefing material</h4>
            <p className='referral-parliament-description'>
              Organisations can share briefing material on PolicyInfluence, a free platform run by PolicyMogul. Their
              content will appear in the ‘Campaigns’ section of your PolicyMogul account.
            </p>
            <div className='link-input pl-3 pr-1 input-violet'>
              <span>{process.env.REACT_APP_POLICYINFLUENCE_APP_URL}</span>
              <button
                className='copy-referral-link'
                disabled={!referralCode}
                onClick={() => handleCopy(process.env.REACT_APP_POLICYINFLUENCE_APP_URL)}
              >
                Copy link
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='referral-link-unique-section px-lg-5 pl-3 pr-5 pb-4 pt-3'>
            <h4>Your unique referral link</h4>
            <div className='link-input pl-3 pr-1'>
              <span>
                {process.env.REACT_APP_APPURL}/r/
                {referralCode ? referralCode.toLowerCase() : ''}
              </span>
              <button
                className='copy-referral-link'
                disabled={!referralCode}
                onClick={() =>
                  handleCopy(`${process.env.REACT_APP_APPURL}/r/${referralCode ? referralCode.toLowerCase() : ''}`)
                }
              >
                Copy link
              </button>
            </div>
          </div>
          <div className='how-it-works-section px-lg-5 pl-3 pr-5 pb-4 pt-3'>
            <h4>How it works</h4>
            <div className='how-it-works-list'>
              <div className='how-it-works-list-item'>
                <span className='circle-marker'>1</span>{' '}
                <span className='how-it-works-list-item-text'>Share your unique referral link</span>
              </div>
              <div className='how-it-works-list-item'>
                <span className='circle-marker'>2</span>{' '}
                <span className='how-it-works-list-item-text'>Link is used to sign up for a paid subscription</span>
              </div>
              <div className='how-it-works-list-item'>
                <span className='circle-marker'>3</span>{' '}
                <span className='how-it-works-list-item-text'>
                  <strong>We’ll send a {currencySymbol}300 Amazon voucher to...</strong>
                </span>
              </div>
              <div className='how-it-works-email-input'>
                <input
                  type='text'
                  className='pl-3'
                  value={emailAddress}
                  disabled={emailInputDisabled}
                  ref={emailAddressRef}
                  onChange={handleChange}
                ></input>
                <button className='pr-3 referral-link-edit-btn' onClick={handleEdit} disabled={disabledSaveBtn}>
                  {emailInputDisabled ? 'Edit' : 'Save'}
                </button>
                {error.type === 'email' && <Error />}
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-start footer px-lg-5 pl-3 pr-5'>
            <a
              className='terms-and-conditions'
              rel='noreferrer'
              href='http://support.policymogul.com/en/articles/8185840-referral-scheme'
              target='_blank'
            >
              Terms and Conditions
            </a>
          </div>
        </>
      )}
    </div>
  );
};

const ReferralLinkModal = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <ModalComponent maxWidth={560} isOpen={showPopUp} setIsOpen={setShowPopup}>
      <ContentPopup {...props} />
    </ModalComponent>
  );
};

export default ReferralLinkModal;
