import BackButton from 'components/Dashboard/components/BackButton.js';
import { Outlet, useLocation } from 'react-router-dom';

const ContentRoutes = (props) => {
  const location = useLocation();
  const fullWidthPages = ['policy-asks', 'briefing-material', 'news-release', 'research'];
  const pathnames = [...fullWidthPages, 'stakeholders'];
  const detectDifferentLayout = ({ pathnames }) => {
    return pathnames.some((item) => location.pathname?.startsWith(`/${item}`));
  };
  const differentLayoutPage = detectDifferentLayout({ pathnames });

  return (
    <div
      className={`mx-auto w-100 ${detectDifferentLayout({ pathnames: fullWidthPages }) ? '' : 'main-content-wrapper'} ${
        location.pathname.includes('stakeholders') ? 'flex-grow-1' : 'main-content-wrapper-content'
      } position-relative`}
    >
      {!differentLayoutPage && <BackButton />}
      <div className={`h-100 ${differentLayoutPage ? '' : 'key-update-content pt-lg-5'}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default ContentRoutes;
