import axios from 'axios';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import useGeneralRequestProperties from 'components/Dashboard/hooks/useGeneralRequestProperties';
import useGetHighlightingKeywords from 'components/Dashboard/hooks/useGetHighlighingKeywords';
import SkeletonKeyUpdatesContent from 'components/Dashboard/KeyUpdates/SkeletonKeyUpdatesContent';
import useEventListFunctions, {
  useCallEventListItem,
} from 'components/Dashboard/LiveFromParliament/useEventListFunctions';
import ParliamentLiveEventContent from 'components/Dashboard/ParliamentLive/ParliamentLiveEventContent';
import useCallParliamentLive from 'components/Dashboard/ParliamentLive/useCallParliamentLive';
import useGetRealTimeEvents from 'components/Dashboard/ParliamentLive/useGetRealtimeEvents';
import ChangeKeywordsExclusions from 'components/Dashboard/utilities/changeKeywordExclusions';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { store } from 'components/Store';
import dayjs from 'dayjs';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

const ParliamentLiveEventView = () => {
  const globalState = useContext(store);
  const { state } = globalState;

  const { realtimeEvents, keywordsLists } = state ?? {};
  const topicParam = getUrlParam('topic-id');
  const searchParam = getUrlParam('search') || getUrlParam('or');

  const [isLoading, setIsLoading] = useState(true);
  const [hits, setHits] = useState([]);
  const [eventItem, setEventItem] = useState({});
  const [eventBodies, setEventBodies] = useState([]);
  const [initialDate, setInitialDate] = useState(null);
  const [parliamentLiveReferenceDates, setParliamentLiveReferenceDates] = useState({});
  const [localTopicId, setLocalTopicId] = useState(topicParam);

  const params = useParams();
  const { event } = params;

  const { callEvents } = useEventListFunctions();
  const { createRequestProperties } = useGeneralRequestProperties({ sectionName: 'ParliamentLive' });
  const { generalApiCall } = useGeneralApiCall();
  const { getRealTimeEvents, getRealTimeEvent } = useGetRealTimeEvents();
  const { queryHighlight, andKeywords } = useGetHighlightingKeywords();

  const callSpecificEvent = useRef();

  useCallEventListItem({
    condition: localTopicId === topicParam && !isLoading,
    eventId: event,
    data: eventBodies,
    setData: setEventBodies,
    date: initialDate,
  });

  useCallParliamentLive({
    parliamentLiveReferenceDates,
    condition: localTopicId === topicParam && !isLoading,
    eventApiCall: async ({ source, propsRequestProperties }) => {
      try {
        const results = await callEventSingle({ source, propsRequestProperties });
        if (!!results && results?.hits?.length) {
          setHits([...hits, ...results?.hits]);
        }
      } catch (error) {}
    },
  });

  const callEventSingle = async ({ source, singleEvent, propsRequestProperties, specificDate }) => {
    try {
      const directTopic = keywordsLists?.find((item) =>
        topicParam === 'all' ? item?.id === null : item?.id === parseInt(topicParam)
      );
      const { requestProperties, url, parliamentLiveReferenceDates } = createRequestProperties({
        specificDate,
        directTopic,
      });
      const propertiesToUse = propsRequestProperties ?? requestProperties;
      let finalRequestProperties = { ...propertiesToUse };
      finalRequestProperties = {
        ...finalRequestProperties,
        filters: [...finalRequestProperties?.filters, { field: 'eventId', value: `${event}`, operator: 'str_eq' }],
      };
      if (singleEvent) {
        finalRequestProperties = {
          ...finalRequestProperties,
          sort: { field: 'title', order: 'DESC' },
          collapseField: 'parliamentEventId',
          collapseSort: {
            field: 'dateTime',
            order: 'DESC',
          },
          doNotIncludeCollapsedHits: true,
          pageSize: 1,
        };
      } else {
        setParliamentLiveReferenceDates(parliamentLiveReferenceDates);
        finalRequestProperties = {
          ...finalRequestProperties,
          sort: { field: 'dateTime', order: 'ASC' },
          doNotIncludeCollapsedHits: false,
          pageSize: 10000,
        };
        delete finalRequestProperties.collapseField;
        delete finalRequestProperties.collapseSort;
      }
      return await generalApiCall({
        pathname: url,
        method: 'post',
        requestProperties: finalRequestProperties,
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
      });
    } catch (error) {}
  };

  callSpecificEvent.current = async (source) => {
    try {
      setIsLoading(true);
      setLocalTopicId(topicParam);
      if (!realtimeEvents) {
        await getRealTimeEvents({ source });
      }
      let initialDateEvent;
      const eventItem = await getRealTimeEvent({ source, eventId: event });
      if (!!eventItem) {
        const dateUK = dayjs().utc();
        initialDateEvent = dateUK.isSame(eventItem?.createdAtUtc, 'day') ? null : eventItem?.createdAtUtc;
        setEventItem(eventItem);
      }
      const events = await callEventSingle({ source, specificDate: initialDateEvent });
      if (!!events) {
        let resultHits = events?.hits;
        setHits(resultHits);
        const eventQuery = await callEvents({ source, eventId: event, setInitialDate, specificDate: initialDateEvent });
        if (!!eventQuery) {
          let eventBodies = eventQuery?.hits?.map((item) => ({ ...item, text: item.body }));
          setEventBodies(eventBodies);
          setIsLoading(false);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const liveItemHighlightApi = async ({ item, source }) => {
    try {
      const { objectID, type } = item ?? {};
      const pathname = topicParam ? '/api/search/highlight-content-by-keyword-list' : `/api/search/highlight-content`;
      let requestProperties = { objectId: objectID, objectType: type };
      if (topicParam) {
        requestProperties = {
          ...requestProperties,
          keywordListId: topicParam === 'all' ? null : parseInt(topicParam),
        };
      } else if (searchParam) {
        requestProperties = {
          ...requestProperties,
          query: queryHighlight,
          andQueries: ChangeKeywordsExclusions(andKeywords ?? []),
        };
      }
      return await generalApiCall({
        method: 'post',
        requestSource: source,
        requestProperties,
        pathname,
        needsAuthentication: true,
        notShowErrorMessage: true,
      });
    } catch (error) {}
  };

  const navigateRemarks = async ({ item, source }) => {
    try {
      const highlightItem = await liveItemHighlightApi({ item, source });
      if (highlightItem && !!highlightItem?.highlightedHtmlBody) {
        let newBodies = [...eventBodies];
        const position = newBodies?.findIndex((body) => body?.objectID === item?.objectID);
        if (position >= 0) {
          newBodies[position] = {
            ...newBodies[position],
            text: highlightItem?.highlightedHtmlBody,
          };
          setEventBodies(newBodies);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    callSpecificEvent.current(source);
    return () => {
      source.cancel('Events cancelled by user');
    };
  }, [topicParam]);

  return (
    <>
      {isLoading ? (
        <SkeletonKeyUpdatesContent />
      ) : (
        <ParliamentLiveEventContent
          navigateRemarks={navigateRemarks}
          eventBodiesState={{ eventBodies, setEventBodies }}
          hits={hits}
          eventItem={eventItem}
        />
      )}
    </>
  );
};

export default ParliamentLiveEventView;
