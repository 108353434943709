const getAppSite = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'PolicyMogulUSA':
      return 'usa';
    default:
      return 'global';
  }
};

const useSiteSectionsNames = () => {
  const names = [
    {
      global: 'Parliamentary record',
      usa: 'Congressional Record',
    },
    {
      global: 'Spoken parliamentary contributions',
      usa: 'Congressional Record contributions',
    },
    { global: 'Key updates', usa: 'News' },
  ];

  const getName = ({ property }) => {
    const item = names.find((item) => item?.global === property);
    return item?.[getAppSite()] ?? property;
  };

  return { getName };
};

const comprehensiveText = (props) => {
  const { uppercase } = props ?? {};
  return getAppSite() === 'usa'
    ? `${uppercase ? 'C' : 'c'}omprehensive`
    : `${uppercase ? 'T' : 't'}he UK’s most comprehensive`;
};

const currencySymbol = getAppSite() === 'usa' ? '$' : '£';

export { useSiteSectionsNames, comprehensiveText, currencySymbol };
export default getAppSite;
