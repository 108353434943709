import React, { useContext, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { store } from 'components/Store.js';
import Share from 'components/Dashboard/components/Share.js';
import { contentItemSearchQuery } from 'components/Dashboard/helpers/locationSearchDescriptor.js';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter.js';
import useRememberPosition from 'components/Dashboard/hooks/useRememberPosition.js';
import TagsLists from 'components/Dashboard/TagsList';
import calculateDifferenceBetweenDays from 'components/Dashboard/utilities/calculateDifferenceBetweenDays.js';
import getSearchWithinHash from 'components/Dashboard/utilities/getSearchWithinHash.js';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';
import normalizeString from 'components/Dashboard/utilities/normalizeString';
import ThumbsDown from 'components/Common/ThumbsDown.js';
import FeedbackOnItem from 'components/Dashboard/FeedbackItem/FeedbackOnItem.js';
import thousandCommas from 'components/Dashboard/helpers/thousandCommas';
import dayjs from 'dayjs';

const ConsultationItem = (props) => {
  const { isAll, item, index, includeTags, scrollBarRef, itemOnclick, embedShowTags, array, notShowShare } = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, contentResults, lastSeenMarkers, itemsViewed } = state;
  const { activeSearch, list } = search;
  const ConsultationSearch = contentResults['searchResults'].Consultations;
  const explanations =
    props.explanations ?? activeSearch ? (ConsultationSearch !== undefined ? ConsultationSearch.explanations : []) : [];
  const feedItemFlags =
    props.feedItemFlags ?? activeSearch
      ? ConsultationSearch !== undefined
        ? ConsultationSearch.feedItemFlags
        : []
      : [];
  const location = useLocation();
  const explanationsToRender = explanations ?? ConsultationSearch?.explanations;
  let locationSearch = contentItemSearchQuery(location, list);
  const { rememberPosition } = useRememberPosition();

  const params = useParams();
  const { inboxSection } = useMainSectionParameter(params);

  const decodeString = (string) => {
    let uri_enc = encodeURIComponent(string);
    let uri_dec = decodeURIComponent(uri_enc);
    return uri_dec;
  };

  const locationDescriptor = (path) => {
    if (getUrlParam('topic-id') === 'all' && inboxSection) {
      locationSearch = `?topic-id=${feedItemFlags?.[index]?.matchingKeywordLists[0]?.id}`;
    }
    return {
      pathname: path,
      search: `${locationSearch}`,
      hash: getSearchWithinHash() ? getSearchWithinHash(true) : '',
    };
  };

  const feedItem = feedItemFlags?.[index];
  const isBackpopulatedCondition = feedItem?.isBackpopulated === null || feedItem?.isBackpopulated === true;
  const unseenConditional =
    list !== null &&
    !isAll &&
    includeTags &&
    feedItemFlags?.length > 0 &&
    !!lastSeenMarkers['consultationFeedItemId'] &&
    lastSeenMarkers['consultationFeedItemId'] < feedItem?.feedItemId &&
    !itemsViewed.includes(item?.objectID) &&
    !isBackpopulatedCondition;
  const alreadyMarkedFeedback = feedItem?.ratingScored === 'Negative';

  const consultationItemContainer = useRef(null);
  const consultationItemTitle = useRef(null);
  const openCloseItem = (e) => {
    let element = consultationItemTitle.current;
    let targetElement = consultationItemContainer.current;
    let expanded = element.getAttribute('aria-expanded');
    targetElement.classList.remove('collapse');
    if (expanded === 'true') {
      targetElement.classList.add('overflow-hidden');
      targetElement.classList.remove('show');
      targetElement.style.height = '0px';
    } else {
      element.classList.remove('collapsed');
      targetElement.classList.add('collapsing');
      targetElement.style.height = `${targetElement.scrollHeight}px`;
    }
    setTimeout(() => {
      targetElement.classList.add('collapse');
      targetElement.classList.remove('collapsing');
      if (expanded === 'true') {
        element.classList.add('collapsed');
        element.setAttribute('aria-expanded', 'false');
        targetElement.classList.remove('overflow-hidden');
      } else {
        targetElement.classList.add('show');
        element.setAttribute('aria-expanded', 'true');
      }
    }, 350);
  };

  return (
    <>
      {isAll && (
        <div key={item.id} className='open-consultations-item my-2'>
          <div
            className={`heading p-4 heading-all-open-consultations collapsed`}
            ref={consultationItemTitle}
            aria-expanded={'false'}
            onClick={openCloseItem}
          >
            <h4 className='mb-2 pr-4 pr-sm-0'>{`${decodeString(item.title)}`}</h4>
            <p className='mb-0'>{`${item.consultations.length} open ${item.consultations.length === 1 ? 'consultation' : 'consultations'}`}</p>
            <img
              className='arrow-down'
              src={`${process.env.REACT_APP_CDNURL}/images/arrow-down.svg`}
              alt='arrow down'
            />
          </div>

          <div className={`collapse open-consultations-items`} ref={consultationItemContainer}>
            {item.consultations.map((consultation, index, array) => {
              return (
                <Consultation
                  consultation={consultation}
                  key={consultation.id}
                  isAll={isAll}
                  scrollBarRef={scrollBarRef}
                  array={array}
                  index={index}
                />
              );
            })}
          </div>
        </div>
      )}
      {!isAll && (
        <>
          <Link
            to={locationDescriptor(`/consultations/${item.id}/${normalizeString(item.title)}`)}
            className={`open-consultations-item-link content-item-link`}
            style={{ zIndex: array ? array.length - index : 1 }}
            onClick={(e) => {
              if (scrollBarRef) {
                rememberPosition('Consultations', scrollBarRef, index);
              }
              if (itemOnclick) {
                itemOnclick(e);
              }
            }}
          >
            <div className={`open-consultations-item my-2`}>
              <div className={`content-item heading collapsed consultation-item adjustment-unseen-dot`}>
                {unseenConditional && (
                  <>
                    <span className='unseen-dot' />
                  </>
                )}
                <div className='px-4 py-3'>
                  <div className='d-flex align-items-start'>
                    <div className='flex-grow-1 consultation-individual-content-width'>
                      <h4 className='mb-2 content-item-title'>{item.title}</h4>
                      <p className='mb-1 source-name heading-date information-date'>Source: {item.departmentName}</p>
                      <p
                        className={`heading-date closing-time information-date mb-0 ${calculateDifferenceBetweenDays(item.closeDate) > 7 ? 'more-seven' : 'below-seven'}`}
                      >
                        {`Opened: ${dayjs(item.openDate).format('DD MMM, YYYY')}`}
                        <span className='dot'></span>
                        {closedText(item)}
                      </p>
                      {((list !== null && activeSearch && includeTags) || embedShowTags) && (
                        <TagsLists
                          feedItemFlags={feedItemFlags}
                          explanations={explanationsToRender}
                          index={index}
                          title={'Consultation'}
                          section={'Consultations'}
                        />
                      )}
                    </div>
                    {!notShowShare && (
                      <div className='flex-centered'>
                        <ThumbsDown item={item} index={index} />
                        <Share item={item} listItem />
                      </div>
                    )}
                  </div>
                </div>
                {alreadyMarkedFeedback && <FeedbackOnItem item={item} index={index} />}
              </div>
            </div>
          </Link>
        </>
      )}
    </>
  );
};

const closedText = (item) => {
  if (calculateDifferenceBetweenDays(item.closeDate) === 0) {
    return 'Closing today';
  } else if (calculateDifferenceBetweenDays(item.closeDate) < 0) {
    return 'Closed';
  } else {
    return `Closing in: ${thousandCommas(calculateDifferenceBetweenDays(item.closeDate))} day${calculateDifferenceBetweenDays(item.closeDate) === 1 ? '' : 's'}`;
  }
};

const Consultation = (props) => {
  const { consultation, scrollBarRef, array, index } = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const { search } = state;
  const { list } = search;

  const location = useLocation();
  let locationSearch = contentItemSearchQuery(location, list);

  const locationDescriptor = (path) => {
    return {
      pathname: path,
      search: locationSearch,
      hash: getSearchWithinHash() ? getSearchWithinHash(true) : '',
    };
  };
  const { rememberPosition } = useRememberPosition();

  return (
    <Link
      to={locationDescriptor(`/consultations/${consultation.id}/${normalizeString(consultation.title)}`)}
      className='inside-consultation-container position-relative d-block'
      onClick={() => {
        rememberPosition('Consultations', scrollBarRef, index);
      }}
      style={{ zIndex: array ? array.length - index : 1 }}
    >
      <div className='pl-4 pr-2 py-3 open-consultation-content' key={consultation.id}>
        <div className='flex-centered mb-3'>
          <div className='flex-grow-1'>
            <p className='open-consultation-link mb-0 flex-grow-1'>{consultation.title}</p>
            <p
              className={`heading-date text-left closing-time open-consultation-closing-time information-date mb-0 ${calculateDifferenceBetweenDays(consultation.closeDate) > 7 ? 'more-seven' : 'below-seven'}`}
            >
              {`Opened: ${dayjs(consultation.openDate).format('dd MMM, YYYY')}`}
              <span className='dot'></span>
              {closedText(consultation)}
            </p>
          </div>
          <Share item={{ ...consultation, contentUrl: consultation.canonicalUrl }} listItem />
        </div>
        <div className='closing-time-container text-lg-right'></div>
      </div>
    </Link>
  );
};

export default ConsultationItem;
