import React, { useEffect, useContext } from 'react';
import { store, net_api_url } from 'components/Store.js';
import Loading from './Loading';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam.js';

const Register = ({ login }) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { app_url } = state;
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const emailFromUrl = getUrlParam('email');
  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/monitor-inbox${window.location.search}`);
    } else {
      let initialOptions = {
        authorizationParams: {
          redirect_uri: `${app_url}/login-success${window.location.search}`,
          netApiUrl: `${net_api_url}`,
          site: `${process.env.REACT_APP_SITE}`,
        },
      };
      if (login) {
        loginWithRedirect(initialOptions);
      } else {
        initialOptions = {
          fragment: 'register',
          ...initialOptions,
        };
        let options =
          emailFromUrl !== undefined
            ? {
                ...initialOptions,
                login_hint: decodeURIComponent(emailFromUrl),
              }
            : initialOptions;

        loginWithRedirect(options);
      }
    }
  }, [isAuthenticated]);
  return (
    <>
      <Loading />
    </>
  );
};

export default Register;
