import axios from 'axios';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import useGeneralRequestProperties from 'components/Dashboard/hooks/useGeneralRequestProperties';
import { useResearchItemsUrl } from 'components/Dashboard/Navigation/NavPages';
import createSectionViewStoreProperty from 'components/Dashboard/ParliamentLive/createSectionViewStoreProperty';
import Loading from 'components/Login/Loading';
import { store } from 'components/Store';
import { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const MonitorPage = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const navigate = useNavigate();

  const { createRequestProperties } = useGeneralRequestProperties({ sectionName: 'ParliamentLive' });
  const { generalApiCall } = useGeneralApiCall();
  const { monitorUrl } = useResearchItemsUrl();

  const initialValidation = useRef();
  initialValidation.current = async (source) => {
    try {
      const { url, requestProperties } = createRequestProperties({});
      const liveResults = await generalApiCall({
        pathname: url,
        requestProperties,
        method: 'post',
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
      });
      if (!!liveResults) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'sectionsView', value: createSectionViewStoreProperty() });
        navigate({
          pathname: `/monitor-inbox/${liveResults?.hits?.length > 0 ? 'parliament-live' : 'key-updates'}`,
          search: monitorUrl?.replace('/monitor-inbox', ''),
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialValidation.current(source);
    return () => {
      source.cancel('Monitor cancelled by the user');
    };
  }, []);

  return <Loading />;
};

export default MonitorPage;
