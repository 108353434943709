import axios from 'axios';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import TextGenerate from 'components/Common/TextGenerator';
import createMarkup from 'components/Dashboard/helpers/createMarkup';
import useTitleScrolledStyles from 'components/Dashboard/hooks/useTitleScrolledStyles';
import { ShareParliamentaryLiveContent } from 'components/Dashboard/ParliamentaryRecord/ShareParliamentaryContent';
import ParliamentaryLiveRemarksWidget from 'components/Dashboard/ParliamentLive/ParliamentaryLiveRemarksWidget';
import { ParliamentHeadingElement } from 'components/Dashboard/ParliamentLive/ParliamentLiveItem';
import ReferenceTopicWidget from 'components/Dashboard/SubNav/ReferenceTopicsWidget';
import ResearchPopUp from 'components/Dashboard/SubNav/ui/ResearchPopUp';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import { store } from 'components/Store';
import dayjs from 'dayjs';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ParliamentLiveEventContent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { realtimeEvents, activePlan, keywordsLists, referenceState } = state ?? {};

  const { enableAdvancedSearch } = activePlan;
  const { eventBodiesState, hits, navigateRemarks, eventItem } = props ?? {};
  const { eventBodies, setEventBodies } = eventBodiesState ?? {};

  const resultInformation = eventBodies?.[0];
  const realTimeEvent = realtimeEvents?.find(
    (item) => item?.parliamentEventId === resultInformation?.parliamentEventId
  );
  const conditionToShowGoToTranscriptionButton = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e ?? scrollBarRef?.current ?? {};
    return scrollTop < scrollHeight - clientHeight;
  };
  const inParameter = getUrlParam('in');
  const [openAIassistantChat, setOpenAIassistantChat] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(null);
  const [scrolling, setScrolling] = useState(false);
  const [reachedTheEnd, setReachedTheEnd] = useState(false);
  const [showGoToTranscriptionButton, setShowGoToTranscriptionButton] = useState(
    conditionToShowGoToTranscriptionButton()
  );
  const [transcriptionButtonPosition, setTranscriptionButtonPosition] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [keywordsSection, setKeywordsSection] = useState('keywords');
  const [animatedItem, setAnimatedItem] = useState(null);

  const [heightContainer, containerRef] = useHeightContainer();
  const [heightContainerRelatedContent, containerRefRelatedContent] = useHeightContainer();
  const [updatingContribution, setUpdatingContribution] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const scrollBarRef = useRef();
  const titleSection = useRef();
  const contentContainer = useRef();
  const goTranscriptionButton = useRef();
  const allowScrollBottom = useRef(!inParameter);

  const updateExtract = useRef();
  updateExtract.current = (source) => {
    if (!scrolling && hits?.length > 0) {
      const remarks = hits.map((item) => {
        const url = new URL(item?.contentUrl);
        const searchParams = new URLSearchParams(url?.search);
        return searchParams?.get('in');
      });

      const lastElement = hits[hits?.length - 1];
      const firstElement = hits[0];
      const elementToUse = eventItem?.eventHasEnded ? firstElement : lastElement;
      const url = new URL(elementToUse?.contentUrl);
      const searchParams = new URLSearchParams(url?.search);
      const timeParamElement = searchParams?.get('in');
      const timeId = inParameter ? decodeURIComponent(inParameter) : timeParamElement;
      const id = `event-${timeId}`;
      const idElement = document.getElementById(id);

      if (idElement && remarks?.includes(timeId)) {
        //Reset class
        const className = 'parliament-live-event-highlighted';
        const elements = document.getElementsByClassName(`${className}`);
        if (elements.length) {
          Array.from(elements).forEach((element) => element.classList.remove(className));
        }
        const item = document.getElementById(id);
        item.classList.add(className);
        const hitItem = hits?.find((hit) => {
          const url = new URL(hit?.contentUrl);
          const searchParams = new URLSearchParams(url?.search);
          const parameter = searchParams?.get('in');
          return parameter === timeId;
        });
        if (hitItem) {
          navigateRemarks({ item: hitItem, source });
        }
        setTimeout(() => {
          const position = item.offsetTop - 140;
          if (scrollBarRef && scrollBarRef.current) {
            setUpdatingContribution(true);
            scrollBarRef.current.scrollTop(position);
          }
        }, 1);
        const finalSearchParams = new URLSearchParams(location?.search);
        finalSearchParams.set('in', encodeURIComponent(timeId));
        navigate({ search: finalSearchParams.toString() }, { replace: true });
        setTimeout(() => {
          setUpdatingContribution(false);
        }, 150);
      }
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    updateExtract.current(source);
    return () => {
      source.cancel('Highlight remark cancelled by the user');
    };
  }, [location.search]);

  useEffect(() => {
    if (contentContainer?.current) {
      setTranscriptionButtonPosition(
        contentContainer.current?.getBoundingClientRect()?.left +
          (contentContainer.current?.getBoundingClientRect()?.width -
            goTranscriptionButton?.current?.getBoundingClientRect()?.width) /
            2
      );
    }
  }, [contentContainer?.current]);

  const scrollToBottom = () => {
    if (allowScrollBottom.current && !scrolling) {
      setTimeout(
        () =>
          scrollBarRef?.current?.view?.scroll({
            top: scrollBarRef?.current?.view?.scrollHeight,
            behavior: 'smooth',
          }),
        10
      );
    }
  };

  const axiosCancelToken = useRef(null);
  const eventHighlightedTime = useRef(null);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e;
    const scrolledToBottom = scrollTop === scrollHeight - clientHeight;
    setReachedTheEnd(endedEvent ? scrolledToBottom : false);
    setScrollPosition(e.scrollTop);
    setShowGoToTranscriptionButton(conditionToShowGoToTranscriptionButton(e));
    allowScrollBottom.current = scrolledToBottom;

    if (hits?.length > 0) {
      const remarks = hits.map((item) => {
        const url = new URL(item?.contentUrl);
        const searchParams = new URLSearchParams(url?.search);
        return searchParams?.get('in');
      });

      const elements = document.querySelectorAll('.parliament-live-event');
      for (let i = 0; i < elements.length; i++) {
        let el = elements[i];
        const { top } = el.getBoundingClientRect();
        const value = contentContainer.current.getBoundingClientRect().top;
        if (top >= value && top < value + 100) {
          const inParameter = el.getAttribute('id')?.replace('event-', '');
          if (
            inParameter &&
            remarks.includes(inParameter) &&
            !updatingContribution &&
            eventHighlightedTime?.current !== inParameter
          ) {
            const className = 'parliament-live-event-highlighted';
            let currentElement = document.getElementsByClassName(`${className}`)[0];
            if (currentElement) {
              currentElement.classList.remove(className);
            }
            el.classList.add(className);
            const finalSearchParams = new URLSearchParams(location?.search);
            finalSearchParams.set('in', encodeURIComponent(inParameter));
            navigate(
              {
                search: finalSearchParams?.toString(),
              },
              { replace: true }
            );
            if (axiosCancelToken.current) {
              axiosCancelToken.current.cancel();
            }
            axiosCancelToken.current = axios.CancelToken.source();
            const hitItem = hits?.find((hit) => {
              const url = new URL(hit?.contentUrl);
              const searchParams = new URLSearchParams(url?.search);
              const parameter = searchParams?.get('in');
              return parameter === inParameter;
            });
            navigateRemarks({ item: hitItem, source: axiosCancelToken.current });
            eventHighlightedTime.current = inParameter;
            break;
          }
        }
      }
    }
  };

  const { showFixedTitle, titleStyles } = useTitleScrolledStyles(contentContainer, titleSection);
  const endedEvent = !dayjs().isSame(dayjs(realTimeEvent?.createdAtUtc), 'day') && !getUrlParam('startDateTime');
  const topFadeCondition = scrollPosition > 20 && realTimeEvent?.actualStartDateTime;
  const bottomFadeCondition =
    scrollPosition + heightContainer < scrollBarRef?.current?.view?.scrollHeight - 50 &&
    realTimeEvent?.actualStartDateTime;

  const eventBodiesRef = useRef(eventBodies);

  const handleFinish = () => {
    let newEventBodies = [...eventBodiesRef.current];
    const currentItemPosition = newEventBodies.findIndex((item) => item?.objectID === animatedItem?.objectID);
    if (currentItemPosition >= 0) {
      newEventBodies[currentItemPosition] = { ...newEventBodies[currentItemPosition], notShown: false };
      const notShowItems = newEventBodies?.filter((item) => item?.notShown);
      setAnimatedItem(notShowItems?.length > 0 ? notShowItems[0] : null);
    }
    setEventBodies(newEventBodies);
  };

  useEffect(() => {
    const notShownItems = eventBodies?.filter((item) => item?.notShown);
    eventBodiesRef.current = eventBodies;
    if (!animatedItem && notShownItems?.length > 0) {
      setAnimatedItem(notShownItems[0]);
    }
  }, [eventBodies]);

  return (
    <>
      <div className='row px-0 piece-content-container'>
        <div className='general-content-column padding-to-nav p-0 z-100'>
          <div ref={contentContainer}>
            <div
              className='main-content-section content-container'
              ref={containerRef}
              style={{ height: `${heightContainer}px` }}
            >
              <CustomScrollbar
                ref={scrollBarRef}
                className={`simple-scrollbar indented-scrollbar-small ${topFadeCondition ? 'scrollbar-top-fade' : ''} ${bottomFadeCondition ? 'scrollbar-bottom-fade' : ''}`}
                maximalThumbYSize={100}
                onScroll={(e) => {
                  handleScroll(e);
                }}
                onScrollStart={() => {
                  setScrolling(true);
                }}
                onScrollStop={() => {
                  setScrolling(false);
                }}
              >
                <div className='main-update-content-container'>
                  <div
                    className={`section-title pb-3 keyupdate-content-title content-title-with-summary content-border-title`}
                    ref={titleSection}
                  >
                    <div className='d-flex align-items-lg-center justify-content-between'>
                      <div className='pr-lg-5 pr-3 content-new-title flex-grow-1'>
                        <h3 className='main-title news-title pr-lg-4 mb-0'>{resultInformation?.title}</h3>
                        <ParliamentHeadingElement item={resultInformation} />
                      </div>
                    </div>
                  </div>
                  {/* FIXED TITLE */}
                  <div
                    className={`content-item-scrolled-title section-title bg-white ${showFixedTitle ? 'item-scrolled-title-visible' : ''}`}
                    style={titleStyles}
                  >
                    <div className='d-flex justify-content-between scrolled-title-container'>
                      <h3 className='main-title news-title pr-lg-4'>{resultInformation?.title}</h3>
                    </div>
                  </div>
                  {/* END FIXED TITLE */}
                  <div className='py-4'>
                    <>
                      {(() => {
                        return eventBodies
                          .filter((item) => !item.notShown && item.text?.trim())
                          .map((item, index, arr) => {
                            const url = new URL(item?.contentUrl);
                            const searchParams = new URLSearchParams(url?.search);
                            const timeParam = searchParams?.get('in');
                            const relevantRemark = hits?.find((hit) => hit?.contentUrl === item?.contentUrl);

                            const currentTime = dayjs(timeParam, 'HH:mm:ss');
                            const currentMinute = currentTime.format('HH:mm');
                            let showTime = true;

                            if (index > 0) {
                              const prevItem = arr[index - 1];
                              const prevUrl = new URL(prevItem?.contentUrl);
                              const prevParams = new URLSearchParams(prevUrl?.search);
                              const prevTimeParam = prevParams?.get('in');
                              const prevTime = dayjs(prevTimeParam, 'HH:mm:ss');
                              const prevMinute = prevTime.format('HH:mm');

                              showTime = currentMinute !== prevMinute;
                            }

                            return (
                              <React.Fragment key={`body-${index}`}>
                                <div
                                  className={`reset-link text-main-blue px-4 d-block parliament-live-event ${relevantRemark ? 'parliament-live-event-relevant specific-top-padding-28 py-3' : 'py-1'}`}
                                  id={`event-${timeParam}`}
                                  onClick={() => {
                                    const selectedText = window.getSelection().toString().trim(); // Get selected text
                                    if (!selectedText) {
                                      window.open(item?.contentUrl, '_blank', 'noopener,noreferrer');
                                    }
                                  }}
                                >
                                  <ShareParliamentaryLiveContent
                                    item={item}
                                    additionalClass={'parliament-live-share-buttons border rounded-pill'}
                                  />
                                  {showTime && (
                                    <p
                                      className={`${relevantRemark ? '' : 'specific-top-margin-6'} mb-1 flex-centered main-light-text paragraph-p5`}
                                    >
                                      <span className='icon-clock mr-1' />
                                      <span>{dayjs(item?.dateTime)?.format('HH:mm')}</span>
                                    </p>
                                  )}
                                  <p
                                    className='paragraph-p1 line-height-1-5 mb-0'
                                    dangerouslySetInnerHTML={createMarkup(item.text)}
                                  />
                                </div>
                              </React.Fragment>
                            );
                          });
                      })()}
                      {!!animatedItem && (
                        <a
                          href={animatedItem?.contentUrl}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='reset-link text-main-blue px-4 py-2 d-block parliament-live-event'
                        >
                          <p className='paragraph-p1 line-height-1-5 mb-5'>
                            <TextGenerate
                              key={animatedItem?.objectID}
                              onFinish={handleFinish}
                              onCharacterAdd={(i) => {
                                if (i % 10 === 0) scrollToBottom();
                              }}
                            >
                              {animatedItem?.text}
                            </TextGenerate>
                          </p>
                        </a>
                      )}
                    </>
                    {eventItem?.transcriberRunning && <div style={{ height: 200 }} />}
                  </div>
                  <button
                    ref={goTranscriptionButton}
                    className='rounded-pill bg-main-blue px-4 py-2 general-button position-fixed text-white z-max go-to-live-transcription'
                    style={{
                      visibility: showGoToTranscriptionButton ? `visible` : 'hidden',
                      opacity: showGoToTranscriptionButton ? 1 : 0,
                      bottom: '20px',
                      left: `${transcriptionButtonPosition}px`,
                    }}
                    onClick={() => {
                      setShowGoToTranscriptionButton(false);
                      scrollBarRef?.current?.view?.scroll({
                        top: scrollBarRef?.current?.view?.scrollHeight,
                        behavior: 'smooth',
                      });
                      allowScrollBottom.current = true;
                    }}
                  >
                    <span className='dropdown-item-element dropdown-item-element-11'>
                      Go to {eventItem?.eventHasEnded ? 'end of transcription' : 'live transcription'}
                    </span>
                  </button>
                </div>
              </CustomScrollbar>
            </div>
          </div>
        </div>
        <div className='related-content-column show-lg px-0'>
          <div className={`related-content ${openAIassistantChat ? 'related-content-open-assistant' : ''}`}>
            <div ref={containerRefRelatedContent} />
            <CustomScrollbar
              className={`simple-scrollbar related-content-scroll`}
              style={{ height: `${heightContainerRelatedContent}px` }}
              maximalThumbYSize={100}
            >
              {/* <AIassistantWidget
              openAIassistantChatState={{ openAIassistantChat, setOpenAIassistantChat }}
              heightContainer={heightContainerRelatedContent}
              data={resultInformation ?? {}}
            /> */}
              <div className='box-outstanding bg-white box-rounded mb-4 parliamentary-widget'>
                {enableAdvancedSearch && (
                  <div className='p-3 border-bottom'>
                    <ReferenceTopicWidget
                      keywordsLists={keywordsLists}
                      setShowPopup={setShowPopup}
                      keywordsSection={keywordsSection}
                      setKeywordsSection={setKeywordsSection}
                      includeMasterTopic={true}
                      setOpenAIassistantChat={setOpenAIassistantChat} //AE: Temporary code for removing warning - delete after final decision about ai assistant
                    />
                  </div>
                )}
                {hits?.length > 0 ? (
                  <ParliamentaryLiveRemarksWidget reachedTheEnd={reachedTheEnd} hits={hits} />
                ) : (
                  <div className='pl-3 py-3'>
                    <p className='mb-0'>No relevant remarks</p>
                  </div>
                )}
              </div>
            </CustomScrollbar>
          </div>
        </div>
      </div>
      <ResearchPopUp
        keywordsLists={keywordsLists.filter((item) => item.id !== null)}
        setShowPopup={setShowPopup}
        showPopUp={showPopup}
        keywordsSection={keywordsSection}
        referenceState={referenceState}
      />
    </>
  );
};

/* 
endedEvent ? (
<div className='h-100 flex-centered justify-content-center'>
  <div>
    <p className='text-center title-h4'>
      <span className='icon-record mr-2 color-main-yellow d-inline-block-centered centered-2'></span>
      <span className='light-60-text'>This event was transcribed in real time</span>
    </p>
  </div>
</div>
*/

export default ParliamentLiveEventContent;
