import { useContext, useEffect, useState } from 'react';
import { store } from 'components/Store';
import { useMainSectionParameter } from 'components/Dashboard/helpers/useMainSectionParameter';
import { useLocation, useParams } from 'react-router-dom';
import normalize from 'components/Dashboard/utilities/normalizeString';
import LocalStorage from 'utils/LocalStorage';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Portal } from 'react-portal';

const SpecificViewElement = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search } = state;
  const { activeSearch } = search;

  const params = useParams();
  const location = useLocation();
  const { searchResultsSection } = useMainSectionParameter(params);
  const { viewName, tick, forceStartOfUpdate, showInPopup } = props;

  const sections = [
    {
      name: 'Snippet displayed',
      tooltip: `Switch between seeing ${searchResultsSection && !activeSearch ? 'start of update' : 'a relevant extract'} or a one-line AI-written summary. This text appears under the headline of each key update in the list.`,
      options: [
        {
          name: 'Most relevant extract',
          label: forceStartOfUpdate
            ? `Show ${searchResultsSection && !activeSearch ? 'start of update' : 'most relevant extract'}`
            : searchResultsSection && !activeSearch
              ? 'Start of update'
              : null,
        },
        { name: 'One-line AI summary', label: forceStartOfUpdate ? `Show one-line AI summary` : null },
      ],
      stateProperty: 'selectedOptionRelevantExtract',
    },
    {
      name: 'View',
      tooltip: `Switch between seeing relevant remarks, and the events they belong to`,
      options: [
        { name: 'Relevant remarks', disabled: location.pathname.match(/^\/?monitor-inbox\/parliament-live\/\d+\/?$/) },
        { name: 'Relevant events', disabled: location.pathname.match(/^\/?monitor-inbox\/parliament-live\/\d+\/?$/) },
      ],
      stateProperty: 'selectedOptionParliamentLiveView',
    },
  ];

  const element = sections?.find((item) => item?.name === viewName);
  const { name, tooltip } = element ?? {};
  return (
    <>
      {!!element && (
        <div className={`${showInPopup ? '' : 'border rounded bg-main-white mb-2 outside-filters-spacing-container'} `}>
          <div className={`flex-centered justify-content-between ${showInPopup ? '' : 'mb-3'}`}>
            <h3 className={`title-h5-bold d-block mb-0`}>{name}</h3>
            {!showInPopup && (
              <span
                className='icon-info ml-2 main-subtle-text paragraph-p2'
                data-tooltip-content={tooltip}
                data-tooltip-id={'view-tooltip'}
              />
            )}
          </div>

          <RelevantOptions element={element} tick={tick} />
        </div>
      )}
      <Portal>
        <ReactTooltip
          effect={'solid'}
          id={'view-tooltip'}
          className={'header-tooltip tooltip-width-280'}
          arrowColor={'transparent'}
          place={'top'}
        />
      </Portal>
    </>
  );
};

const RelevantOptions = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { sectionsView } = state;
  const { element, tick } = props;
  const { options, stateProperty } = element ?? {};

  const [optionSelected, setOptionSelected] = useState(sectionsView[stateProperty]);

  useEffect(() => {
    setOptionSelected(sectionsView[stateProperty]);
  }, [stateProperty]);

  return (
    <>
      {options?.map((item, index) => {
        const { name, label, disabled } = item;
        return (
          <div
            key={`item-${index}`}
            className={`${tick ? 'tick-radio-button px-3 py-2' : `mb-${index === options?.length - 1 ? '2' : '3'}`} radio-button relevant-extract-option ${disabled ? 'radio-button-disabled' : ''}`}
          >
            <input
              id={normalize(name)}
              onChange={() => {
                setOptionSelected(name);
                const newSectionsView = {
                  ...sectionsView,
                  [stateProperty]: name,
                };
                LocalStorage.setItem('sectionsView', JSON.stringify(newSectionsView));
                dispatch({
                  type: 'MODIFY_SECTION',
                  parameter: 'sectionsView',
                  value: newSectionsView,
                });
              }}
              disabled={disabled}
              type={'radio'}
              checked={optionSelected === name}
            />
            <label htmlFor={normalize(name)}>{label ?? name}</label>
            {optionSelected === name && tick && <span className='icon-tick'></span>}
          </div>
        );
      })}
    </>
  );
};

export { RelevantOptions, SpecificViewElement };
