import axios from 'axios';
import AddNewEventForm from 'components/Admin/Transcribers/AddNewEventForm';
import TranscriberDatePicker from 'components/Admin/Transcribers/TranscriberDatePicker';
import { TranscriberDeletePopup } from 'components/Admin/Transcribers/TranscribersPopups';
import useTranscribersCalls from 'components/Admin/Transcribers/useTranscribersCalls';
import CustomScrollbar from 'components/Common/CustomScrollbar';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import createNotification from 'components/Settings/Utilities/CreateNotification';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const TranscribersEvents = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);

  const { getEvents } = useTranscribersCalls();
  const [heightContainer, containerRef] = useHeightContainer();
  const { transcribersActions, updateEvent, deleteEvent } = useTranscribersCalls();

  const navigate = useNavigate();
  const dateParameter = getUrlParam('date');

  const initialCall = useRef();
  initialCall.current = async (source) => {
    try {
      setIsLoading(true);
      const results = await getEvents({ source, date: dateParameter });
      if (!!results) {
        setResults(
          results?.sort((a, b) => {
            return new Date(b.actualStartDateTime) - new Date(a.actualStartDateTime);
          })
        );
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    return () => {
      source.cancel('Admin events cancelled by the user');
    };
  }, [dateParameter]);

  const daySelectFunction = ({ day }) => {
    navigate({ search: `date=${dayjs(day)?.format('YYYY-MM-DD')}` });
  };

  const createOrUpdateEvent = async ({ event }) => {
    try {
      const result = await updateEvent({ event });
      if (!!result) {
        let newResults = [...results];
        if (!!event?.id) {
          const position = newResults.findIndex((item) => item.id === event?.id);
          if (position >= 0) {
            newResults[position] = result;
          }
        } else {
          newResults = [...newResults, result];
        }
        setResults(newResults);
        return true;
      }
    } catch (error) {}
  };

  const deleteSpecificEvent = async ({ eventId }) => {
    try {
      const result = await deleteEvent({ eventId });
      if (result?.status === 200) {
        let newResults = [...results];
        const position = newResults.findIndex((item) => item.id === eventId);
        if (position >= 0) {
          newResults.splice(position, 1);
        }
        setResults(newResults);
        return true;
      }
    } catch (error) {}
  };

  return (
    <>
      <div className='row mx-0 justify-content-between pt-2 pt-lg-4 align-items-center team-filters-container'>
        <div className='d-flex  align-items-center'>
          <h3 className='my-lg-0 main-title mb-0 mr-3'>Live transcribers - Events</h3>
        </div>
        <div className='flex-centered mx-0 '>
          <TranscriberDatePicker daySelectFunction={daySelectFunction} date={dateParameter ?? new Date()} />
          <button
            className='action-button general-button px-3 filter-action-button nowrap-item'
            onClick={() => setIsOpen(true)}
          >
            Add new event
          </button>
        </div>
      </div>

      <p className='paragraph-p2 main-light-text pb-2 pb-lg-4 mb-0'>
        Starting / restarting a transcriber can take around two minutes to complete.
      </p>
      <div className='box-top-rounded bg-white admin-general-content' ref={containerRef}>
        <CustomScrollbar
          className={'main-content-scrollbar content-page-scrollbar'}
          style={{ height: `${heightContainer}px` }}
          maximalThumbYSize={100}
        >
          <div>
            {isLoading ? (
              <p className='p-5'>Loading...</p>
            ) : (
              <>
                {results?.length === 0 ? (
                  <p className='text-center mt-5 pt-5 title-h5-m main-light-text'>No events found</p>
                ) : (
                  <>
                    <div className='px-3 py-2 border-bottom'>
                      <div className='row'>
                        <div className='col-1'>
                          <h3 className='title-h5-bold mb-0'>Start</h3>
                        </div>
                        <div className='col-1'>
                          <h3 className='title-h5-bold mb-0'>End</h3>
                        </div>
                        <div className='col-4'>
                          <h3 className='title-h5-bold mb-0'>Title</h3>
                        </div>
                        <div className='col-1'>
                          <h3 className='title-h5-bold mb-0'>Running</h3>
                        </div>
                        <div className='col-1'>
                          <h3 className='title-h5-bold mb-0'>Ended</h3>
                        </div>
                        <div className='col-2'>
                          <h3 className='title-h5-bold mb-0'>Last utterance</h3>
                        </div>
                        <div className='col-1'>
                          <h3 className='title-h5-bold mb-0'>Updated</h3>
                        </div>
                      </div>
                    </div>
                    <div className='px-3'>
                      {results?.map((item, index) => {
                        const {
                          id,
                          title,
                          actualStartDateTime,
                          actualEndDateTime,
                          eventHasEnded,
                          transcriberRunning,
                          latestUtteranceDateTime,
                          updatedAtUtc,
                          transcriberTaskId,
                        } = item;
                        return (
                          <div
                            className='row py-3 section-title align-items-center'
                            key={`transcriber-event-${title}-${index}`}
                          >
                            <div className='col-1'>
                              <p className='mb-0'>
                                {actualStartDateTime ? dayjs(actualStartDateTime)?.format('HH:mm') : '—'}
                              </p>
                            </div>
                            <div className='col-1'>
                              <p className='mb-0'>
                                {actualEndDateTime ? dayjs(actualEndDateTime)?.format('HH:mm') : '—'}
                              </p>
                            </div>
                            <div className='col-4'>
                              <p className='mb-0'>{title}</p>
                              <Link to={'/admin/transcribers/tasks'}>
                                <p className='mb-0 break-word main-light-text paragraph-p4 specific-top-margin-4'>
                                  Event id: {id}
                                </p>
                              </Link>
                            </div>
                            <div className='col-1'>
                              <p
                                className={`mb-0 text-center w-100 text-uppercase font-weight-bold main-light-text ${transcriberRunning ? 'bg-green-10' : 'bg-grey'}`}
                              >
                                {transcriberRunning ? 'yes' : 'no'}
                              </p>
                            </div>
                            <div className='col-1'>
                              <p className='mb-0'>{eventHasEnded ? 'YES' : 'NO'}</p>
                            </div>
                            <div className='col-2'>
                              <p className='mb-0'>
                                {latestUtteranceDateTime ? dayjs(latestUtteranceDateTime)?.format('HH:mm') : '—'}
                              </p>
                            </div>
                            <div className='col-1'>
                              <p className='mb-0'>{updatedAtUtc ? dayjs(updatedAtUtc)?.format('HH:mm') : '—'}</p>
                            </div>
                            <div className='col-4 d-flex'>
                              {transcriberRunning ? (
                                <button
                                  className='general-button danger-button px-4 py-1 mx-3'
                                  onClick={async (e) => {
                                    e.target.disabled = true;
                                    const result = await transcribersActions({
                                      action: 'stop',
                                      taskId: transcriberTaskId,
                                    });
                                    if (result?.status === 200) {
                                      let newResults = [...results];
                                      const position = newResults?.findIndex((item) => item?.id === id);
                                      newResults[position] = {
                                        ...newResults[position],
                                        transcriberRunning: false,
                                        eventHasEnded: true,
                                      };
                                      setResults(newResults);
                                      createNotification('success', 'Transcriber stopped.');
                                    }
                                    e.target.disabled = false;
                                  }}
                                >
                                  Stop
                                </button>
                              ) : (
                                <button
                                  className='general-button action-button px-4 py-1 mx-3'
                                  onClick={async (e) => {
                                    e.target.disabled = true;
                                    const result = await transcribersActions({ action: 'start', eventId: id });
                                    if (result?.status === 200) {
                                      let newResults = [...results];
                                      const position = newResults?.findIndex((item) => item?.id === id);
                                      newResults[position] = {
                                        ...newResults[position],
                                        transcriberRunning: true,
                                        eventHasEnded: false,
                                      };
                                      setResults(newResults);
                                      createNotification('success', 'Transcriber started.');
                                    }
                                    e.target.disabled = false;
                                  }}
                                >
                                  Start
                                </button>
                              )}
                              <button
                                className='general-button action-button px-4 py-1'
                                disabled={!transcriberRunning}
                                onClick={async (e) => {
                                  e.target.disabled = true;
                                  const result = await transcribersActions({ action: 'restart', eventId: id });
                                  if (result?.status === 200) {
                                    createNotification('success', 'Transcriber restarted.');
                                  }
                                  e.target.disabled = false;
                                }}
                              >
                                Restart
                              </button>
                              <button
                                className='general-button mx-3 main-subtle-text'
                                data-tooltip-id={'settings-tooltip'}
                                data-tooltip-content={'Edit event'}
                                data-tooltip-place={'top'}
                                onClick={() => {
                                  setEventId(id);
                                  setIsOpen(true);
                                }}
                              >
                                <span className='icon-edit-pencil-thick' />
                              </button>
                              <button
                                className='general-button text-main-danger'
                                data-tooltip-id={'settings-tooltip'}
                                data-tooltip-content={'Delete event'}
                                data-tooltip-place={'top'}
                                onClick={() => {
                                  setEventId(id);
                                  setIsOpenDeletePopup(true);
                                }}
                              >
                                <span className='icon-delete' />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </CustomScrollbar>
      </div>
      <AddNewEventForm
        createOrUpdateEvent={createOrUpdateEvent}
        openState={{ isOpen, setIsOpen }}
        eventIdState={{ eventId, setEventId }}
      />
      <TranscriberDeletePopup
        openState={{ isOpen: isOpenDeletePopup, setIsOpen: setIsOpenDeletePopup }}
        eventIdState={{ eventId, setEventId }}
        deleteSpecificEvent={deleteSpecificEvent}
      />
    </>
  );
};

export default TranscribersEvents;
