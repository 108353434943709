import { useContext, useEffect, useRef, useState } from 'react';
import { store } from 'components/Store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Portal } from 'react-portal';
import useSearchBarUiFunctions from 'components/Dashboard/hooks/useSearchBarUiFunctions';
import useIsMobile from 'components/Dashboard/utilities/useIsMobile';
import useOutsideHoverElement from 'components/Dashboard/hooks/useOutsideHoverElement';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import checkEllipsis from 'components/Dashboard/utilities/checkEllipsis';
import ColorThief from 'colorthief/dist/color-thief.umd.js';
import useChangeClient from './useChangeClient';

const ClientsPicker = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { team, clients, changesOnReports, targetClient } = state;

  const { additionalClass, dropdownAdditionalClass } = props;
  const [activeClient, setActiveClient] = useState(team);

  const location = useLocation();
  const navigate = useNavigate();
  const { changeClientFunction } = useChangeClient();

  const selectClientFunction = async ({ client, teamId, colorData }) => {
    if (targetClient === null) {
      const currentTeam = teamId === activeClient?.teamId;
      const reportsCondition = location.pathname?.startsWith('/reports') && !!changesOnReports;
      if (reportsCondition) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'clientToSwitchOnReports', value: client });
      } else {
        if (!currentTeam) {
          await changeClientFunction({ clientId: teamId });
          setActiveClient(client);
          const query = new URLSearchParams(window.location.search);
          query.delete('search');
          navigate({ search: query.toString() }, { replace: true });
        }
      }
    }
  };

  useEffect(() => {
    setActiveClient(team);
  }, [team]);

  return (
    <>
      {Object.keys(team)?.length === 0 ? (
        <div className='mr-3'>
          <SkeletonTheme baseColor='#f2f2f2'>
            <Skeleton height={38} width={277} style={{ borderRadius: '20px' }} />
          </SkeletonTheme>
        </div>
      ) : (
        <>
          <ComponentClientSelector
            selectClientFunction={selectClientFunction}
            activeClientState={{ activeClient, setActiveClient }}
            clientsLists={clients}
            notShowActions={true}
            additionalClass={additionalClass}
            dropdownAdditionalClass={dropdownAdditionalClass}
          />
        </>
      )}
    </>
  );
};

const ComponentClientSelector = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { maxListsPerTeam } = activePlan;

  const {
    clientsLists,
    activeClientState,
    selectClientFunction,
    restrictOverlimitClients,
    notShowActions,
    additionalClass = '',
    dropdownAdditionalClass = '',
    maximumZIndex = false,
  } = props;
  const { activeClient } = activeClientState;

  const [showDropdownContent, setShowDropdownContent] = useState(false);

  const isMobile = useIsMobile();

  const topicContainer = useRef(null);
  const dropdownElement = useRef(null);
  const scrollContainerElement = useRef(null);
  const keywordListsElement = useRef(null);
  const topicsListElement = useRef(null);

  const positionStyles = () => {
    let { top, left, width, height } = topicContainer.current ? topicContainer.current.getBoundingClientRect() : {};
    return {
      position: 'fixed',
      top: top ? top + height : 0,
      left,
      width,
    };
  };

  const { startMoveLists, listPosition, setStartMoveLists, setListPosition, scrollList } = useSearchBarUiFunctions({
    keywordListsElement,
    scrollContainerElement,
    topicsListElement,
    showDropdownContent,
  });

  const unselectClient = () => {
    setStartMoveLists(false);
  };
  useOutsideHoverElement(topicsListElement, unselectClient);

  const { myKeywordLists, teamMemberKeywordLists } = activeClient ?? {};
  const listsForActiveClient =
    !!myKeywordLists && !!teamMemberKeywordLists ? [...myKeywordLists, ...teamMemberKeywordLists] : [];
  const addNewTopicConditionForActiveClient =
    maxListsPerTeam === null || listsForActiveClient?.length < maxListsPerTeam;
  //const allowAddClients = maxNumberOfTeams === null || clients?.length < maxNumberOfTeams;

  return (
    <>
      <div
        className={`client-picker dropdown-keywords-lists ${showDropdownContent ? `dropdown-keywords-lists-open` : ''} dropdown-keywords-lists-fixed-position ${restrictOverlimitClients && !addNewTopicConditionForActiveClient ? 'client-element-disabled bg-white ' : ''} ${additionalClass}`}
        ref={topicContainer}
      >
        <div
          className={`dropdown-keywords-activeitem flex-centered pointer`}
          onClick={(e) => {
            setShowDropdownContent(!showDropdownContent);
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <p className={`search-main-text-value pointer flex-centered`}>
            <img
              className='img-fluid w-100 client-picker-logo client-picker-logo-main'
              src={`${process.env.REACT_APP_DOTNETAPIURL}/api/cors/resource?url=${encodeURIComponent(activeClient?.organisationLogo ?? `${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`)}&mime=${encodeURIComponent('image/png')}&cache=true`}
              alt='organisation logo'
            />
            <span className='d-block hidden-lines hidden-one-line'>{activeClient?.teamName}</span>
          </p>

          <span className={`search-dropdown ${showDropdownContent ? 'search-dropdown-open' : ''}`} />
        </div>
      </div>
      <Portal>
        {showDropdownContent && (
          <div
            className={`general-close-background ${maximumZIndex ? 'adjustment-of-position' : 'z-extra-max'}`}
            onClick={() => setShowDropdownContent(false)}
          />
        )}
        <div
          className={`open-dropdown d-${showDropdownContent ? 'block' : 'none'} dropdown-fixed-position ${dropdownAdditionalClass} ${maximumZIndex ? 'adjustment-of-position' : ''}`}
          style={positionStyles()}
          ref={dropdownElement}
        >
          <div className='open-dropdown-container'>
            <div
              ref={topicsListElement}
              onKeyDown={(e) => {
                scrollList(e);
              }}
            >
              <div className='scroll-container hover-container search-bar-lists' ref={scrollContainerElement}>
                <ul className='policy-list px-0 mb-0 hover-content' ref={keywordListsElement}>
                  {clientsLists?.map((client, index) => {
                    return (
                      <ClientItem
                        index={index}
                        client={client}
                        key={`team-${client?.teamId}`}
                        listPosition={listPosition}
                        startMoveLists={startMoveLists}
                        setStartMoveLists={setStartMoveLists}
                        setShowDropdownContent={setShowDropdownContent}
                        setListPosition={setListPosition}
                        restrictOverlimitClients={restrictOverlimitClients}
                        notShowActions={notShowActions}
                        activeClient={activeClient}
                        selectClientFunction={selectClientFunction}
                      />
                    );
                  })}
                </ul>
              </div>
            </div>
            <Link
              to={'/settings/clients'}
              className={`text-light-blue manage-clients-button w-100 d-block p-3 border-top`}
            >
              <span className='icon-edit-pencil-thick' />
              Manage clients
            </Link>
          </div>
        </div>
        <ReactTooltip
          id={'client-picker-tooltip'}
          effect={'solid'}
          type={'info'}
          className={'pricing-feature-tooltip feed-tooltip'}
          arrowColor={'transparent'}
          backgroundColor={'#00122B'}
          multiline={true}
          globalEventOff={isMobile ? 'click' : undefined}
        />
      </Portal>
    </>
  );
};

const ClientItem = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team, activePlan } = state;
  const { myself } = team ?? {};
  const { maxListsPerTeam } = activePlan;

  const {
    client,
    index,
    listPosition,
    startMoveLists,
    setStartMoveLists,
    setListPosition,
    restrictOverlimitClients,
    setShowDropdownContent,
    notShowActions,
    activeClient,
    selectClientFunction,
  } = props;

  const { teamId, teamName, organisationLogo, myKeywordLists, teamMemberKeywordLists } = client ?? {};
  const currentTeam = teamId === activeClient?.teamId;
  const lists = !!myKeywordLists && !!teamMemberKeywordLists ? [...myKeywordLists, ...teamMemberKeywordLists] : [];
  const addNewTopicCondition = maxListsPerTeam === null || lists?.length < maxListsPerTeam;
  const disabledCondition = restrictOverlimitClients && !addNewTopicCondition;

  const [data, setData] = useState(null);

  const teamNameElement = useRef();

  const location = useLocation();
  const isMobile = useIsMobile();

  const preloadLogo = useRef();
  preloadLogo.current = () => {
    if (!!organisationLogo) {
      const img = new Image();
      img.addEventListener('load', function () {
        const ct = new ColorThief();
        const arrayRGB = ct.getColor(img, 10);
        setData(arrayRGB);
      });
      img.crossOrigin = 'anonymous';
      img.src = organisationLogo;
    }
  };

  useEffect(() => {
    preloadLogo.current();
  }, []);

  return (
    <div
      key={`item-${teamId}`}
      className={`${startMoveLists && listPosition === index ? `dropdown-keywordlist-highlighted ${currentTeam ? '' : 'client-picker-highlighted'}` : ''} ${disabledCondition ? 'client-element-disabled' : ''}`}
      onMouseOver={() => {
        if (!isMobile && !disabledCondition) {
          if (!startMoveLists) {
            setStartMoveLists(true);
          }
          setListPosition(index);
        }
      }}
      data-tooltip-content={
        disabledCondition ? `You have reached the limit of topics you can create for this client` : ''
      }
      data-tooltip-id={'client-picker-tooltip'}
      onClick={async (e) => {
        if (!disabledCondition) {
          selectClientFunction({ client, teamId, e, colorData: data });
          setShowDropdownContent(false);
        }
      }}
    >
      <li
        className={`dropdown-keywordlist d-flex position-relative`}
        data-tooltip-content={checkEllipsis(teamNameElement?.current) ? teamName : ''}
        data-tooltip-id={'client-picker-tooltip'}
        data-tooltip-float={true}
      >
        <p className={`pointer flex-centered position-relative`}>
          <span className='client-picker-logo flex-centered justify-content-center position-relative'>
            <img
              className='img-fluid w-100 rounded-circle'
              src={`${process.env.REACT_APP_DOTNETAPIURL}/api/cors/resource?url=${encodeURIComponent(organisationLogo ?? `${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`)}&mime=${encodeURIComponent('image/png')}&cache=true`}
              alt='organisation logo'
            />

            {!notShowActions && !currentTeam && (
              <>
                <span className='icon-circle-arrow client-picker-logo-hovered' />
              </>
            )}
          </span>
          <span className='d-block hidden-lines hidden-one-line flex-grow-1 nowrap-item' ref={teamNameElement}>
            {' '}
            {teamName}
            {disabledCondition && <span className='icon-padlock ml-2' />}
          </span>
        </p>
        {currentTeam && <span className='icon-tick topic-selected-mark' />}

        {!notShowActions && (
          <div className='dropdown-list-actions'>
            {(myself?.role === 'Admin' || myself?.role === 'Owner') && (
              <Link
                to={`/settings/clients?returnUrl=${encodeURIComponent(location.pathname)}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <button
                  className='general-button dropdown-edit-keyword-list flex-centered'
                  data-tooltip-content={`Edit this client`}
                  data-tooltio-id={'general-tooltip'}
                  data-tooltip-place={'top'}
                  data-tooltip-class={'edit-icon-analytics-tooltip'}
                >
                  <span className='icon-edit-pencil' />
                </button>
              </Link>
            )}
          </div>
        )}
      </li>
      {}
    </div>
  );
};

export { ComponentClientSelector };
export default ClientsPicker;
