import CustomScrollbar from 'components/Common/CustomScrollbar';
import EventListItem from 'components/Dashboard/LiveFromParliament/EventListItem';
import EventListSkeleton from 'components/Dashboard/LiveFromParliament/EventListSkeleton';
import { useHeightContainer } from 'components/Dashboard/utilities/useHeightContainer';
import { useEffect, useRef, useState } from 'react';

const EventsList = (props) => {
  const {
    selectedEvent,
    setSelectedEvent,
    resultsToRender,
    isLoading,
    sortResults,
    remainingSpaceBoxHeght,
    setRemainingSpaceBoxHeght,
    events,
  } = props;
  const [heightContainer, containerRef] = useHeightContainer();
  const [expandedItem, setExpandedItem] = useState(null);
  const scrollToItemRef = useRef(null);
  const scrollBarRef = useRef(null);

  useEffect(() => {
    if (scrollBarRef.current && scrollToItemRef?.current) {
      scrollBarRef.current.scrollTop(scrollToItemRef?.current?.offsetTop);
    }
  }, [scrollBarRef.current, scrollToItemRef.current, remainingSpaceBoxHeght]);

  const getRemainingSpaceBoxHeght = () => {
    return heightContainer - (scrollBarRef?.current?.view?.scrollHeight - scrollToItemRef?.current?.offsetTop) ?? 0;
  };

  useEffect(() => {
    setTimeout(() => {
      setRemainingSpaceBoxHeght(getRemainingSpaceBoxHeght());
    }, 100);
  }, [heightContainer, scrollBarRef?.current, scrollToItemRef?.current]);

  const getGroupResults = (type) => {
    let res;
    switch (type) {
      case 'live':
        res = resultsToRender?.filter((item) => {
          const eventItem = events?.find((event) => event?.id === item?.eventId);
          return !!eventItem?.transcriberRunning;
        });
        break;
      case 'upcoming':
        res = resultsToRender?.filter((item) => {
          const eventItem = events?.find((event) => event?.id === item?.eventId);
          return !eventItem?.transcriberRunning && !eventItem?.actualStartDateTime;
        });
        break;
      case 'finished':
        res = resultsToRender?.filter((item) => {
          const eventItem = events?.find((event) => event?.id === item?.eventId);
          return !eventItem?.transcriberRunning && eventItem?.actualStartDateTime;
        });
        break;
      default:
        break;
    }
    return res?.sort(sortResults);
  };

  const getHitsToShow = () => {
    const liveEvents = getGroupResults('live');
    let hitsToShow = [
      ...getGroupResults('finished'),
      { scrollToItem: true, hidden: liveEvents.length === 0 },
      ...liveEvents,
      ...getGroupResults('upcoming'),
    ];
    return hitsToShow.filter((item) => !item.hidden);
  };

  return (
    <div className='specific-top-padding-37 parliament-event-list-container'>
      {isLoading ? (
        <EventListSkeleton />
      ) : (
        <>
          {resultsToRender?.length > 0 && (
            <div ref={containerRef}>
              <CustomScrollbar
                className={`main-content-scrollbar`}
                style={{ height: `${heightContainer}px` }}
                maximalThumbYSize={100}
                ref={scrollBarRef}
                contentClass={'ScrollbarsCustom-Content overscroll-behavior-contain'}
              >
                <ul className='policy-list px-0 items-list pb-2'>
                  {getHitsToShow()?.map((item, index, array) => {
                    if (item?.scrollToItem) {
                      return <div key={`item-scrollToItemRef`} ref={scrollToItemRef}></div>;
                    } else {
                      return (
                        <EventListItem
                          key={`item-parliamentary-item-${index}`}
                          item={item}
                          array={array}
                          index={index}
                          listView={true}
                          selectedEvent={selectedEvent}
                          setSelectedEvent={setSelectedEvent}
                          isExpanded={expandedItem === item.id || selectedEvent?.id === item.eventId}
                          setExpandedItem={setExpandedItem}
                          events={events}
                        />
                      );
                    }
                  })}
                  <div style={{ height: `${remainingSpaceBoxHeght}px` }}></div>
                </ul>
              </CustomScrollbar>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EventsList;
