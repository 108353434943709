import React, { useEffect, useState, useContext, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading';
import { net_api_url, store } from 'components/Store.js';
import axios from 'axios';
import getUrlParam from 'components/Dashboard/utilities/getUrlParam';
import { useNavigate } from 'react-router-dom';
import removeLocalStorage from 'components/Dashboard/Navigation/RemoveLocalStorage';
import { RegistrationFields } from './RegistrationVerification/RegistrationUi';
import useNewUserFields from './useNewUserFields';
import validateFields from './RegistrationVerification/validateFields';
import useGeneralApiCall from 'components/Dashboard/apiCalls/useGeneralApiCall';
import useGetAccessToken from 'components/Dashboard/apiCalls/useGetAccessToken.js';
import LocalStorage from 'utils/LocalStorage.js';

const AcceptInvite = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { app_url } = state;
  const { logout, loginWithRedirect, isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const [isLoading, setIsLoading] = useState(true);

  const [invitationInformation, setInvitationInformation] = useState(null);
  const [invitationErrors, setInvitationErrors] = useState({ title: '', message: '' });
  const [showUserFields, setShowUserFields] = useState(false);
  const invitationCode = getUrlParam('invitationCode');
  const sourceRef = useRef();

  const navigate = useNavigate();
  const logoutWithRedirect = () => logout({ logoutParams: { returnTo: window.location.origin } });

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    sourceRef.current = source;
    getInvitationInformation(source);
    return () => {
      source.cancel('Accept invitation canceled by the user.');
    };
  }, []);

  useEffect(() => {
    if (invitationInformation !== null) {
      const { isExistingUser, code, email, hasBeenAccepted, hasExpired } = invitationInformation;
      if (hasBeenAccepted === true) {
        setInvitationErrors({ message: 'This invitation has already been accepted' });
        setIsLoading(false);
      } else if (hasExpired) {
        setInvalidMessage();
      } else {
        if (isAuthenticated) {
          acceptInvitation(getUrlParam('invitationCode'));
        } else {
          if (isExistingUser === false) {
            setShowUserFields(true);
            setIsLoading(false);
          }
          if (isExistingUser === true) {
            LocalStorage.setItem('invitationCode', code);
            loginWithRedirect({
              authorizationParams: {
                redirect_uri: `${app_url}/accept-invite?invitationCode=${code}`,
                login_hint: email,
              },
            });
          }
        }
      }
    }
  }, [invitationInformation]);

  const acceptInvitation = async (code) => {
    setIsLoading(true);
    let token = await getAccessToken();
    callAcceptInvitation(token, code);
  };

  const callAcceptInvitation = (token, code) => {
    axios
      .post(
        `${net_api_url}/api/team/accept-invite`,
        {
          code: code,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: sourceRef.current?.token,
        }
      )
      .then((res) => {
        navigate('/settings/team');
        deleteLocalStorage();
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          setInvitationErrors({ title: 'An error ocurred', message: error.response.data.message });
          deleteLocalStorage();
        } else if (error.request) {
          console.error(error.request);
        } else {
          console.error(error.request);
        }
      });
  };

  const deleteLocalStorage = () => {
    if (LocalStorage.getItem('invitationCode') !== null) {
      LocalStorage.removeItem('invitationCode');
    }
  };

  const setInvalidMessage = () => {
    setInvitationErrors({ message: 'This invitation is not valid or has expired' });
    setIsLoading(false);
  };

  const getInvitationInformation = async (source) => {
    try {
      if (invitationCode === undefined) {
        let codeStoraged = LocalStorage.getItem('invitationCode');
        if (codeStoraged !== null) {
          acceptInvitation(codeStoraged);
        } else {
          setInvalidMessage();
        }
      } else {
        axios
          .get(`${net_api_url}/api/team/invitation/${invitationCode}`, {
            cancelToken: source.token,
          })
          .then((res) => {
            if (res.status === 204) {
              setInvalidMessage();
            } else {
              setInvitationInformation(res.data);
            }
          })
          .catch(function (error) {
            if (error.response) {
              setInvalidMessage();
            } else if (error.request) {
              console.error(error.request);
            } else {
              console.error('Error', error.message);
            }
          });
      }
    } catch (error) {}
  };

  const { formFields, setFormFields, modifyValue } = useNewUserFields();
  const { generalApiCall } = useGeneralApiCall();

  const registerUser = async (e) => {
    let validation = validateFields({ formFields, setFormFields });
    if (validation) {
      let button = e.target;
      button.disabled = true;
      let pathname = `/api/registration/register-verified-user-from-invitation?code=${invitationCode}`;
      const requestHeaders = { cancelToken: sourceRef.current?.token };
      const method = 'post';
      const finalFields = formFields.slice();
      let fullNameItem = finalFields.find((item) => item.property === 'fullName');
      let fullNameParts =
        fullNameItem.type === 'autocomplete' ? fullNameItem.value.label.split(' ') : fullNameItem.value.split(' ');
      let passwordItem = finalFields.find((item) => item.property === 'password');
      let emailAddress = invitationInformation.email;

      const requestProperties = {
        email: emailAddress,
        fullName: fullNameItem.value,
        firstName: fullNameParts[0],
        lastName: fullNameParts.splice(1).join(' '),
        password: passwordItem.value,
      };
      let results = await generalApiCall({
        pathname,
        method,
        requestHeaders,
        requestProperties,
        requestSource: sourceRef?.current,
      });
      if (!!results) {
        loginWithRedirect({
          authorizationParams: {
            login_hint: emailAddress,
            userPassword: encodeURIComponent(passwordItem.value),
            redirect_uri: `${app_url}/${window.location.search}/login-success`,
          },
        });
      }
      button.disabled = false;
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <div className='create-profile-container px-0 accept-invite'>
          <div className='scroll-container h-100 create-profile-general-content'>
            <h2 className='company-name'>Policy Mogul</h2>
            <div className='d-flex justify-content-center align-items-center w-100 py-lg-2 py-lg-5 pt-5'>
              <div className='mt-5 mt-lg-0'>
                <div className=' px-5 py-4 create-profile-content'>
                  {!showUserFields && (
                    <button
                      className='general-button d-block text-right w-100'
                      onClick={() => {
                        removeLocalStorage();
                        logoutWithRedirect();
                      }}
                    >
                      <strong>Log out</strong>
                    </button>
                  )}
                  {showUserFields && (
                    <div className='pt-4'>
                      <RegistrationFields
                        formFields={formFields}
                        modifyValue={modifyValue}
                        registerUser={registerUser}
                        actionText={'Create account'}
                      />
                    </div>
                  )}
                  <div className='mt-5 pt-5'>
                    {invitationErrors.title === undefined && (
                      <>
                        <p className='welcome-message mb-0'> {invitationErrors.message} </p>
                        <button
                          className='general-button action-button py-2 px-4 my-5'
                          onClick={() => {
                            window.location.assign('/monitor-inbox');
                          }}
                        >
                          Go to platform
                        </button>
                      </>
                    )}
                    {invitationErrors.title !== undefined && (
                      <>
                        <p className='welcome-message mb-0'>{invitationErrors.title}</p>
                        <div className='awaits-message'>{invitationErrors.message}</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AcceptInvite;
